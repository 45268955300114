/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Map cases
 */

import SingleCaseMapper from '../case/mapper';

class HomepageMapper {
    /**
     * map server data to UI data
     * @param payload
     * @returns {{}}
     */
    mapToVm = (payload) => ({
        cases: payload.cases.map((it) => SingleCaseMapper.mapToVm(it)),
        count: payload.count
    });

    /**
     * map UI data to server data
     * @param payload
     * @returns {{}}
     */
    mapToDto = (payload) => ({
        _cases: payload.cases
    });
}

export default new HomepageMapper();