import PropTypes from 'prop-types';

export const SimpleField = PropTypes.shape({
    type: PropTypes.oneOf(['text', 'number', 'email', 'phone', 'password']).isRequired,
    elementType: PropTypes.string,
    defaultValue: PropTypes.any,
    label: PropTypes.string.isRequired
});

export const SimpleTitle = PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string.isRequired
});
