/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
export const TOGGLE_LOADING = 'CASES@TOGGLE_LOADING';
export const REMOVE_CASE = 'CASES@REMOVE_CASE';
export const APPLY_FILTER = 'CASES@APPLY_FILTER';
export const RESET_FILTERS = 'CASES@RESET_FILTERS';
export const GET_CASES_SUCCESS = 'CASES@GET_CASES_SUCCESS';
export const NEWSLETTER_SUCCESS = 'CASES@NEWSLETTER_SUCCESS';
export const NEWSLETTER_ERROR = 'CASES@NEWSLETTER_ERROR';
export const CLEAR_CASES = 'CASES@CLEAR_CASES';
export const GET_SUBSCRIBER_SUCCESS = 'CASES@GET_SUBSCRIBER_SUCCESS';
export const NEWSLETTER_UNSUBSCRIBE_SUCCESS = 'CASES@NEWSLETTER_UNSUBSCRIBE_SUCCESS';
export const EMAIL_VALIDATION_RESPONSE_SUCCESS = 'CASES@EMAIL_VALIDATION_REPONSE_SUCCESS';
export const EMAIL_VALIDATION_GENERATE_SUCCESS = 'CASES@EMAIL_VALIDATION_GENERATE_SUCCESS';
export const CLOSE_EMAIL_VALIDATION_GENERATE_MODAL = 'CASES@CLOSE_EMAIL_VALIDATION_GENERATE_MODAL';
export const CASES_FINISHED_HOURS_SUCCESS = 'CASES@CASES_FINISHED_HOURS_SUCCESS';