/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Auth box for login / signup
 */
import React from 'react';
import PropTypes from 'prop-types';
import Wizard from "../../wizard";
import IdSmsDetails from "./id-sms-details";

class FinalDetails extends React.PureComponent {

    render() {

        const stages = [
            {title: '', component: <IdSmsDetails {...this.props} />},
        ];

        return (
            <div className={'auth-box with-dots'}>
                <Wizard stages={stages} activeStage={this.props.verificationStage} />
            </div>
        );
    }
}

FinalDetails.propTypes = {
    onCompleteDetailsSubmit: PropTypes.func,
    verificationStage: PropTypes.number
};

export default FinalDetails;
