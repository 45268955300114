import BannerImg from '../../../assets/img/WL-white.jpeg'

class WhiteLabelMapper {
    /**
     * server data to UI
     * @param payload
     */
    mapToVm = (payload) => (
        {
            logoUrl: payload.logo_url,
            name: payload.name,
            id: payload.id,
            type: payload.type,
            emergency: payload.emergency || false,
            primaryColor: payload.primary_color ? payload.primary_color : '#22a5b9',
            secondaryColor: payload.secondary_color ? payload.secondary_color : '#F58220',
            textColor: payload.text_color ? payload.text_color : '#666666',
            headingBgColor: payload.header_color ? payload.header_color : '#39b1c6',
            bannerText: payload.banner_text || undefined,
            bannerImg: payload.banner_img || BannerImg,
            bannerImgTablet: payload.banner_img_tablet || payload.banner_img || BannerImg,
            bannerImgMobile: payload.banner_img_mobile || payload.banner_img || BannerImg,
            successStripTextColor: payload.menu_text_color ? payload.menu_text_color : '#fff',
            menuLinkColor: payload.menu_link_color ? payload.menu_link_color : '#fff',
            menuLinkHoverColor: payload.menu_link_hover_color ? payload.menu_link_hover_color : '#000',
        }
    );

    /**
     * UI data to server
     * @param payload
     */
    mapToDto = (payload) => (
        {
            logo_url: payload.logoUrl,
            name: payload.name,
            id: payload.id,
            type: payload.type,
            emergency: payload.emergency || false,
            primary_color: payload.primaryColor,
            secondary_color: payload.secondaryColor,
            text_color: payload.textColor
        }
    )

}

export default new WhiteLabelMapper();