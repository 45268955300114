import RestService from "../../../common/rest-service";
import SuccessMapper from './mapper';
import * as actionTypes from './action-types';

export const getSuccesses = (params) => async (dispatch) => {
    const paramsToServer = SuccessMapper.mapToDto(params);
    const result = await RestService.get('api/cases/success', paramsToServer);
    const {data} = result;

    if(data) {
        const successes = SuccessMapper.mapToVm(data.successes);
        dispatch({type: actionTypes.GET_SUCCESSES, payload: {successes, total: data.total_completed_cases}})
        dispatch({type: actionTypes.SET_BANNER_SUCCESS, payload: {successes}})
    }
};