import {CONTACT_MAIL} from "../../../common/constants";
import * as emailjs from "emailjs-com/source/index";
import * as actionTypes from "./action-types";
import RestService from "../../../common/rest-service";
import {showSuccessToast} from "../common/toast/actions";
import {showLoading, hideLoading} from 'react-redux-loading-bar';

export function sendContactForm(payload){
    return async (dispatch) => {

        dispatch({type: actionTypes.TOGGLE_SEND_BUTTON});
        dispatch(showLoading());

        try {
            const tokenResponse = await RestService.post('api/recaptcha', {'g-recaptcha-response': payload['g-recaptcha-response']});
            const tokenSuccess  = tokenResponse.success;
            if(tokenSuccess) {
                const response = await emailjs.send(CONTACT_MAIL.mailType, CONTACT_MAIL.template, payload, CONTACT_MAIL.userId);
                if (response) {
                    dispatch(hideLoading());
                    dispatch({type: actionTypes.SEND_FORM_SUCCESS});

                    showSuccessToast('תודה, הודעתך נשלחה בהצלחה');

                    dispatch({type: actionTypes.TOGGLE_SEND_BUTTON});
                }
            }
        }
        catch (error) {
            dispatch(hideLoading());
            dispatch({type: actionTypes.TOGGLE_SEND_BUTTON});
        }
    }
}

