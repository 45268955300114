import * as actionTypes from './action-types';

const initialState = {
    successes: [],
    total: 0,
    successesServed: false,
};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case actionTypes.GET_SUCCESSES: {
            const {payload: {successes, total}} = action;

            return {
                ...state,
                successes,
                total,
                successesServed: true
            }
        }
        case actionTypes.SET_BANNER_SUCCESS: {
            const {payload: {successes}} = action;

            const bannerSuccess = successes.length ? successes.sort(() => 0.5 - Math.random()).slice(0,1)[0] : null;

            return {
                ...state,
                bannerSuccess
            }
        }
        default: {
            return state;
        }
    }
}