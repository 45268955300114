import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-toolbox/lib/dropdown';
import './_style.scss';

class DropdownSelect extends PureComponent {

    /**
     * The dependency is opinionated about how we feed displayNames (it prefers labels).
     * This also adds label attribute to inner-data
     * * @param data
     */
    handleData = () => {
        const { data, includeLabel, label } = this.props;
        let newData = includeLabel ? [{value: '', label}] : [];

        data.map((item, i) => {
            let obj = {};
            Object.keys(item).map(key => {
                if (key === 'displayName') {
                    return obj['label'] = data[i]['displayName']
                } else {
                    return obj[key] = data[i][key];
                }
            });
            return newData = [...newData, obj]
        });

        return newData;
    };

    onChange = (value) => this.props.onChange(this.props.name, value);

    render() {
        const { label, className, value, includeLabel } = this.props;
        const items = this.handleData();

        return (
            <div className="select-container">
                <Dropdown
                    auto={false}
                    label={!includeLabel ? label : ''}
                    source={items}
                    onChange={this.onChange}
                    className={`${className ? className : ''} ${value ? 'has-value' : ''} ${includeLabel ? 'included-label' : ''}`}
                    value={value}
                    theme={{
                        selected: 'selected-item selected'
                    }}
                />
            </div>
        )
    }
}

DropdownSelect.propTypes = {
    label: PropTypes.string,
    data: PropTypes.array.isRequired,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    onChange: PropTypes.func
};

export default DropdownSelect;