import {createSelector} from 'reselect';


const _selectRootReducer = (state) => state.rootReducer;

export const selectCityWhiteList = (state) => _selectRootReducer(state).cityWhiteList;

export const selectWhiteHeader = (state) => _selectRootReducer(state).whiteLogo;

export const selectSpecialHeaderClass = (state) => _selectRootReducer(state).specialHeaderClass;

export const selectWhiteLabel = createSelector(
    [_selectRootReducer],
    (root) => root.whiteLabel
);

export const selectWhiteLabelState = createSelector(
    [(state) => selectWhiteLabel(state)],
    (whiteLabel) => whiteLabel
);

