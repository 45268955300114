import {createSelector} from 'reselect';

const selectProfile = (state) => state.profile;

export const selectShowSmsModal = createSelector(
    [selectProfile],
    (profile) => profile.showSmsModal
);
export const selectValidError = createSelector(
    [selectProfile],
    (profile) => profile.validError
);
export const userTaskCompleted = createSelector(
    [selectProfile],
    (profile) => ({
        taskCompletedList: profile.userTaskCompleted,
        pagination: 1
    })
);
export const userOnGoingTasks = createSelector(
    [selectProfile],
    (profile) => profile.userOnGoingTasks
);
