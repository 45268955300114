import React from 'react';
import './style.scss';
import Accordion from "../../components/accordion";
import {FOUNDERS_MEMBERS} from './constants';
import HeadingText from "../../components/heading-text";
import TaskTypesGrid from '../../components/task-types-grid';

class About extends React.Component {

    render() {
        const accordionCards = [
            {
                title: "הסיפור שלנו",
                id: "beginning",
                content:
                    <div className={`beginning`}>
                        <p>בין לימודים באוניברסיטה ועבודה במשרות תובעניות ושוחקות, לבין רצון תמידי להוסיף לשגרה ערך מוסף, חיפשנו מקום שבו אפשר להתנדב, בצורה קלה ופשוטה. פשוט להתנדב. כשכבר כמעט הצלחנו, נתקלנו בהתנהלות מסורבלת, מיושנת ובירוקרטית, ודרישה להתחייבות לטווח ארוך.</p>
                        <p>מצד שני, ישנם אלפי אזרחים.ות מאוכלוסיות מוחלשות או בעלי צרכים מיוחדים, שסובלים.ות מדי יום מקשיים ובעיות שהיו יכולים להיפתר בצורה מהירה ונקודתית, אם רק היינו יודעים עליהם. קשיש שצריך הסעה וליווי לטיפול כימותרפי בבי"ח, אם חד הורית הזקוקה לעזרה במילוי מסמכים לביטוח לאומי, או משפחה קשת יום שחסרים לה בגדים חמים לחורף.</p>
                        <p>הפלטפורמה הדיגיטלית של Helpi מאפשרת לעובדי חברות וארגונים שותפים ולמתנדבי הקהל הרחב להתנדב מתי, איך וכמה שמתאים, ללא התחייבות לטווח ארוך. אנחנו מתווכות בין שני הצדדים ודואגות שההתנדבות תהיה קלה ונגישה. כך, אלפי אזרחים יוכלו לקבל את הסיוע שהם זקוקים לו.</p>
                        <p>בכל יום, מתקיימים מעשים מרגשים ומעוררי השראה דרך הפלטפורמה של Helpi, שלא היו מתקיימים אחרת: 50% ממתנדבי הפלטפורמה לא התנדבו באף מסגרת אחרת ב-3 השנים האחרונות. כלומר, Helpi אפשרה להם להיות מעורבים חברתית ולתרום לקהילה, בפעם הראשונה מזה 3 שנים לפחות. ככל שעובר הזמן, אנחנו מתרגשות לגלות עד כמה התנדבות נקודתית עושה שינוי גדול, ואיזה חותם היא משאירה גם הרבה זמן אחר כך.</p>
                        <p>רוצה גם? זה קל, זה פשוט, וזה עושה טוב על הנשמה.</p>
                    </div>
            },
            {
                title: "חזון ומטרות",
                id: "vision",
                content:
                    <div className={`vision`}>
                        <p><strong>החזון שלנו</strong> - איחוד וחיזוק הקהילה, אהבת אדם והרוח האנושית, על ידי חיבור בין בני אדם באשר הם.</p>
                        <strong style={{display: 'block'}}>ערכי העמותה</strong>
                        <p style={{marginTop:"0"}}>סולידריות אזרחית, שוויון הזדמנויות, מעורבות ושותפות חברתית, שקיפות, קהילתיות וקבלת האחר</p>
                        <strong style={{display: 'block'}}>ייעוד</strong>
                        <p style={{marginTop:"0", marginBottom: "0"}}>אנו פועלים להגברת המעורבות החברתית והקהילתית בקרב אזרחי ישראל והקהילה העסקית באמצעות:</p>
                        <ol style={{marginTop:"0", paddingRight: "13px"}}>
                            <li>פיתוח והפעלת פלטפורמה אינטרנטית חדשנית, מותאמת לשימוש בסמארטפון, המאפשרת גישה פשוטה, קלה ומהירה למגוון רחב של התנדבויות חד-פעמיות וחיבור מהיר ויעיל בין הזקוקים לסיוע למתנדבים לתת אותו.</li>
                            <li>יצירת תקן התנדבות לעסקים במשק הישראלי והענקתו לארגונים העומדים בדרישות התקן.</li>
                        </ol>
                        <strong>מטרות</strong>
                        <ul className="goals">
                            <li>הגברת המעורבות החברתית והגדלת אחוזי ההתנדבות בישראל באמצעות התנדבויות חד פעמיות.</li>
                            <li>יצירת חיבורים בין אנשים וקהילות ממגזרים ומעמד סוציו אקונומי שונה.</li>
                            <li>שימוש בטכנולוגיה לשם פישוט והנגשת הליך בקשת סיוע ומציאת התנדבות.</li>
                            <li>הגברת המחויבות החברתית והקהילתית של עסקים בישראל, באמצעות מנגנון התנדבות התומך בהתנדבות העובדים ובתמורה לתו איכות הקובע סטנדרט התנדבות עבור חברות במשק הישראלי.</li>
                        </ul>
                    </div>
            },
            {
                title: 'תחומי התנדבות',
                id: 'volunteer-types',
                content: <TaskTypesGrid/>
            },
            {
                title: 'ציוני דרך בפעילות העמותה',
                id: 'milestones',
                content:
                    <div className="milestones">
                        <ul className="goals">
                            <li>עמותת בואונתערב [ע"ר 580615813] נוסדה בספטמבר 2015 - <a href={`/files/תעודת מלכר.pdf`} target="_blank" rel="noopener noreferrer">להורדת תעודת עמותה </a></li>
                            <li>בחודש דצמבר 2016 נכנסה העמותה לשת"פ אסטרטגי עם משרד הרווחה, המבוסס על מיזם משותף ועל הטמעת הפלטפורמה בקרב מנהלי התנדבות במחלקות רווחה ברשויות.</li>
                            <li>פלטפורמת Helpi עלתה לאוויר באפריל 2017.</li>
                            <li>העמותה הינה בוגרת המחזור השני (2017) של  <a href="https://www.thesocialprogram.co.il/" target="_blank" rel="noopener noreferrer"> 8200 Impact </a>- אקסלרטור למיזמים חברתיים של עמותת בוגרי 8200</li>
                            <li>העמותה קיבלה לראשונה את אישור כמוסד ציבורי לעניין סעיף 46א לפקודת מס הכנסה במרץ 2017 (בתוקף עד 31.12.2021) - <a href="/files/סעיף 46א - 2019.pdf" target="_blank">להורדה</a> </li>
                            <li>העמותה מחזיקה ב<a href="http://www.midot.org.il/%D7%91%D7%95%D7%90%D7%95%D7%A0%D7%AA%D7%A2%D7%A8%D7%91" target="_blank" rel="noopener noreferrer">תו מידות לאפקטיביות</a> (בתוקף עד אוקטובר 2020)</li>
                            <li>העמותה התקבלה למאגר העמותות של <a href="https://www.round-up.org.il/%D7%94%D7%9C%D7%A4%D7%99-helpi?association" target="_blank" rel="noopener noreferrer">עיגול לטובה</a> בינואר 2019</li>
                            <li>עד אוקטובר 2020, 130 רשויות ועמותות נעזרו לפחות פעם אחת בשירותי העמותה, או מקיימות עמה שותפות ארוכת טווח</li>
                            <li>עד אוקטובר 2020, 110 חברות וארגונים קידמו התנדבות דרך הפלטפורמה לפחות פעם אחת, או מקיימות עם העמותה שותפות ארוכת טווח</li>
                            <li>בשנת 2017, 374 מתנדבים השתתפו ב-137 פעילויות וסייעו ל-2,172 מוטבים - <a href="https://mailchi.mp/c21467746eca/u1vo5sfpjz" target="_blank" rel="noopener noreferrer">לסיכום 2017</a></li>
                            <li>בשנת 2018, 1,740 מתנדבים השתתפו ב-431 פעילויות וסייעו ל-5,155 מוטבים - <a href="https://mailchi.mp/cd1fc947cd68/2018-2098329" target="_blank" rel="noopener noreferrer">לסיכום 2018</a></li>
                            <li>בשנת 2019, 3,349 מתנדבים השתתפו ב-592 פעילויות וסייעו ל-8,294 מוטבים - <a href="https://mailchi.mp/ade7fff45499/2019-2588305?e=62e52ab711" target="_blank" rel="noopener noreferrer">לסיכום 2019</a></li>
                            <li>משרדי העמותה – לילינבלום 22, ת"א</li>
                        </ul>
                    </div>
            },
            {
                title: "הצוות",
                id: "team",
                content:
                    <div className={`team`}>
                        <div className={`founders-section`}>
                            {FOUNDERS_MEMBERS.map((item, index) => {
                                return (
                                <div key={`founder-${item.email}-${index}`} className={`member-item`}>
                                    <div className={`member-container`}>
                                        <div className={`img-container`} >
                                            <div className="member-img" style={{backgroundImage: `url(${item.imgSrc})`}}/>
                                            {/*<img className={`member-img`} alt={`תמונה- מייסדת`} src={item.imgSrc} />*/}
                                        </div>
                                        <div className={`founder-data`}>
                                            <span className="member-text">{item.name}, {item.title}</span>
                                            {item.description && <span className="description">{item.description}</span>}
                                            <a href="mailto:{{member.email}}">{item.email}</a>
                                        </div>

                                    </div>
                                    </div>
                                )}
                                )}

                        </div>
                        <HeadingText label={`חברי הוועד המנהל`}/>
                        <ul className="managers">
                            <li>יו"ר הוועד - גיא גבע, יו"ר חברת WXG</li>
                            <li>מריאנה וקסמן, מנהלת תחום אקדמיה וחינוך, אינטל ישראל</li>
                            <li>איילה פאר, ראש ענף הדרכה ופרויקטים, תחום התנדבות, משרד העבודה, הרווחה והשירותים
                                החברתיים
                            </li>
                            <li>אוריה לין, ראש תכנית "הכוורת" מיסודם של ג'וינט ישראל וקרנות ביטוח לאומי</li>
                            <li>איתי רוקני, יזם אינטרנט ומובייל</li>
                            <li>אמיר שני - גוף מבקר</li>
                            <li>רחלי פישר, רו״ח</li>
                        </ul>
                        <HeadingText label={`מתנדבים ויועצים`}/>
                        <div>
                            <p style={{textAlign: 'center'}}>סטודיו זלצר, אייל דוד, חן נבו, רותם מידן, דנה סנדר מולה, ניבי טפליץ, יובל רוביצ'ק, ORO Photography, אורטל עטיה, דרור עוגן, אורי גושן, רונה שפייזמן, UM Digital, Tailor Brands, דנה קריב, רון פלד, Protectops</p>
                            {/*<ul className="volunteers">*/}
                                {/*{*/}
                                    {/*VOLUNTEERS.map((item, index) => {*/}
                                        {/*return(*/}
                                            {/*<li key={`volunteer-${item.name}-${index}`}>{item.name}, {item.position}</li>*/}
                                        {/*)*/}
                                    {/*})*/}
                                {/*}*/}
                            {/*</ul>*/}
                        </div>
                        <div className="originators">
                            <HeadingText label="צוות ההקמה"/>

                           <div>מאיר קדוש, ניר אינגביר, אבישי בלדרמן, בר וכטל, יוני דור, יותם שלו, יקיר רבינוביץ, ניבי טפליץ, סתיו מורן לשם, ברק אן כהן, מרב בצר</div>
                            <div>בלעדיכם אין סיכוי שמשהו מזה היה קורה. אנחנו מודות לכם מכל הלב, לתמיד.</div>
                        </div>
                        <div id="decor"> בניית הפלטפורמה - <strong>Codicate</strong>
                        </div>
                    </div>
            }
        ];

        return (
            <div className={`about-us-page container about-us-page-container`}>
                <div>
                    <div className={"about-content"}>
                        <Accordion
                            cards={accordionCards}
                        />
                        <div className={"youtube_video"}>
                            <iframe title={"aboutVideo"} width="560" height="315" src="https://www.youtube.com/embed/zaD07p2RAFk?rel=0"
                                    frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default About;
