import 'react-app-polyfill/ie11';
import 'core-js/fn/object/values';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './utils/polyfills';
import App from './App';
import Provider from "react-redux/es/components/Provider";
import store from "./store";
import {Storage} from '@decor-dev/wrappers/dist';
import AsyncStorage from "@decor-dev/async-storage/dist";
import {BrowserRouter, Router} from "react-router-dom";
import history from "./utils/history";
import Network from './utils/network';
import {Sentry} from '@decor-dev/wrappers/dist';
import {SENTRY_CLIENT_KEY} from './common/constants';
import WebSentry from './common/web-sentry';
import FBService from './common/facebook-service';
import GAService from './common/analytics-service';
import smoothscroll from 'smoothscroll-polyfill';
// import createHost from 'cross-domain-storage/host';
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

Storage.setDriver(new AsyncStorage());

Network.initialize(process.env.REACT_APP_API_PATH);

Sentry.setDriver(new WebSentry(), SENTRY_CLIENT_KEY);

GAService.init();

FBService.init();

smoothscroll.polyfill();

ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
            <Router history={history.getHistory()}>
                    <App/>
            </Router>
        </Provider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
