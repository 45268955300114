import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import * as selectors from "./selectors.js";
import * as actions from './actions';
import * as actionsTypes from './action-types';

export default (ContactGui) => {
    const mapStateToProps = (state) => ({
        showSuccess: selectors.selectShowSuccess(state),
        disableSendButton: selectors.selectDisableSendButton(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        sendContactForm: (payload) => dispatch(actions.sendContactForm(payload)),
        hideMessage: () => dispatch({type: actionsTypes.HIDE_MESSAGE})
    });

    class ContactWrapper extends Component {

        render() {
            return (
                <ContactGui
                    showSuccess={this.props.showSuccess}
                    sendContactForm={this.props.sendContactForm}
                    disableSendButton={this.props.disableSendButton}
                    hideMessage={this.props.hideMessage}
                />
            );
        }
    }

    ContactWrapper.propTypes = {
        showSuccess: PropTypes.bool,
        sendContactForm: PropTypes.func,
        disableSendButton: PropTypes.bool
    };

    return connect(mapStateToProps, mapDispatchToProps)(ContactWrapper);
};
