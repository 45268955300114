import React, {useState, useRef} from 'react';
import ValidatedForm from '../../../components/validated-form';
import InputValidator from '../../../common/input-validator';
import HelpiButton from '../../../components/helpi-button';
import {HELPI_BUTTON_CLASSES} from '../../../common/constants.js';
import {Link} from 'react-router-dom';
import './style.scss';
import ProfileWrapper from "../../../core/containers/auth/profile";
import HelpiRecaptcha from '../../../components/helpi-recaptcha';

const initState = {
    oldPass: '',
    newPass: '',
    newPassCheck: ''
}

const formFields = {
    oldPass: {
        validator: InputValidator.password,
        type: 'password',
        label: 'סיסמה נוכחית:',
        hint: 'צריכה להכיל 6 תווים לפחות',
        klass: 'auth-input',
        metaValue: '',
        required: true
    },
    newPass: {
        validator: (val) => {
            const error = InputValidator.password(val);
            if(error.length) {
                return error;
            } else if (formFields.newPass.metaValue === formFields.oldPass.metaValue) {
                return 'הסיסמה החדשה לא יכולה להתאים לסיסמה הקודמת';
            }
            return '';
        },
        type: 'password',
        label: 'סיסמה חדשה:',
        hint: 'צריכה להכיל 6 תווים לפחות',
        klass: 'auth-input',
        metaValue: '',
        required: true
    },
    newPassCheck: {
        type: 'password',
        label: 'אימות סיסמה חדשה:',
        hint: 'צריכה להכיל 6 תווים לפחות',
        klass: 'auth-input',
        metaValue: '',
        required: true,
        validator: (val) => {
            const error = InputValidator.password(val);
            if(error.length) {
                return error;
            }
            else if (formFields.newPass.metaValue !== val){
             return 'הסיסמאות לא תואמות'
            }

            return '';
        }

    },
}

const PasswordChange = (props) => {

    const recaptcha = useRef(null);

    const [formData, setForm] = useState(initState);
    const [valid, setValidity] = useState(false);
    const [token, setToken] = useState(null);

    const onSubmit = () => {
        props.updatePassword({oldPass: formData.oldPass, newPass: formData.newPass, user: props.user, token});

        recaptcha.current.execute();
    };

    const onValidChange = (v) => setValidity(v);

    const handleChange = (name, value, valid) => {
        setForm({...formData, [name]: value});
        formFields[name].metaValue = value;
    };

    return (
        <section className="change-password">
            <h1>שינוי סיסמה</h1>
            <ValidatedForm
                fields={formFields}
                handleFieldValueChange={handleChange}
                onValidChange={onValidChange}
            />
            <HelpiButton
                label='שינוי סיסמה'
                disabled={!valid}
                onClick={onSubmit}
                classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.THICK]}
            />
            <Link to="/profile">חזרה לעדכון פרטים</Link>
            {/* <HelpiRecaptcha
                ref={recaptcha}
                action="changePassword"
                onVerify={setToken}

            /> */}
        </section>
    )
};

export default ProfileWrapper(PasswordChange);