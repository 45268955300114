import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Geosuggest from 'react-geosuggest';
import {ADDRESS_FIELDS} from "../../common/constants";

class LocationAutoComplete extends PureComponent {

    constructor(props){
        super(props);

        this.onSuggestSelect = this.onSuggestSelect.bind(this);
    }

    /**
     * when a address been selected - arrange the address components by the allowed keys.
     * @param suggest
     */
    onSuggestSelect(suggest) {
        if(suggest) {
            const addressComponents = suggest.gmaps.address_components;

            //Search for the allowed address type fields in the address component from google maps
            const addressObj = addressComponents.reduce((acc, curr) => {
                curr.types.map((type) => {

                    //If the type is allowed we add to the new address object.
                    if (Object.keys(ADDRESS_FIELDS).find((item) => item === type)) {
                        return acc[ADDRESS_FIELDS[type]] = (type === 'country') ? curr.short_name : curr.long_name;
                    }

                    return null;
                });
                return acc
            }, {});

            const validatedAddressData = this.props.addressValidate(addressObj, this.props.name);
            this.setState(validatedAddressData);

        }

    }

    render() {

        return (
            <div className="location-auto-complete-wrapper">
                <label className={`location-label`}>{this.props.label}</label>
                <Geosuggest
                    country={'IL'}
                    placeholder={this.props.placeholder}
                    onSuggestSelect={this.onSuggestSelect}
                    ref={this.props.geosuggestRef}
                    types={this.props.types}
                />
                <span className={`indicator ${(!this.props.isValid) ? `error` : ``}`}>
                    {this.props.error}
                </span>
            </div>
        )
    }
}

LocationAutoComplete.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    geosuggestRef: PropTypes.object,
    types: PropTypes.array,
    error: PropTypes.string,
    isValid: PropTypes.bool
};

LocationAutoComplete.defaultProps = {
    types: []
};

export default LocationAutoComplete;