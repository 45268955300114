import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import HeaderMenuMobile from './header-menu-mobile';
import WhitelabelLogo from '../whitelabel-logo';
import PropTypes from "prop-types";
import AuthConsumer from './../../../core/containers/auth/auth-protected';
import {IS_DESKTOP} from "../../../common/constants";

const HeaderMobileClassList = ['mobile-drawer-container', 'side-nav-menu', 'side-nav-option'];

class HeaderMobile extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeDrawer: false,
            isScrolled: false,
        };

    }

    componentDidMount() {
        if(!IS_DESKTOP) {
            window.addEventListener('scroll', this.handleMobileHeaderScroll);
        }
    }

    componentWillUnmount() {
        if(!IS_DESKTOP) {
            window.removeEventListener('scroll', this.handleMobileHeaderScroll);
        }
    }

    handleMobileHeaderScroll = () => {
        const {isScrolled} = this.state;
        const scrollTop = window.scrollY;

        if(scrollTop > 60 && !isScrolled) {
            this.setState({...this.state, isScrolled: true})
        } else if (scrollTop < 60 && isScrolled) {
            this.setState({...this.state, isScrolled: false})
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if(!prevState.activeDrawer && this.state.activeDrawer) {
            document.addEventListener('click', this.handleClick)
        } else if(prevState.activeDrawer && !this.state.activeDrawer) {
            document.removeEventListener('click', this.handleClick);
        }
    }

    handleClick = (evt) => {
        const targetClassName = evt.target.className.split(' ')[0];
        if(!HeaderMobileClassList.includes(targetClassName)){
            this.handleToggleDrawer();
        }
    };

    handleToggleDrawer = () => {
        this.setState({activeDrawer: !this.state.activeDrawer});
    };

    logoClick = () => this.props.logoClick();

    render() {
        const {isScrolled} = this.state;

        const hamburger = <div className={`hamburger-icon`} onClick={this.handleToggleDrawer}>
                <div/>
                <div/>
                <div/></div>

        const isLogin = this.props.location.pathname === '/signin';

        const whiteLabel = this.props.whiteLabel.logoUrl ?
            <WhitelabelLogo isStrauss={this.props.isStrauss} logoUrl={this.props.whiteLabel.logoUrl} />
            : null;
        return (
            <div className={`small-nav-bar ${isScrolled ? 'scrolled' : ''}`}>
                {hamburger}
                <div className={'mobile-drawer-container'}>
                        <HeaderMenuMobile path={this.props.path} className={`${this.state.activeDrawer ? 'show' : 'hide'}`} user={this.props.user} logOut={this.props.logOut} items={this.props.items} onClickItem={this.handleToggleDrawer} whiteLabel={this.props.whiteLabel}/>
                </div>
                <div className="logo-wrap">
                {isLogin && whiteLabel}
                <Link className={"logo"} to={this.props.homePath} onClick={this.logoClick} />
                </div>

            </div>

        );
    }
}

HeaderMobile.propTypes = {
    items: PropTypes.array.isRequired,
    homePath: PropTypes.string.isRequired
};

export default withRouter(AuthConsumer(HeaderMobile));
