import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SuccessCard from '../../components/success-card';
import HeadingText from './../../components/heading-text';
import Utils from '../../common/utils';
import "./_style.scss";


class PictureList extends PureComponent {

    generateList = () => {
        const {type, items, itemsToDisplay, link} = this.props;
        

        const countedItems = Utils.pickRandomItems(items, itemsToDisplay);

        if(type === 'card') {
            return countedItems.map((it, i) => <li key={`success-${i}`}>
                <SuccessCard isLink={!!link} image={it.imgUrl} text={it.text} link={link} />
            </li>);
        }

        return countedItems.map((it, i) => <li key={`partner-${i}`} className="logo"><Link to={it.link ? it.link : link} target={it.link ? '_blank' : null}><img src={`${window.location.origin}${it.imgSrc}`} alt="" /></Link></li>);
    };

    render() {
        const {name, heading, type} = this.props;
        const list = this.generateList();

        const headingText = heading ? <HeadingText className="container" label={heading} /> : null;


        return (
            <div className="picture-list-container">
                <div className={`${name} ${type}`}>
                    {headingText}
                    <ul className={`${name}-container ${type}-container ${name === 'success' ? 'container' : ''}`}>
                        {list}
                    </ul>
                </div>
            </div>
        )
    }
}

PictureList.propTypes = {
    name: PropTypes.string.isRequired,
    heading: PropTypes.string,
    type: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    itemsToDisplay: PropTypes.number.isRequired,
    link: PropTypes.string
};

export default PictureList;