/**
 * Created by mac
 *
 * Created at: 21.2.2018
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: ClickOutside component
 */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

export default function (ComposedComponent, excludes) {

    class ClickOutside extends PureComponent {

        constructor(props) {
            super(props);
            this.state = {closed: true};

            this.close = this.close.bind(this);
            this.open = this.open.bind(this);
            this.toggle = this.toggle.bind(this);
            this.handleClick = this.handleClick.bind(this);
        }

        componentDidMount() {
            // document.addEventListener('click', this.handleClick);
            document.addEventListener('mousedown', this.handleClick);
            document.addEventListener('touchstart', this.handleClick);
        }

        componentWillUnmount() {
            // document.removeEventListener('click', this.handleClick);
            document.removeEventListener('mousedown', this.handleClick);
            document.removeEventListener('touchstart', this.handleClick);
        }

        handleClick(e) {
            // to ignore clicks on scrollbar
            //set clientX on mobile or desktop
            if(e.target.parentNode) {
                const clientX = e.touches ? e.touches[0].clientX : e.clientX;
                if (clientX <= document.documentElement.clientWidth) {
                    const className = e.target.className.split(' ')[0];
                    const parentClassName = e.target.parentNode !== '#document' && e.target.parentNode.className ? e.target.parentNode.className.split(' ')[0] : '';
                    const id = e.target.id;

                    if ((excludes
                            && e
                            && e.target
                            && (
                                excludes.indexOf(className) === -1
                                &&
                                excludes.indexOf(parentClassName) === -1
                            ))
                        || (id && id !== this.props.id)
                    ) {
                        this.close();
                    }
                }
            }
        }

        close() {
            this.setState({closed: true});

            // if there's a close callback
            if (this.props.onClose) {
                this.props.onClose.apply(this, []);
            }
        }

        open() {
            this.setState({closed: false});
        }

        toggle() {
            this.setState({closed: !this.state.closed});
        }

        render() {
            let props = {
                ...this.props,
                closed: this.state.closed,
                open: this.open,
                close: this.close,
                toggle: this.toggle
            };

            return (
                <div className="outsider" tabIndex="0" onBlur={() => excludes ? null : this.close()}>
                    <ComposedComponent ref="child" {...props} />
                </div>);
        }

    }

    ClickOutside.propTypes = {
        onClose: PropTypes.func
    };

    return ClickOutside;
}