export const FIELDS_TYPES = {
    NAME: 'name',
    PHONE: 'phoneNumber',
    EMAIL: 'email'
};

export const COLUMN_TITLES = {
    [FIELDS_TYPES.NAME]: 'שם',
    [FIELDS_TYPES.PHONE]: 'טלפון',
    [FIELDS_TYPES.EMAIL]: 'אימייל',
}

export const FIELDS_STRUCTURE =
    {
        // [FIELDS_TYPES.ID]: (item) => {
        //     return (
        //         {
        //             data: item[FIELDS_TYPES.ID] ? item[FIELDS_TYPES.ID] : '',
        //         }
        //     );
        // },
        [FIELDS_TYPES.NAME]: (item) => {
            return (
                {
                    data: item[FIELDS_TYPES.NAME] ? item[FIELDS_TYPES.NAME] : '',
                }
            );
        },
        [FIELDS_TYPES.PHONE]: (item) => {
            return (
                {
                    data: item[FIELDS_TYPES.PHONE] ? item[FIELDS_TYPES.PHONE].number : '',
                }
            );
        },
        [FIELDS_TYPES.EMAIL]: (item) => {
            return (
                {
                    data: item[FIELDS_TYPES.EMAIL] ? item[FIELDS_TYPES.EMAIL] : '',
                }
            );
        },
    };