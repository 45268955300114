import React, {PureComponent} from 'react';
// import './style.scss';
import PropTypes from "prop-types";

class AccordionCard extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            hidden: true
        };

        this.toggleActive = this.toggleActive.bind(this);
    }

    toggleActive(){
        this.setState({
            hidden: !this.state.hidden
        });
    }

    render() {
        const {card} = this.props;
        const {hidden} = this.state;
        return (
            <div className={`accordion-card-container`}>
                <h3 onClick={this.toggleActive}>
                    <span>{card.title}</span>
                    <i className={`fa ${hidden ? "fa-plus" : "fa-minus"}`} />
                </h3>
                <div className={`line-content-container`} hidden={hidden}>
                    {card.content}
                </div>
            </div>
        );
    }
}

AccordionCard.propTypes = {
    card: PropTypes.object
};

export default AccordionCard;
