/**
 * Created by mor on 03/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import {createBrowserHistory} from "history";

class HistoryNavigation {
    _history = createBrowserHistory();

    /**
     * navigate to a path
     * @param path
     */
    navigate(path, extDomain) {
        if(extDomain){
            window.location.href = `${window.location.protocol}//${extDomain}${path}`;
        } else {
            this._history.push(path);
        }
    }

    replace = (path) => this._history.replace(path);

    getHistory = () => this._history;
}

export default new HistoryNavigation();
