import UserService from '../../service';
import {ERROR_MESSAGE_TRANSLATE} from "../../../../../common/constants";
import {showSuccessToast, showErrorToast} from '../../../common/toast/actions';
import {showLoading, hideLoading} from 'react-redux-loading-bar';


export const updatePassword = ({oldPass, newPass, user, token}) => async (dispatch) => {

    const result = await UserService.updatePassword({oldPass, newPass, userId: user.id, token});
    const {success, data} = result;

    dispatch(showLoading());
    if (success) {

            showSuccessToast('הפעולה הושלמה בהצלחה');
            dispatch(hideLoading());
        } else {
            const error = ERROR_MESSAGE_TRANSLATE[data] || 'הסיסמה הנוכחית שהזנת לא נכונה'
            showErrorToast(error);
            dispatch(hideLoading());
        }
    // } else {
    //     showErrorToast('היתה שגיאה במערכת, אנא נסו שנית מאוחר יותר');
    //     dispatch(hideLoading());
    // }
};