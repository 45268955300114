/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import { createSelector } from 'reselect';

const _selectCasesState = (state) => state.homepage;

const _selectCases = (state) => _selectCasesState(state).cases;

const _selectFilters = (state) => _selectCasesState(state).filters;

export const selectCasesItems = createSelector(
    [_selectCases],
    (cases) => cases
);

export const selectCasesLoading = createSelector(
    [_selectCasesState],
    (cases) => cases.loading
);

export const selectCasesFilters = createSelector(
    [_selectFilters],
    (filters) => filters
);

export const selectCasesCount = (state) => _selectCasesState(state).count;

export const selectCasesSuccesses = (state) => _selectCasesState(state).success;

export const selectCasesPartners = (state) => _selectCasesState(state).partners;

export const selectNewsletterErrors = createSelector(
    [_selectCasesState],
    (cases) => cases.newsletterErrors
);

export const selectNewsletterSuccess = createSelector(
    [_selectCasesState],
    (cases) => cases.newsletterSuccess
);

export const newsletterUnsubscribeSuccess = createSelector(
    [_selectCasesState],
    (cases) => cases.newsletterUnsubscribeSuccess
);

export const selectSubscriber = createSelector(
    [_selectCasesState],
    (cases) => cases.subscriber
);

export const selectEmailValidationSuccess = createSelector(
    [_selectCasesState],
    (cases) => cases.emailValidationSuccess
);

export const selectEmailGenerationSuccess = createSelector(
    [_selectCasesState],
    (cases) => cases.emailGenerationSuccess
);


export const selectEmailValidationDetails = createSelector(
    [_selectCasesState],
    (cases) => cases.emailValidationDetails
)

export const selectFinishedHours = createSelector(
    [_selectCasesState],
    (state) => state.finishedHours
)