import * as actionTypes from "./action-types";

const initialState = {
    cityWhiteList: [],
    whiteLogo: false,
    specialHeaderClass: '',
    whiteLabel: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.UPDATE_WHITELIST: {
            const {payload} = action;
            return {
                ...state,
                cityWhiteList: payload
            };
        }

        case actionTypes.WHITE_HEADER_LOGO: {
            return {
                ...state,
                whiteLogo: !state.whiteLogo
            };
        }

        case actionTypes.RESET_LOGO: {
            return {
                ...state,
                whiteLogo: false
            }
        }

        case actionTypes.HEADER_CLASS: {
            const {payload} = action;
            return {
                ...state,
                specialHeaderClass: payload
            }
        }

        case actionTypes.RESET_HEADER_CLASS: {
            return {
                ...state,
                specialHeaderClass: ''
            }
        }

        case actionTypes.WHITE_LABEL_SUCCESS: {
            const {payload} = action;

            return {
                ...state,
                whiteLabel: payload
            }
        }

        default:
            return state;

    }
};
