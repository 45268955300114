import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HelpiButton from '../helpi-button';
import {HELPI_BUTTON_CLASSES, FILTERS_TYPES} from '../../common/constants';
import DropdownSelect from '../dropdown-select';
import MultiSelect from '../multi-select';
import InputWrapper from '../input-wrapper';
import {IS_SUBDOMAIN} from "../../common/constants";
import './_style.scss';

class Filters extends PureComponent {

    onFilterChange = (name, value) => this.props.onFilterChange(name, value);

    applyFilter = (e) => this.props.applyFilter(e);

    generateFilters = () => {

        const {filters, whiteLabel, alignDropsOnBottom} = this.props;
        // Attempting to remove this field in the filter
        delete filters.timeRange;
        return Object.keys(filters).filter(item => !filters[item].hide || filters[item].hide.apply(null, [whiteLabel])).map((key) => {
            const {type, label, addAll, data, includeLabel, placeholder} = filters[key];
        let elem;
        let value;
        if (this.props.values[key] === null) {
          value = "";
        } else {
          value = this.props.values[key];
        }
        const klass = filters[key]['klass'] ? filters[key]['klass'] : '';

            const filterData = typeof data === 'function' ? data.apply(null, [whiteLabel]) : data;

            const renderedLabel = typeof label === 'function' ? label.apply(null, [whiteLabel]) : label;
            const renderedPlaceholder = typeof placeholder === 'function' ? placeholder.apply(null, [whiteLabel]) : placeholder;

            if(type === FILTERS_TYPES.DROP) {
                elem = <DropdownSelect
                    label={renderedLabel}
                    placeholder={renderedPlaceholder}
                    addAllOption={addAll}
                    data={filterData}
                    onChange={this.onFilterChange}
                    value={value}
                    className={`filter-dropdown ${klass}`}
                    name={key}
                    includeLabel={includeLabel}
                />
        } else if (type === FILTERS_TYPES.MULTI) {
                elem = <MultiSelect
                    label={renderedLabel}
                    placeholder={renderedPlaceholder}
                    addAllOption={addAll}
                    data={filterData}
                    onChange={this.onFilterChange}
                    value={value}
                    className={`filter-dropdown ${klass}`}
                    name={key}
                    labelInDropdown={includeLabel}
                    alignOnBottom={alignDropsOnBottom}
                    id={`${key}-multi-select`}
                />
            } else {
                elem = <InputWrapper
                    type="text"
                    label={renderedLabel}
                    placeholder={renderedPlaceholder}
                    onChange={this.onFilterChange}
                    className={klass}
                    value={value}
                    name={key}
                    autocomplete={'nope'}
                />
            }

            return (
                <li key={`filter-${key}`}>
                    {elem}
                </li>
            )
        })
    };

    render() {
        const filterList = this.generateFilters();

        return (
            <div className={`filters filters-container container ${IS_SUBDOMAIN ? 'whitelabel' : ''}`}>
                <ul className={'filter-list'}>
                    {filterList}
                </ul>
                <HelpiButton
                    label={'חיפוש'}
                    classList={[HELPI_BUTTON_CLASSES.FILTER_SEARCH, HELPI_BUTTON_CLASSES.ORANGE]}
                    onClick={this.applyFilter}
                />
            </div>
        )
    }
}

Filters.propTypes = {
    filters: PropTypes.objectOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
        type: PropTypes.string.isRequired,
        addAll: PropTypes.bool,
        data: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
        klass: PropTypes.string,
        includeLabel: PropTypes.bool
    })).isRequired,
    applyFilter: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    alignDropsOnBottom: PropTypes.bool
};

Filters.defaultProps = {
    alignDropsOnBottom: false
};

export default Filters;