import Dialog from 'react-toolbox/lib/dialog/Dialog';
import React from "react";
import PropTypes from 'prop-types';
import './style.scss';
import {FontIcon} from "react-toolbox/lib/font_icon/FontIcon";

class Modal extends React.Component {

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
    }

    /**
     * toggle the modal
     */
    handleToggle(){
        this.props.handleToggle();
    }

    handleOverlayClick = () => {
        if(this.props.closeOnOverlayClick) {
            this.props.handleToggle();
        }
    };

    render () {
        return (
            <div className={'modal-container'}>
                <Dialog
                    active={this.props.show}
                    onEscKeyDown={this.handleToggle}
                    onOverlayClick={this.handleOverlayClick}
                    className={this.props.className}
                >
                    { this.props.showHeader &&
                        <div className={'modal-header'}>
                            <h2>{this.props.title}</h2>
                            {this.props.closeButton &&
                            <span className={`close-container`} onClick={this.handleToggle}><FontIcon value={`close`}/></span>}
                        </div>
                    }
                    {this.props.render({onClose: this.handleToggle, ...this.props})}
                </Dialog>
            </div>
        );
    }
}

Modal.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    show: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    closeButton: PropTypes.bool,
    closeOnOverlayClick: PropTypes.bool

};

Modal.defaultProps = {
    show: false,
    handleToggle : () => {},
    closeButton: true,
    closeOnOverlayClick: true,
    showHeader: true
};

export default Modal;
