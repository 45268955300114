import './_style.scss'
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { COLUMN_TITLES, FIELDS_STRUCTURE, FIELDS_TYPES } from "./constants";
import { USER_ROLES } from "../../common/constants";
import Modal from '../modal';
import HelpiButton from '../helpi-button';

const DeleteVolunteerModal = (props) => {
    return (
        <div className={'modal-volunteer-content content'}>
            <div className={`contact-text`}>
                <h2>{props.title}</h2>
                <h4>{props.text}</h4>
            </div>
            <div className={`actions`}>
                <HelpiButton classList={['orange']} label={`כן!`} onClick={props.onSubmit} />
                <HelpiButton classList={['transparent']} label={`לחצתי בטעות`} onClick={props.onClose} />
            </div>
        </div>
    )
};


class VolunteerList extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            volunteerToDelete: 0
        }
    }

    handleRemove = () => {
        const { volunteerToDelete } = this.state;
        const { handleRemoveVolunteer, caseId } = this.props;
        if (handleRemoveVolunteer) {
            handleRemoveVolunteer(caseId, volunteerToDelete);
        }
        this.toggleModal();
    };

    toggleModal = (e) => {
        const volunteerId = e ? e.target.id : 0;

        this.setState({ ...this.state, showDeleteModal: !this.state.showDeleteModal, volunteerToDelete: volunteerId })

    };


    render() {
        const { volunteers, addRemoveOption, leaderId } = this.props;

        const fieldTypes = Object.values(FIELDS_TYPES);

        const multipleVolunteers = volunteers.length > 1;

        return (
            <div className="volunteer-list-wrapper">
                <table className="volunteer-list-table" testid="volunteerListTable">
                    <thead>
                        {
                            <tr testid="titlesRow">
                                {
                                    addRemoveOption && <th testid="removeCol"> </th>
                                }
                                {
                                    fieldTypes.map((fieldType, fieldTypeIndex) => {
                                        return (
                                            <th testid="titleCol" className="title-col" key={`${fieldTypeIndex}-${fieldType}`}>{COLUMN_TITLES[fieldType]}</th>
                                        );
                                    })
                                }
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            volunteers.map((volunteerData) => {

                                const notLeader = volunteerData.id !== leaderId;
                                const isAdminConnected = this.props.user.role === USER_ROLES.ADMIN;

                                return (
                                    <tr testid="volunteerRow" className="volunteer-row" key={volunteerData.id}>
                                        {
                                            addRemoveOption &&
                                            <td testid="removeEmptyCell" className={'remove-cell'} key={`${volunteerData.id}-remove`}>
                                                {
                                                 isAdminConnected &&  (notLeader || (!multipleVolunteers && this.props.user && this.props.user.role === USER_ROLES.ADMIN)) &&
                                                    <div testid="iconContainer" className="icon-container" onClick={this.toggleModal} id={volunteerData.id}>
                                                        <i testid="icon" className={`material-icons`} id={volunteerData.id}>delete</i>
                                                    </div>
                                                }
                                            </td>
                                        }
                                        {
                                            fieldTypes.map((fieldItem, fieldItemIndex) => {
                                                return (
                                                    <td testid="volunteerData" key={`${volunteerData.id}-${fieldItemIndex}`}>
                                                        {FIELDS_STRUCTURE[fieldItem](volunteerData).data}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                );
                            }
                            )
                        }

                    </tbody>
                </table>
                <Modal
                    className="task-item-modal"
                    title={`הסרת מתנדב`}
                    text="בטוח/ה שאת/ה רוצה להסיר את המתנדב?"
                    render={(props) => <DeleteVolunteerModal {...props} onSubmit={this.handleRemove} />}
                    show={this.state.showDeleteModal}
                    handleToggle={this.toggleModal}
                    showHeader={false}
                />
            </div>
        )
    }
}

VolunteerList.propTypes = {
    volunteers: PropTypes.array.isRequired,
    entityName: PropTypes.string,
    leaderId: PropTypes.string,
    addRemoveOption: PropTypes.bool,
    handleRemoveVolunteer: PropTypes.func
};

VolunteerList.defaultProps = {
    entityName: '',
    leaderId: '',
    addRemoveOption: false
};

export default VolunteerList;
