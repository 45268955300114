/**
 * use a custom response interceptor for error handling
 */
import axios from 'axios';

// const ERROR = {
//     NOT_FOUND: 404
// };

class Network {
    baseURL = null;
    errorHandler = null;
    token = '';
    _axios = axios.create();

    /**
     *
     * @param baseURL
     * @param errorHandler
     * @param requestInterceptor
     * @param responseInterceptor
     */
    initialize(baseURL, errorHandler, requestInterceptor, responseInterceptor) {
        this.baseURL = baseURL;
        this.errorHandler = errorHandler;

        if (requestInterceptor) {
            this._axios.interceptors.request.use(resp => {
                requestInterceptor.apply(this, []);

                return resp;
            });
        }

        if (responseInterceptor) {
            this._axios.interceptors.response.use(null, responseInterceptor);
        }
    }

    /**
     * check instance validity
     * @returns {boolean}
     */
    checkInstance() {

        if (!this.baseURL) throw Error("Network instance was not initialized with a base URL!");

        return true;
    }

    /**
     * generate headers object
     * @returns {Promise.<{Authorization: void}>}
     * @private
     */
    async _getHeaders() {
        const token = this.token || await sessionStorage.getItem('token');
        return {
            'Authorization': token
        }
    }

    /**
     * setup auth token
     * @param token
     * @returns {Promise<void>}
     */
    async setToken(token) {
        if (!token) throw new Error('Invalid token');

        this.token = token;

        if(process.env.NODE_ENV === 'development') {
            sessionStorage.setItem('token', token);
        }

        // return await sessionStorage.setItem('token', token);
    }

    /**
     * handle API error
     * @param error
     * @returns {Promise.<*>}
     */
    handleError = (error) => Promise.reject(error);

    /**
     * generic request
     * @param options
     * @returns {Promise.<*>}
     */
    async request(options) {
        this.checkInstance();

        try {
            return await this._axios(options);
        } catch (error) {
            return await this.handleError(error);
        }
    }

    /**
     * post request generator
     * @param url
     * @param data
     * @returns {Promise.<*>}
     */
    async post(url, data = null) {
        this.checkInstance();

        return this.request({
            method: 'POST',
            url: `${this.baseURL}/${url}`,
            data,
            headers: await this._getHeaders()
        });
    }

    /**
     * put request generator
     * @param url
     * @param data
     * @returns {Promise.<*>}
     */
    async put(url, data) {
        this.checkInstance();

        return this.request({
            method: 'PUT',
            url: `${this.baseURL}/${url}`,
            data,
            headers: await this._getHeaders()
        });
    }

    /**
     * post request generator
     * @param url
     * @param params
     * @param isAbsolutePath
     * @returns {Promise.<*>}
     */
    async get(url, params = {}, isAbsolutePath = false) {
        this.checkInstance();

        return this.request({
            method: 'GET',
            url: isAbsolutePath ? url : `${this.baseURL}/${url}`,
            params,
            headers: await this._getHeaders()
        });
    }

    /**
     * delete request generator
     * @param url
     * @returns {Promise.<*>}
     */
    async delete(url) {
        this.checkInstance();

        return this.request({
            method: 'DELETE',
            url: `${this.baseURL}/${url}`,
            headers: await this._getHeaders()
        });
    }
}

export default new Network();
