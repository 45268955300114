import React, {useState, useEffect, useRef} from 'react';
import "./style.scss";

const ScrollBanner = ({children, alignStyle, hideBeforeSticky}) => {

    const banner = useRef(null);

    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        if(hideBeforeSticky) {
            window.addEventListener('scroll', handleShow);
        }

        return () => window.removeEventListener('scroll', handleShow);

    },[]);

    const style = {
        opacity: hidden ? 0 : 1,
        height: hidden ? 0 : 'auto',
        padding: hidden ? 0 : '5px',
        pointerEvents: hidden ? 'none' : 'auto',
        ...alignStyle
    };

    const handleShow = (e) => {
        const elTop = banner.current.getBoundingClientRect().top;

        if(elTop === 0) {
            setHidden(false)
        } else if (elTop > 0) {
            setHidden(true)
        }
    };

    return (
        <aside ref={banner} className={`scroll-banner ${!hidden ? 'show' : ''}`} style={style}>
            {children}
        </aside>
    )
};

ScrollBanner.defaultProps = {
    alignStyle: {top: 0},
    hideBeforeSticky: true,
};

export default ScrollBanner