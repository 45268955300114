import React from 'react';
import HeadingText from "../../components/heading-text";
import ContactForm from "../../components/contact-form";
import ContactMap from "../../components/contact-map";
import ContactWrapper from '../../core/containers/contact';
import './style.scss';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formSubject: this.props.match.params.formSubject || ''
        }
    }

    componentDidMount(){
        window.scroll(0,0);
    }

    componentWillUnmount(){
        this.props.hideMessage();
    }

    render() {
        const successMessage = (this.props.showSuccess) ?
            <div className={"send-success"}>הטופס נשלח בהצלחה!</div>
            : null;

        return (
            <div className={"container contact-container view-container"}>
                <HeadingText label={"בכל עניין אנחנו כאן"}/>
                {successMessage}
                <ContactForm
                    sendContactForm={this.props.sendContactForm}
                    subject={this.state.formSubject}
                    disableSendButton={this.props.disableSendButton}
                />
                <div className={'mapContainer'}>
                    <ContactMap />
                </div>
            </div>
        );
    }
}

Contact.propTypes = {
    sendContactForm: PropTypes.func,
    disableSendButton: PropTypes.bool
};

export default ContactWrapper(withRouter(Contact));