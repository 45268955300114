import React, {PureComponent} from 'react';
import './style.scss';
import {PARTNERS_SECTIONS_DATA} from "../../common/constants";
import HeadingText from "../../components/heading-text";


class Partners extends PureComponent {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {

        const imgElement = (partner) => <img src={partner.imgSrc} className={`partner-img`} alt={""}/>;

        const partnerElement = (partner) => {
            if(partner.link){
                return <a href={partner.link} target={`_blank`}>{imgElement(partner)}</a>;
            }
            else{
                return imgElement(partner);
            }
        };

        return (
            <div className={`container partners-view`}>
                    {PARTNERS_SECTIONS_DATA.map((item, index) => {
                        
                        console.log("index", index);
                        console.log("item", item);
                        return (
                            <div className={`partners-section`} key={`partners-section-${item.label}-${index}`}>
                        <div className={index === 0 ? 'section-title' : 'section-title small'}>
                            <HeadingText label={item.label} />
                        </div>
                        { index === 0 &&
                                <div  className='partners-description'>
                                    <br></br>
                                    <br></br>
                                    <div className='description-bold'>החברות המאמצות של הלפי מהוות עוגן ובסיס לפעילותה של העמותה ולהצלחתה.</div>
                                                            <div className='description'>חברות אלו הן שמאפשרות את המשך פעילותה של העמותה והמשך התפתחות הפלטפורמה, דבר המעיד על
                                                            התרבות הארגונית של אותן חברות ואיכות ההון האנושי המרכיב אותן,המונע מתוך חיבור עמוק לעולם ההתנדבות
                                                            ומחויבות לשינוי חברתי אמיתי בחברה הישראלית.</div>
                                                            <div className='description-bold'>תודה לכם!</div>
                                                            </div>}
                                <ul className={`partners-container`}>
                                    {item.data.map((partner, partnerIndex) => {
                                        return (
                                            <li  className={ index === 0 ?`partner`: `partner small-logo`} key={`partner-${partnerIndex}`}>
                                                {partnerElement(partner)}
                                            </li>
                                        );})
                                    }
                                </ul>
                            </div>
                            );})
                    }

            </div>
        );
    }
}


export default Partners;
