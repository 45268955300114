import {CONTACT_MAP} from "../../common/constants";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react'
import React, { PureComponent } from "react";



class ContactMap extends PureComponent {

    render() {
        return(
            <Map google={this.props.google}
                 initialCenter={CONTACT_MAP.officeLocation}
                 zoom={CONTACT_MAP.mapZoom}
                 className={'map'}
            >
                <Marker
                    name={'Current location'}
                    position={CONTACT_MAP.officeLocation} />
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: (CONTACT_MAP.apiKey),
    language: CONTACT_MAP.language,
})(ContactMap)