/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from './reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';

// export default createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(combinedReducers,composeEnhancers(applyMiddleware(thunk)));