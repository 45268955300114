import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CaseCard from '../case-card';
import { HELPI_BUTTON_CLASSES, EVENTS, IS_MOBILE, IS_SUBDOMAIN } from '../../common/constants';
import HeadingText from '../heading-text';
import HelpiButton from '../helpi-button';
import GAService from '../../common/analytics-service';
import DropdownSelect from '../dropdown-select';
import "./_style.scss";


class CasesGrid extends PureComponent {

    sortEvent = (label) => GAService.event({category: EVENTS.CASES.DISPLAY_NAME, action: EVENTS.CASES.ACTIONS.SORT, label});

    updateSort = (eOrName, v='') => {
        const name = typeof eOrName === 'object' && eOrName.target ? eOrName.target.getAttribute('name') : v;

        //label for GA event
        const label = name === 'newest' ? 'newerFirst' : 'urgentFirst';
        this.props.updateSort(name);

        this.sortEvent(label);
    };

    /**
     * Generates div for the sort
     * @returns {XML}
     */
    generateSort = () => {
        const { sort } = this.props;
        const urgent = sort === 'urgent';

        const desktop = <>
            <span>מיון לפי: </span>
            <span className={`sort-option ${!urgent ? 'selected' : ''}`} onClick={this.updateSort} name={'newest'}>חדש באתר</span>
            <span className="divider">|</span>
            <span className={`sort-option ${urgent ? 'selected' : ''}`} onClick={this.updateSort} name={'urgent'}>דחוף ביותר</span>
        </>;

        const mobile = <>
            <DropdownSelect
                name={'sort-options'}
                value={sort ? sort : 'newest'}
                onChange={this.updateSort}
                data={[
                    {
                        value: 'newest',
                        displayName: 'חדש באתר'
                    },
                    {
                        value: 'urgent',
                        displayName: 'דחוף ביותר'
                    }
                ]}
            />
        </>

        return (
            <div className="sort-options">
                {IS_MOBILE ? mobile : desktop}
            </div>
        )
    };

    /**
     *Generates the string of case count
     * @returns {XML}
     */
    generateCaseCount = () => {
        const { cases, count} = this.props;

        const fullText = `מציג ${cases.length} מתוך ${count} הזדמנויות `;

        return (
            <div className="cases-paging-title">
                {fullText}
            </div>
        )
    };

    getMoreCases = () => this.props.getMoreCases();

    /**
     * generates case cards
     */
    generateCases = () => {
        const { cases, user, onDisabledCaseClick } = this.props;
        const showAuthCaseBanner = !user;

        return cases.map(it => (
            <li key={it.id} ><CaseCard {...it} showAuthCaseBanner={showAuthCaseBanner} onDisabledCaseClick={onDisabledCaseClick} /></li>
        ))
    };

    render() {
        const sort = this.generateSort();
        const caseCount = this.generateCaseCount();
        const cases = this.generateCases();
        const buttonClassList = IS_SUBDOMAIN ? [HELPI_BUTTON_CLASSES.MORE_CASES, HELPI_BUTTON_CLASSES.ORANGE] : [HELPI_BUTTON_CLASSES.MORE_CASES]

        return (
            <div className={`top-section cases-grid-container container ${this.props.className}`} id="cases-grid-container">
                {/*<HeadingText label={'הזדמנויות התנדבות'} />*/}
                <div className="sort-and-count">
                    {caseCount}
                    {sort}
                </div>
                <ul className="cases-grid">
                    {cases}
                </ul>
                <div className="load-more-button">
                    <HelpiButton
                        disabled={this.props.cases.length === parseInt(this.props.count, 10)}
                        label={'עוד הזדמנויות'}
                        onClick={this.getMoreCases}
                        classList={buttonClassList}
                    />
                </div>
            </div>
        )
    }
}

CasesGrid.propTypes = {
    cases: PropTypes.array.isRequired,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    sort: PropTypes.string.isRequired,
    updateSort: PropTypes.func.isRequired,
    getMoreCases: PropTypes.func.isRequired
};

export default CasesGrid;