import React, {useState} from 'react';
import CheckboxWrapper from '../checkbox-wrapper';
import Radio from '../radio';
import TextAreaInput from '../text-area-input';
import InputValidator from '../../common/input-validator';
import ValidatedForm from '../validated-form';
import ValidatedInput from '../validated-input';
import {EMERGENCY_AREA_RADIO} from "../../features/request/constants";
import {REQUEST_DESCRIPTION_ROWS_NUM} from '../../common/constants';
import HelpiButton from '../helpi-button';
import RequestFormWrapper from '../../core/containers/request-form';
import AuthConsumer from '../../core/containers/auth/auth-protected'
import {TASK_TYPES, USER_STATES, TIME_RANGE_VALUES} from "../../common/constants"
import moment from 'moment'

const onBehalf = {
    firstName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם פרטי',
        required: true
    },
    lastName:{
        validator: InputValidator.name,
        type: 'text',
        label: 'שם משפחה',
        required: true
    },
    label:{
        type: 'label',
        label: 'במידה ותרצו שהקשר יעשה ישירות עם מבקש/ת העזרה, אנא מלאו את הפרטים הבאים לגביו/ה '
    },
    phoneNumber: {
        validator: InputValidator.phoneRequestForm,
        type: 'number',
        label: 'טלפון'
    },
    email: {
        validator: InputValidator.emailRequestForm,
        type: 'email',
        label: 'דוא״ל'
    }
};

const initForm = {
    onBehalf: false,
    onBehalfInfo: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        isValid: true,
    },
    area: EMERGENCY_AREA_RADIO[0].value,
    beneficiaries: 0,
    volunteers: 0,
    description: ''
};

const EmergencyRequestForm = (props) => {

    const [formState, setFormState] = useState(initForm);

    const handleChange = (value, name) => {
        const itemName = typeof name === 'object' && 'target' in name ? name.target.name : name;

        setFormState({
          ...formState,
          [itemName]: value
      })

    };

    const handleCheckboxChange = () => handleChange(!formState['onBehalf'], 'onBehalf')

    const handleSubmit = () => {
        const onBehalf = formState.onBehalf ? `
הבקשה הזאת נשלחה בשם:
  שם פרטי:${formState.onBehalfInfo.firstName},
שם משפחה:${formState.onBehalfInfo.lastName},
        טלפון:${formState.onBehalfInfo.phoneNumber},
        מייל:${formState.onBehalfInfo.email}
        ` : '';

        const beneficiaries = `מספר מוטבים: ${formState.beneficiaries}`;

        const area = `רוחב המקרה: ${EMERGENCY_AREA_RADIO.find(item => item.value === formState.area).label}`

        const volunteers = `מספר מתנדבים: ${formState.volunteers}`;

        const description = `${formState.description}`

        const combinedDescription = `
${description}
${onBehalf}
${area}
${beneficiaries}
${volunteers}
`

        const newCase = {
            description: combinedDescription,
            title: formState.description,
            petitionerId: props.user.id,
            allowedUserState: USER_STATES.USER_PENDING_APPROVAL,
            dueDate: moment().add(2, 'day').set({'hour': 11, 'minutes': 0, 'seconds': 0}),
            tasks: {
                title: formState.description,
                type: TASK_TYPES.EMERGENCY,
                description: formState.description,
                time_range: [TIME_RANGE_VALUES.FLEX]
            }
        }

        props.sendCaseRequest(newCase, props.user.id, TASK_TYPES.EMERGENCY)

    };

    const handleValidatedFormChange = (name, value) => {
        setFormState({
            ...formState,
            onBehalfInfo: {
                ...formState.onBehalfInfo,
                [name]: value
            }
        })
    }

    const onValidChange = (valid) => {
        setFormState({
            ...formState,
            onBehalfInfo: {
                ...formState.onBehalfInfo,
                isValid: valid && formState.onBehalfInfo.firstName.length && formState.onBehalfInfo.lastName.length
            }
        })
    }

    return (
        <div className="container ask-help-container ask-help">
            <div id={`title-container`}>
                <h2>טופס בקשה</h2>
            </div>
        <form id="emergency-request-form">
            <div className="description-container text-input inner-section">
                <TextAreaInput
                    label={'כאן כותבים את הבקשה'}
                    required={true}
                    rowsNum={REQUEST_DESCRIPTION_ROWS_NUM}
                    fieldName="description"
                    handleFieldChange={handleChange}
                />
            </div>
            <div className="someone-else-container inner-section">
                <CheckboxWrapper
                    label={'מגישים את הבקשה עבור מישהו/י אחר/ת'}
                    name={'onBehalf'}
                    checked={formState.onBehalf}
                    value={!formState.onBehalf}
                    onChange={handleCheckboxChange}
                />
                {
                    formState.onBehalf &&
                    <div className={`someone-else-form-container`}>
                        <ValidatedForm
                            fields={onBehalf}
                            onValidChange={onValidChange}
                            handleFieldValueChange={handleValidatedFormChange}
                        />
                    </div>
                }
            </div>
            <div className="inner-section">
                <h3>רוחב המקרה</h3>
                <Radio
                groupName="area"
                buttons={EMERGENCY_AREA_RADIO}
                currentValue={formState.area}
                handleChange={handleChange}
                includeGroupNameOnSubmit={true}
            />
            </div>
            <div className="inner-section">
                <h3>מספר מוטבים</h3>
                <ValidatedInput
                name="beneficiaries"
                value={formState.beneficiaries}
                onChange={handleChange}
                type="number"
            />
            </div>
            <div className="inner-section">
                <h3>מספר מתנדבים</h3>
                <ValidatedInput
                name="volunteers"
                value={formState.volunteers}
                onChange={handleChange}
                type="number"
            />
            </div>
            <div className="button-container inner-section">
                <HelpiButton
                    onClick={handleSubmit}
                    label={`שלח/י בקשה`}
                    disabled={!formState.onBehalfInfo.isValid}
                />
            </div>
        </form>
        </div>
    )
}

export default RequestFormWrapper(AuthConsumer(EmergencyRequestForm));