
class AuthMapper {
    /**
     * map server data to UI data
     * @param payload
     * @returns {{}}
     */
    mapToVm = (payload) => {
        const contactPerson = (payload.user.company && payload.user.company.contact_person) ? {contactPerson: {
                email: payload.user.company.contact_person.email,
                name: payload.user.company.contact_person.name,
                phoneNumber: payload.user.company.contact_person.phone_number,
            }} : {};

        const company = (payload.user.company) ? {company: {
                companyEmail: payload.user.company.company_email,
                id: payload.user.company.id,
                name: payload.user.company.name,
                ...contactPerson,
            },
        } : {};

        const notifications = (payload.user.notifications) ?
            {
                notifications: {
                    caseNewsletter: payload.user.notifications.case_newsletter,
                    newsletter: payload.user.notifications.newsletter,
                    sms: payload.user.notifications.sms
                }
            } : {};

        return ({
            user: {
                city: payload.user.city,
                ...company,
                email: payload.user.email ? payload.user.email : '',
                firstName: payload.user.first_name ? payload.user.first_name : '',
                id: payload.user.id,
                lastName: payload.user.last_name ? payload.user.last_name : '',
                ...notifications,
                password: payload.user.password,
                phoneNumber: payload.user.phone_number ? payload.user.phone_number : '',
                reqActions: payload.user.req_actions,
                role: payload.user.role,
                state: payload.user.state,
                updatedAt: payload.user.updated_at,
                regions: payload.user.regions,
                taskTypes: payload.user.task_types,
                idNumber: payload.user.id_number,
                fbUser: payload.user.facebook_user,
                whiteLabelSubdomain: payload.user.whitelabel_subdomain ? payload.user.whitelabel_subdomain : ''

            }
        })
    };

    /**
     * map UI data to server data
     * @param payload
     * @returns {{}}
     */
    mapToDto = (payload) => {
        const company = (payload.user.company) ? {company: {
                company_email: payload.user.company.companyEmail,
                id: payload.user.company.id,
                name: payload.user.company.name,
                contact_person: {
                    email: payload.user.company.contactPerson.email,
                    name: payload.user.company.contactPerson.name,
                    phone_number: payload.user.company.contactPerson.phoneNumber,
                }
            },
        } : {};

        const case_newsletter = payload.user.caseNewsletter;

        const newsletter = payload.user.newsletter;

        const sms = payload.user.sms;

        const notifications = case_newsletter || sms || newsletter
        ?
            {
                case_newsletter,
                newsletter,
                sms
            }
            :
            undefined;

        let userUpdate =  {
            city: payload.user.city,
            ...company,
            email: payload.user.email,
            first_name: payload.user.firstName,
            id: payload.user.id,
            last_name: payload.user.lastName,
            notifications,
            password: payload.user.password,
            req_actions: payload.user.reqActions,
            role: payload.user.role,
            state: payload.user.state,
            updated_at: payload.user.updatedAt,
            task_types: payload.user.taskTypes,
            regions: payload.user.regions,
            id_number: payload.user.idNumber
        };

        if(payload.user.phoneNumber) {
            userUpdate.phone_number = {
                number: payload.user.phoneNumber,
                country_code: 'IL'
            };
        }

        return userUpdate;
    }
}


export default new AuthMapper();