import React from 'react';
import './style.scss';
import HeadingText from "../../components/heading-text";
import {QUESTIONS, SECTIONS} from "./constants";
import Parser from "html-react-parser";


class Faq extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeSectionQuestions: []
        }
    }

    componentDidMount() {
        window.scroll(0,0);
    }

    changeActiveSection(newSection){
        const {section, link} = newSection;

        this.setState({
            activeSectionQuestions: QUESTIONS[section] ? QUESTIONS[section] : []
        });

        //Redirect to anther tab if it is a link to different page
        if(link){
            window.open(link, "_blank");
        }
    }

    render() {
        const {activeSectionQuestions} = this.state;

        return (
            <div className={`container faq-container faq-page`}>
                <HeadingText label={`שאלות ותשובות`} />
                <div className={`section-images`}>
                    <ul>
                        {SECTIONS.map((item, index) => {
                            return (
                                <li key={`section-image-${index}-${item.section}`}
                                    onClick={this.changeActiveSection.bind(this, item)}
                                >
                                    <img alt={"תמונת סוג שאלות"} src={item.img}/>
                                    <span className={`section-title`}>{item.title}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={`questions-container`}>
                    {
                        activeSectionQuestions.map((item, index) => {
                            return(
                                <div className={`question-container`} key={`question-${item.title}-${index}`}>
                                    <div className={`question`}>
                                        <span>{item.title}</span>
                                    </div>
                                    <div className={`answer`}>
                                        <span>{Parser(item.answer)}</span>
                                    </div>
                                    <div className={`return`} onClick={() => window.scrollTo(0, 0)}>
                                        <span>חזרה למעלה</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}


export default Faq;
