/**
 * Created by bargalili on 27/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Sentry for the browser platform
 */

import Raven from 'raven-js';

export default class WebSentry {

    /**
     * set sentry driver
     * @param driver
     */
    setDriver(driver) {
        this._driver = driver;

    }

    /**
     * initialize sentry
     * @param dsn
     * @param options
     */
    initialize(dsn, options) {
        Raven.config(dsn, options).install();
    }

    /**
     * set user context
     * @param user
     */
    setUserContext(user) {
        Raven.setUserContext({
            ...user
        });
    }

    /**
     * manually report exceptions
     * @param e
     */
    reportException(e, options) {
        Raven.captureException(e, options);
    }


    /**
     * manually report messages
     * @param msg
     */
    reportMessage(msg, options) {
        Raven.captureMessage(msg, options);
    }
}
