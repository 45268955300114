import React from 'react';
import PropTypes from 'prop-types';
import HelpiButton from '../helpi-button';
import {HELPI_BUTTON_CLASSES} from "../../common/constants";
import XOrange from '../../assets/img/x-orange.svg';
import GAService from '../../common/analytics-service';
import {EVENTS} from "../../common/constants";
import './style.scss';

const StickyBanner = (props) => {
    // render() {

        const {text, buttonText, buttonLink, alignment, isExternal} = props;

        const style = alignment === 'bottom' ? {bottom: '0'} : {top: '0'};

        const handleClose = () => props.handleClose();

        const handleEvent = () => GAService.event({category: EVENTS.STICKY_BANNER.DISPLAY_NAME, action: EVENTS.STICKY_BANNER.ACTIONS.CLICK})

        const onClick = () => {
            if(props.onClick) {
                props.onClick()
            } else {
                handleEvent()
            }
        }

        return (
            <div className="sticky-banner-container" style={style}>
                <div className="content container">
                    <div className="close" onClick={handleClose}><img src={XOrange} alt=""/></div>
                    <div className="text" dangerouslySetInnerHTML={{__html: text}} />
                    <HelpiButton
                        classList={[HELPI_BUTTON_CLASSES.ORANGE, HELPI_BUTTON_CLASSES.BOLD, HELPI_BUTTON_CLASSES.THICK]}
                        onClick={onClick}
                        label={buttonText}
                        ext={isExternal}
                        type={'link'}
                        to={buttonLink || null}
                    />
                </div>
            </div>
        )
    // }
}

StickyBanner.propTypes = {
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string,
    isExternal: PropTypes.bool,
    alignment: PropTypes.oneOf(['bottom', 'top'])
}

StickyBanner.defaultProps = {
    text: 'עובדי חברות שותפות יכולים לבחור ממגוון רחב יותר של התנדבויות. רוצים לצרף את החברה שלכם? לחצו כאן',
    buttonText: 'להצטרפות',
    buttonLink: '/signin',
    alignment: 'bottom',
    isExternal: true
};

export default StickyBanner;