import {TASK_TYPES, TASK_TYPES_DATA, USER_STATES, TIME_RANGES} from "../../common/constants";

export const FORM_EXTRA_COMPONENTS_TYPES = {
    DESTINATION: 'DESTINATION',
    LOCATION: 'LOCATION',
    DATE: 'DATE',
    GROUP: 'GROUP',
    USER_STATE: 'USER_STATE'
};

export const DATE_RADIO_BUTTONS_TYPES = {
    FLEX: 'flex',
    EXACT: 'exact',
    RANGE_OF_DATES: 'range_of_dates'
};

export const DATE_RADIO_BUTTONS = [
    {
        label: 'גמיש ובתיאום מראש',
        value: DATE_RADIO_BUTTONS_TYPES.FLEX,
        name: DATE_RADIO_BUTTONS_TYPES.FLEX,
    },
    {
        label: 'תאריך מדוייק',
        value: DATE_RADIO_BUTTONS_TYPES.EXACT,
        name: DATE_RADIO_BUTTONS_TYPES.EXACT
    },
    {
        label: "טווח תאריכים",
        value: DATE_RADIO_BUTTONS_TYPES.RANGE_OF_DATES,
        name: DATE_RADIO_BUTTONS_TYPES.RANGE_OF_DATES
    }
];

export const TIME_RANGE_RADIO_BUTTONS = TIME_RANGES.map(item => ({label: item.displayName, value: item.value, name: item.value}));

export const AUTH_USER_STATE_BUTTONS = [
    {
        label: 'לעובדי חברות בלבד',
        value: USER_STATES.USER_AUTHORIZED,
        name: USER_STATES.USER_AUTHORIZED
    },
    {
        label: 'לעובדי חברות והקהל הרחב',
        value: USER_STATES.USER_PENDING_APPROVAL,
        name: USER_STATES.USER_PENDING_APPROVAL
    }
]

export const TASK_TYPES_FORM_DATA = [
    {
        type: TASK_TYPES_DATA[TASK_TYPES.TRANSPORTATION].value,
        label: TASK_TYPES_DATA[TASK_TYPES.TRANSPORTATION].displayName,
        extraComponents: [
            FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DESTINATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE
        ],
        img: 'images/ask-help/escort.drive.png',
        exampleCases: "הסעה לבדיקה, ליווי לביטוח לאומי וכדומה",
        example: "גבר אלמן כבן 60 הסובל מחרדות ומחלת לב, אינו מגיע למעקב במרפאה לאחר שעבר צנתור כי אינו מצליח להגיע לחיפה לבדו. הוא זקוק לליווי מביתו בנשר למרכז רפואי לין בחיפה, המתנה של כשעה לכל היותר וחזרה לביתו. את התור ניתן לקבוע לפי זמינות התורים ונוחות המתנדב/ת. אין צורך בעזרה פיזית. משך משוער - סה\"כ שעתיים. ",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'האם מדובר בהסעה לכיוון אחד או הלוך חזור?',
            'האם נדרשת המתנה במקום?',
            'האם יש צורך ברכב?',
            'האם יש צורך בליווי פיזי (הרמה, עזרה בהליכה) וכדומה?',
            'מה משך הפעילות המשוער (הערכה של כמה זמן נדרש מהמתנדב)?'
        ]
    },
    {
        type:TASK_TYPES_DATA[TASK_TYPES.PRODUCT_REQUEST].value,
        label: TASK_TYPES_DATA[TASK_TYPES.PRODUCT_REQUEST].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.DESTINATION, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/product.donation.png',
        exampleCases: "בגדים חמים, צעצועים, רהיטים, הטענת כרטיסיית נסיעות בתחבורה ציבורית, סלי מזון וכדומה",
        example: "לצעירה בסיכון המטופלת בתכנית ה׳מנטורינג - נערות למען נערות׳ ונמצאת בתהליך שיקום ויציאה לעצמאות דרושה תרומת טוסטר אובן בגודל סטנדרטי (לתנור רגיל אין מקום בביתה). יש צורך בשינוע. ניתן למסור את הטוסטר לעו״ס המטפלת בצעירות הנמצאת באונ׳ ת״א בימים א-ה בין השעות 08:30 - 16:30",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'מה מידות המוצר?',
            'מה הכמות הנדרשת?',
            'האם יש צורך בשינוע המוצר? אם כן, לאילו שעות ניתן לתאם מסירה?'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.FOOD].value,
        label: TASK_TYPES_DATA[TASK_TYPES.FOOD].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/workshop.activity.png',
        exampleCases: "",
        example: "ארגון 'לתת' מחפש מתנדבים בעלי רכבים לחלוקת מזון למשפחות נזקקות בבת ים לקראת החגים. ניתן להגיע בין השעות 15:00 עד 18:00 לאיסוף החבילות ומשם יציאה לבית המשפחות. מומלץ להגיע עם בן/בת זוג לחלוקה בגלל מצוקת חניה באזור. כל זוג יקבל 5-6 חבילות מזון וכתובות לחלוקה. דרושה יכולת פיזית סבירה, חבילה שוקלת כ10 ק''ג. החלוקה אורכת כשעה - שעה וחצי לכל היותר.",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'מה כמות המתנדבים המינימלית והמקסימלית?',
            'האם נדרש כוח פיזי?',
            'מה משך הפעילות המשוער (הערכה של כמה זמן נדרש מהמתנדב)?',
            'האם נדרש רכב?',
            'האם ההתנדבות מתאימה למשפחה?'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.ACTIVITY].value,
        label: TASK_TYPES_DATA[TASK_TYPES.ACTIVITY].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/orb.png',
        exampleCases: "הרצאות העשרה והשראה במעונות יום לקשיש, סדנאות יצירה ובישול לנוער בסיכון וכדומה",
        example: "לבית החם לנערות במועצה אזורית מרום גליל מחפשים מתנדבות להעברת סדנה המתאימה לקבוצה של 15 נערות. הן תשמחנה לקבל סדנה שתתרום לגיבוש או העצמה של הקבוצה. הסדנה יכולה להיות בתחום האומנות, תנועה, יצירה, בישול, וכל דבר אחר הרלוונטי לנערות בגילאי 13-16. ימי הפעילות של הסדנאות בבית החם הם ראשון ושלישי בשעות 16:00- 18:00. ניתן להגיע בקבוצה של עד 3 מתנדבות.",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'מה כמות המתנדבים המינימלית והמקסימלית?',
            'מה כמות משתתפי הפעילות וטווח גילאים?',
            'מה תחומי העניין הרלוונטיים לפעילות (יצירה/ בישול/ הרצאה בתחום ספציפי)?',
            'מה טווח הימים והשעות האפשריים לביצוע הפעילות?'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.TECH_HELP].value,
        label: TASK_TYPES_DATA[TASK_TYPES.TECH_HELP].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/occupation.oriented.png',
        exampleCases: "סיוע בשימושי מחשב, סמארטפונים, אינטרנט ותוכנות שונות, תיקון והתקנת מוצרים טכנולוגיים וכדומה",
        example: "אשה פנסיונרית אשר החלה בלימודי פנאי באוניברסיטה על מנת להעביר את זמנה, זקוקה למתנדב/ת אשר ידריכו אותה בתפעול בסיסי של המחשב, בנוסף על תוכנות אופיס ואינטרנט. המועד ייקבע לפי נוחיותו של המתנדב/ת.",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'נסו לפרט ככל הניתן מה הידע הדרוש לביצוע ההתנדבות'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.MAINTENANCE].value,
        label: TASK_TYPES_DATA[TASK_TYPES.MAINTENANCE].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/light.maintenance.png',
        exampleCases: "צביעת דירה, סיוע בחלון תקוע, תליית מדפים ותמונות וכדומה",
        example: "אישה ערירית בת 43 במצב כלכלי קשה המתמודדת עם בעיות בריאות רבות זקוקה לסיוע בצביעת דירתה. האישה מתגוררת בדירה ישנה ומוזנחת ועקב מצבה הכלכלי והרפואי אינה מסוגלת לשפר את תנאי מגוריה או לממן צביעה מקצועית. הדירה הינה דירת 4 חדרים, וההתנדבות מתאימה לקבוצה של כ-10 מתנדבים. משך ההתנדבות המשוער: כ-4 שעות. על המתנדבים לרכוש את החומרים והציוד הדרושים לשפכטל ולצביעה.",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'מה גודל המקום שיש לטפל בו?',
            'האם נדרש ציוד / חומרים?',
            'האם נדרש ידע מקצועי?',
            'אנא פרטו ככל הניתן את הבעיה שיש לפתור ואת המצב הנוכחי'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.LONELINESS].value,
        label: TASK_TYPES_DATA[TASK_TYPES.LONELINESS].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/loneliness.png',
        exampleCases: "אירוח לחג, בילוי משותף ומשחק וכדומה",
        example: "אישה בודדה בת 50 המתמודדת עם נכות קשה, מחפשת מתנדב/ת להפגת בדידות. האישה תשמח למתנדב/ת שיגיעו לביתה לשוחח עימה, ללוות אותה לטיול בקרבת הבית או פעילות חברתית. האישה תקשורתית מאוד, מלווה על ידי מטפלת החיה בביתה. התיאום יעשה במעמד ההתנדבות לפי נוחות המתנדב",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'מה רמת התקשור של המוטב? האם נדרשות יכולות מיוחדות?',
            'האם נדרש ליווי או סיוע בהגעה?'
        ]
    },
    {
        type: TASK_TYPES_DATA[TASK_TYPES.OTHER].value,
        label: TASK_TYPES_DATA[TASK_TYPES.OTHER].displayName,
        extraComponents: [FORM_EXTRA_COMPONENTS_TYPES.LOCATION, FORM_EXTRA_COMPONENTS_TYPES.DATE, FORM_EXTRA_COMPONENTS_TYPES.USER_STATE],
        img: 'images/ask-help/other.png',
        exampleCases: "עזרה הדורשת ידע מקצועי, רכיבת אופניים, וכל מה שלא נכנס באף אחת משאר הקטגוריות.",
        example: "לפרויקט מיוחד של רכיבת אופניים של אנשים עם מוגבלות דרושים מתנדבים שיגיעו לרכוב עם האנשים. הרכיבה מתקיימת כל יום שלישי ורביעי אחר הצהריים. יש צורך להגיע עם אופניים. מתאים ליחידים וקבוצות. מתאים למקסימום 10 מתנדבים. אין צורך באופניים (יסופקו במקום).",
        prompts: [
            'רקע - מי זקוק לסיוע? ולמה?',
            'כל הפרטים הנדרשים להבנת הבקשה (שימו לב לדוגמה).'
        ]
    }
];

export const EMERGENCY_AREA_RADIO = [
    {
        label: 'ארצי',
        value: 'national',
        name: 'national'
    },{
        label: 'רשותי',
        value: 'network',
        name: 'network'
    },{
        label: 'אזורי',
        value: 'regional',
        name: 'regional'
    }
]