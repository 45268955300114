/**
 * Created by jacob on 22/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Map single case
 */
class SingleCaseMapper {
    /**
     * map server data to UI data
     * @param payload
     * @returns {{}}
     */
    mapToVm = (payload) => {
        return ({
            id: payload.id,
            title: payload.title,
            description: payload.description,
            state: payload.state,
            location: payload.location,
            dueDate: payload.due_date * 1000,
            createdAt: payload.created_at * 1000,
            tasks: payload.tasks.map((item) => {
                    return {
                        id: item.id ? item.id : "",
                        realDueDate: item.real_due_date ? item.real_due_date : false,
                        dueDate: item.due_date ? item.due_date : -1,
                        dueDateText: item.due_date_text ? item.due_date_text : "",
                        time: item.time ? item.time : null,
                        title: item.title ? item.title : "",
                        state: item.state ? item.state : "",
                        type: item.type ? item.type : "",
                        updatedAt: item.updated_at ? item.updated_at : -1,
                        createdAt: item.created_at ? item.created_at : -1,
                        startDate: item.start_date ? item.start_date : null,
                        startDateText: item.start_date_text ? item.start_date_text : null,
                        description: item.description ? item.description : "",
                        volunteers: item.volunteers ? item.volunteers : null,
                        volunteerId: item.volunteer_id ? item.volunteer_id : '',
                        previous_volunteer_id: item.previous_volunteer_id ? item.previous_volunteer_id: "",
                        groupVolunteers: item.group_volunteers ? item.group_volunteers : [],
                        location: item.location ? {
                            address: {
                                city: item.location.address.city,
                                country: item.location.address.country,
                                streetName: item.location.address.street_name,
                                streetNumber: item.location.address.street_number
                            },
                            geoLocation: {
                                lat: item.location.geo_location.lat,
                                lng: item.location.geo_location.lng
                            }
                        } : null,
                        destination: item.destination ? {
                            address: {
                                city: item.destination.address.city,
                                country: item.destination.address.country,
                                streetName: item.destination.address.street_name,
                                streetNumber: item.destination.address.street_number
                            },
                            geoLocation: {
                                lat: item.destination.geo_location.lat,
                                lng: item.destination.geo_location.lng
                            }
                        } : null
                    };
                }
            ),
            petitionerId: payload.petitioner_id,
            petitionerOrganization: payload.petitioner_organization,
            serialNumber: payload.serial_number,
            populations: payload.populations,
            minVol: payload.min_vol,
            maxVol: payload.max_vol,
            population: payload.population,
            completionData: payload.completion_data,
            numVolunteers: payload.num_volunteers,
            beneficiaryNum: payload.beneficiary_num,
            imgUrl: payload.img_url,
            isMultipleVolunteers: payload.is_multiple_volunteers ? payload.is_multiple_volunteers : false,
            maximumVolunteers: payload.maximum_volunteers ? payload.maximum_volunteers : 0,
            whiteLabel: payload.whitelabel ? {
                id: payload.whitelabel.id || "",
                type: payload.whitelabel.type || "",
                name: payload.whitelabel.name || "",
                logo: payload.whitelabel.logo || ""
            } : undefined,
            volunteerGroupLeader: payload.volunteer_group_leader ? payload.volunteer_group_leader : '',
            allowedUserState: payload.allowed_user_state

        });
    };


    /**
     * map UI data to server data
     * @param payload
     * @returns {{}}
     */
    mapToDto = (payload) => {
        return ({
            id: payload.id,
            title: payload.title,
            description: payload.description,
            state: payload.state,
            location: payload.location,
            petitioner_id: payload.petitionerId,
            img_url: payload.imgUrl,
            min_vol: payload.minVol,
            max_vol: payload.maxVol,
            duration: payload.duration,
            populations: payload.populations,
            num_volunteers: payload.numVolunteers,
            beneficiary_num: payload.beneficiaryNum,
            population: payload.population,
            is_multiple_volunteers: payload.isMultipleVolunteers,
            maximum_volunteers: payload.maximumVolunteers,
            whitelabel: payload.whiteLabel ? {
                    id: payload.whiteLabel.id || '',
                    type: payload.whiteLabel.type || ''
            } : null,
            allowed_user_state: payload.allowedUserState
        });
    }
}

export default new SingleCaseMapper();