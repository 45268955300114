import * as actionTypes from './action-types';

const initialState = {
    showSuccess: false,
    disableSendButton: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SEND_FORM_SUCCESS: {
            return {
                ...state,
                showSuccess: true
            };
        }
        case actionTypes.HIDE_MESSAGE: {
            return {
                ...state,
                showSuccess: false
            };
        }
        case actionTypes.TOGGLE_SEND_BUTTON: {

            return {
                ...state,
                disableSendButton: !state.disableSendButton
            };
        }
        default:
            return state;
    }
};
