import * as authActions from "./../action-types";
import {selectLoggedInUserId} from "./../selectors";
import Mapper from "./../mapper";
import * as actionTypes from "./action-types";
import UserService from "./../service";
import {showSuccessToast} from "../../common/toast/actions";
import GAService from 'react-ga';
import {EVENTS} from "../../../../common/constants"
import * as homeActions from '../../homepage/actions';

export const updateUserData = (payload) => async (dispatch, getState) => {

    const userId = selectLoggedInUserId(getState());
    const mappedPayload = Mapper.mapToDto({user: payload});
    const result = await UserService.update(userId, mappedPayload);
    const {success, data} = result;

    if (success) {
        GAService.event({category: EVENTS.PROFILE.DISPLAY_NAME, action: EVENTS.PROFILE.ACTIONS.UPDATE});
        dispatch({type: authActions.USER_AUTHENTICATION_SUCCESS, payload: Mapper.mapToVm({user: data})});
        dispatch({type: actionTypes.RESET_VALID_ERROR});
        showSuccessToast('הפעולה הושלמה בהצלחה');
        if (!data.req_actions.email) {
            dispatch(homeActions.generateEmailToken(data.id))
        }
    } else {
        let payload;
        if (data.errors) {
            payload = data.errors[0]['code']
        } else {
            payload = data.message ? data.message : data;
        }
        dispatch({type: actionTypes.SHOW_VALID_ERROR, payload})
    }
};

export const checkSMSCode = (payload) => async (dispatch, getState) => {
    const userId = selectLoggedInUserId(getState());
    const result = await UserService.checkSMSVerification(userId, payload);
    const {success} = result;

    dispatch({type: actionTypes.RESET_VALID_ERROR});

    if (success) {
        dispatch({type: actionTypes.TOGGLE_SMS_MODAL});
        showSuccessToast('תודה שנרשמת להלפי');
    } else {
        dispatch({type: actionTypes.SHOW_VALID_ERROR, payload: 'bad code'});
    }
};

export const getAllUserTasks = (type) => async (dispatch, getState) => {
    try {
        const userId = selectLoggedInUserId(getState());
        const result = await UserService.getAllUserTasks({status: type});
        const {success} = result;
        if (success) {
            if(type=='completed' || type === 'completed_for_record')
                dispatch({type: actionTypes.USER_TASK_COMPLETE_SUCCESS, result});
            else if(type =='future')
                dispatch({type: actionTypes.USER_TASK_FUTURE_SUCCESS, result});
        }
    } catch (e) {
        console.log('getAllUserTasks Error',e)
        dispatch({type: actionTypes.USER_TASK_COMPLETE_ERROR, e});
    }
}