import Network from '../utils/network';
import {SERVER_RESPONSES} from './constants';

class RestService {

    errorToSend = (err) => err && err.response ? err.response.data : SERVER_RESPONSES.GENERAL_ERROR;

    wrap = (success, data) => ({ success, data });

    /**
     * post request wrapper
     * @param url
     * @param data
     * @returns {Promise<{success, data}>}
     */
    post = async (url, data) => {
        try {
            const response = await Network.post(url, data);

            if (response) {
                return this.wrap(true, response.data);
            }
        } catch (e) {
            return this.wrap(false, this.errorToSend(e));
        }
    };

    /**
     * get request wrapper
     * @param url
     * @returns {Promise<{success, data}>}
     */
    get = async (url, data) => {
        const response = await Network.get(url, data);

        if (response) {
            return this.wrap(true, response.data);
        }

        return this.wrap(false, this.errorToSend(null));
    };

    /**
     * put request wrapper
     * @param url
     * @param data
     * @returns {Promise<{success, data}>}
     */
    put = async (url, data) => {
        try {
            const response = await Network.put(url, data);

            if (response) {
                return this.wrap(true, response.data);
            }
        } catch (e) {
            return this.wrap(false, this.errorToSend(e));
        }
    };

    /**
     * delete request wrapper
     * @param url
     * @return {Promise.<void>}
     */
    delete = async (url) => {
        try {
            const response = await Network.delete(url);

            if (response) {
                return this.wrap(true, response.data);
            }
        } catch (e) {
            return this.wrap(false, this.errorToSend(e));
        }
    }
}

export default new RestService();
