export const SECTIONS_NAMES = {
    VOLUNTEERS: 'volunteers',
    COMPANY: 'company',
    NEED_HELP: 'needHelp',
    ORG: 'org'
};
export const QUESTIONS = {
    [SECTIONS_NAMES.VOLUNTEERS]: [
        {
            title : "אני בכלל יכול/ה להתנדב?",
            answer : 'הלפי עושה כמיטב יכולתה להגן ולשמור על בטיחותם של המשתמשים בפלטפורמה. לכן, חילקנו את ההתנדבויות באתר לשתי קבוצות (לפי רמות רגישות) וחלק מההתנדבויות מתאפשרות רק דרך <a href=https://helpi.org.il/partners target=_blank style=font-size:17px;color:#22a5b9;text-decoration: underline;> ארגונים שותפים</a>. <br/>התחברו לאתר ותראו את הבקשות הרלוונטיות אליכם.'
        },
        {
            title : "אני רוצה שהחברה/ארגון בו אני עובד/ת יצטרפו",
            answer : 'מעולה, גם אנחנו. אפשר להשאיר פרטים <a style=font-size:17px;color:#22a5b9;text-decoration: underline; href=https://www.hello.helpi.org.il target=_blank>פה</a> ואנחנו ניצור איתך קשר בהקדם'

        },
        {
            title : "אני לא חלק מאף ארגון אבל בכל זאת רוצה לדבר איתכם, מה לעשות?",
            answer : 'כדאי לכתוב לנו <a style=font-size:17px;color:#22a5b9;text-decoration: underline; target=_blank href=https://helpi.org.il/contact>פה</a> וננסה לעזור.'
        },
        {
            title : "אוקיי, התנדבתי. מה אני צריכ/ה לדעת?",
            answer : 'יס עליך! כל כך מגניב שהתנדבת. מיד לאחר ההרשמה יגיע אלייך מייל שמחבר בינך לבין מקום ההתנדבות. בימים הקרובים יצרו איתך קשר לתיאום כל הפרטים. אם עדיין יש לך שאלות לקראת ההתנדבות, אנחנו <a style="font-size:17px;color:#22a5b9;text-decoration: underline;" target="_blank" rel="noopener noreferrer"href="/contact">פה</a>.'
        },
        {
            title : "מה קורה אחרי שנרשמתי להתנדבות?",
            answer : 'לאחר הרשמתך להתנדבות, יישלח מייל המחבר בינך לבין מבקש/ת הסיוע, על מנת שתוכלו לתאם ביניכן/ם את כל מה שצריך. אם לא קיבלת מייל, חשוב ליצור איתנו קשר <a style=font-size:17px;color:#22a5b9;text-decoration: underline; target=_blank href=https://helpi.org.il/contact>פה</a>.'
        },
        {
            title : "איך אני יודע/ת שהאדם שנפגשתי איתו לא יפגע או ינצל אותי?",
            answer : 'הפניות שלנו מתקבלות אך ורק דרך עובדים סוציאליים ברשויות ובעמותות מוכרות בארץ, כך שכל בקשה שמופיעה באתר עוברת סינון של גורמי רווחה מקצועיים.'
        },
        {
            title : "נרשמתי להתנדבות אבל לצערי זה לא מסתדר בסוף. למי מודיעים ואיך?",
            answer : 'חשוב לשלוח אלינו הודעה בהקדם האפשרי ל- <a href=mailto:info@helpi.org.il style=font-size:17px;color:#22a5b9;text-decoration: underline;>info@helpi.org.il</a> או לכתוב לנו <a style=font-size:17px;color:#22a5b9;text-decoration: underline; target=_blank href=https://helpi.org.il/contact>פה</a>'
        },
        {
            title : "הבנתי שאחת ההתנדבויות יכולה להיות מימון של מונית. איך זה עובד?",
            answer : 'זה נכון, הסעה היא התנדבות מבוקשת, ואם אין לך אוטו או שאין זמן להסיע, אפשר לממן מונית ע"י הזמנתה באחת מהאפליקציות המאפשרות תשלום בכרטיס אשראי. זאת כמובן לאחר שווידאת את כל הפרטים עם מבקש/ת הסיוע.'
        },
        {
            title : "ראיתי שיש צורך בהתנדבות של בעל מקצוע. אין לי את הידע, אבל חשבתי לממן ביקור של בעל מקצוע. אפשרי?",
            answer : 'אפשרי בהחלט! כל מה שצריך זה ליצור קשר עם בעל המקצוע, ולתאם מול מבקש/ת הסיוע.'
        },
        {
            title : "איך אני משנה את הפרטים האישיים שלי?",
            answer : 'לאחר התחברות לחשבונך יופיעו בסרגל העליון שבאתר המילים "שלום" עם שמך הפרטי. מתחת למילים האלו, יופיע הכפתור "עדכון פרטים", שם ניתן לשנות או לעדכן את פרטיך.'
        },
        {
            title: "פחות מתאים לי לקבל מכם סמסים, איך מבטלים?",
            answer: 'כנס/י לעמוד הפרופיל והסר/י את סימון קבלת ההודעות מאיתנו.'
        }
    ],
    [SECTIONS_NAMES.COMPANY]: [],
    [SECTIONS_NAMES.NEED_HELP]: [
        {
            title : "אני בכלל יכול/ה להעלות בקשה?",
            answer : 'הלפי הוא מיזם משותף של משרד הרווחה הממשלתי, במסגרתו עובדי מחלקות רווחה ונציגי ברחבי הארץ מעלים בקשות עבור מטופליהם. הפלטפורמה אינה פתוחה לקהל הרחב, אבל אם את/ה רוצה בכל זאת לדבר איתנו על העלאת בקשה - אפשר <a style=font-size:17px;color:#22a5b9;text-decoration: underline; target=_blank href=https://helpi.org.il/contact>לכתוב לנו פה</a>. אם את/ה חלק מרשות / עמותה שותפה - תוכל/י כמובן להעלות בקשות לאתר'
        },
        {
            title : 'אני רוצה שהרשות / עמותה שלי תוכל להעלות בקשות!',
            answer : 'מעולה, גם אנחנו. אפשר להשאיר פרטים <a href=https://www.hello.helpi.org.il/join target=_blank style=font-size:17px;color:#22a5b9;text-decoration: underline;>פה</a> ואנחנו ניצור איתך קשר בהקדם. '
        },

        {
            title : 'איך מעלים בקשה לאתר?',
            answer : 'פשוט מאוד: נכנסים ל<a style=font-size:17px;color:#22a5b9;text-decoration: underline; target=_blank href=https://helpi.org.il/ask-help>טופס בקשה</a>, וממלאים את הפרטים. לאחר 48 שעות לכל המאוחר, הבקשה תופיע באתר כך שמתנדבים יוכלו להירשם אליה. חשוב להעלות בקשות מפורטות ככל הניתן על מנת לקצר את זמני הטיפול.'
        },

        {
            title : 'ואם בקשתי לא עלתה?',
            answer : 'לעיתים מגיעות אלינו בקשות עם פרטים חסרים. אם בקשתך לא עלתה, אמורה לחכות לך הודעה במייל מאיתנו, להשלמת לבירור הפרטים החסרים. אם לא קיבלת מייל להשלמת פרטים, כדאי <a href=https://helpi.org.il/contact target=_blank style=font-size:17px;color:#22a5b9;text-decoration: underline;>לכתוב לנו.</a>'
        },

        {
            title : 'מה קורה אחרי שהבקשה שלי עלתה לאתר?',
            answer : 'כשהבקשה עולה לאתר, מתנדבים ומתנדבות פוטנציאליים יכולים לראותה, ולהירשם לביצועה. ברגע שמתנדב/ת נרשמ/ת לבקשתך, פרטיו או פרטיה יועברו אליך. זה הרגע ליצור קשר ולמסור את כל הפרטים הנחוצים לפעילות (כתובת, טלפון וכו\').'
        },

        {
            title : 'האם פרטים אישיים כלשהם יחשפו באתר?',
            answer : 'לעולם לא. לאתר יעלו פרטים הנחוצים לשם ההתנדבות. פרטים אישיים כמו שם משפחה, טלפון, כתובת מגורים, דוא"ל נשמרים לצרכים פנימיים בלבד.'
        },

        {
            title : 'העליתי בקשה לסיוע אבל אני צריכ/ה לשנות את פרטים שבה או לבטל אותה.',
            answer : 'חשוב לשלוח אלינו הודעה בהקדם ל- <a href=mailto:info@helpi.org.il style=font-size:17px;color:#22a5b9;text-decoration: underline;>info@helpi.org.il</a>'
        },

        {
            title : 'אילו סוגי סיוע ניתן לבקש?',
            answer : 'המון! ליווי, הסעה, העברת פעילות, עזרה במילוי מסמכים, תרומת מוצר ולמעשה כל מה שיכול להיפתר ע"י עזרה חד פעמית ונקודתית.'
        },
        {
            title:'עבור מי ניתן לבקש עזרה?',
            answer: 'עבור כל מי שמעל גיל 18, וזקוק או זקוקה לסיוע שיכול להיפתר ע"י עזרה חד פעמית ונקודתית. בקשות למי שטרם מלאו לו 18 יתאפשרו רק בהרשמה ובליווי של מבוגר/ת בעת הפעילות.'
        },
        {
            title: 'האם יש הלפי בכל חלקי הארץ?',
            answer: 'כן. בקשות לסיוע יכולות להתקבל מכל אזור בארץ'
        },
        {
            title : 'מותר לבקש את אותה העזרה כמה פעמים?',
            answer : 'בטח! אפשר לבקש את אותה העזרה יותר מפעם אחת, אבל צריך להעלות בקשה בכל פעם מחדש.'
        }
    ],
    [SECTIONS_NAMES.ORG]: []
};



export const SECTIONS = [
    {
        img: "images/ask-help/loneliness.png",
        title: "אני רוצה להתנדב בהלפי",
        section: SECTIONS_NAMES.VOLUNTEERS

    }, {

        img: "images/ask-help/ladder.png",
        title: "אנחנו חברה/ארגון שמתעניין בהתנדבויות עובדים",
        link: 'https://www.hello.helpi.org.il',
        section: SECTIONS_NAMES.COMPANY

    },{

        img: "images/ask-help/other.png",
        title: "אני רוצה להעלות בקשות לאתר",
        section: SECTIONS_NAMES.NEED_HELP

    },{

        img: "images/ask-help/occupation.oriented.png",
        title: "אנחנו רשות/עמותה שרוצה להיעזר",
        link: 'https://www.hello.helpi.org.il/join',
        section: SECTIONS_NAMES.ORG
    }
];