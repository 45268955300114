export const TOGGLE_SMS_MODAL = 'PROFILE@TOGGLE_SMS_MODAL';
export const SHOW_VALID_ERROR = 'PROFILE@SHOW_VALID_ERROR';
export const RESET_VALID_ERROR = 'PROFILE@RESET_VALID_ERROR';

export const USER_TASK_FUTURE_SUCCESS = 'PROFILE@USER_TASK_FUTURE_SUCCESS';
export const USER_TASK_FUTURE_ERROR = 'PROFILE@USER_TASK_FUTURE_ERROR';

export const USER_TASK_COMPLETE_SUCCESS = 'PROFILE@USER_TASK_COMPLETE_SUCCESS';
export const USER_TASK_COMPLETE_ERROR = 'PROFILE@USER_TASK_COMPLETE_ERROR';




