import {createSelector} from 'reselect';

export const selectAuth = (state) => state.auth;
const selectSignUp = (state) => state.signUp;

export const selectPendingTask = createSelector(
    [selectAuth],
    (auth) => auth.pendingTask
);

export const selectLoggedInUserData = createSelector(
    [(state) => selectAuth(state).user],
    (user) => user
);

export const selectLoggedInUserId = createSelector(
    [(state) => selectAuth(state).user],
    (user) => user && user.id
);

export const selectLoggedInReqActionsEmail = createSelector(
    [(state) => selectAuth(state).user],
    (user) => user && user.reqActions && user.reqActions.email
  
);

export const selectLoggedInUserRole = createSelector(
    [(state) => selectAuth(state).user],
    (user) => user && user.role
);

export const selectLoggedInUserCompanyId = createSelector(
    [(state) => selectAuth(state).user],
    (user) => user && user.company && user.company.id
);

export const selectAuthTestDone = createSelector(
    [selectAuth],
    (auth) => auth.authTestDone
);

export const selectAuthErrors = createSelector(
    [selectAuth, selectSignUp],
    (auth, signUp) => ({
        login: auth.errors,
        signUp: signUp.errors
    })
);

export const selectSignUpStage = createSelector(
    [selectSignUp],
    (signUp) => signUp.stage
);

export const selectEmailDomainAuthorized = createSelector(
    [selectSignUp],
    (signUp) => signUp.emailDomainAuthorized
);

export const selectCompanies = createSelector(
    [selectSignUp],
    (signUp) => {
        return signUp.companies}
);

export const selectEmailDomainAuthorizedType = createSelector(
    [selectSignUp],
    (signUp) => signUp.emailDomainType
);

export const selectVerificationStage = createSelector(
    [selectSignUp],
    (signUp) => signUp.verificationStage
);

export const selectRegistrationSuccess = createSelector(
    [selectSignUp],
    (signUp) => signUp.registrationSuccess
);

export const selectAuthenticationForContext = createSelector(
    [selectAuth],
    (auth) => ({
        authenticated: !!(auth.user && auth.user.id),
        user: auth.user
    })
);

export const selectForgetPassword = createSelector(
    [selectAuth],
    (auth) => auth.forgetPassword
);

export const selectCompleteDetails = createSelector(
    [selectAuth],
    (auth) => auth.completeDetails
);

export const selectValidError = createSelector(
    [selectAuth],
    (auth) => auth.validError
);


