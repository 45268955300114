/**
 * Created by mor on 03/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Authentication reducer
 */
import * as actionTypes from './action-types';
import {ERROR_MESSAGE_TRANSLATE} from '../../../common/constants';

export const initialStateAuth = {
    user: null,
    pendingTask: null,
    authTestDone: false,
    context: '',
    validError: '',
    forgetPassword: {
        sent: false,
        valid: false
    },
    completeDetails: false
};

export default (state = initialStateAuth, action = {}) => {

    switch (action.type) {
        case actionTypes.USER_AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                user: action.payload.user
            }
        }
        case actionTypes.AUTH_TEST_DONE: {
            return {
                ...state,
                authTestDone: true
            }
        }
        case actionTypes.ADD_PENDING_TO_AUTH_TASK: {
            const { payload } = action;
            return {
                ...state,
                pendingTask: payload
            }
        }
        case actionTypes.CLEAR_PENDING_TO_AUTH_TASK: {
            return {
                ...state,
                pendingTask: null
            }
        }
        case actionTypes.FORGOT_PASSWORD_SENT: {
            const { payload } = action;

            const sent = true;

            const valid = payload.success;

            return {
                ...state,
                forgetPassword: {
                    sent,
                    valid
                }
            }

        }
        case actionTypes.RESET_FORGOT_PASSWORD: {
            return {
                ...state,
                forgetPassword: {
                    sent: false,
                    valid: false
                }
            }
        }
        case actionTypes.UPDATE_FORGOTTEN_PASSWORD: {
            const {payload} = action;

            const sent = true;

            const valid = payload.success;

            return {
                ...state,
                forgetPassword: {
                    sent,
                    valid
                }
            }
        }
        case actionTypes.LOGIN_COMPLETE_DETAILS: {
            return {
                ...state,
                completeDetails: !state.completeDetails
            }
        }
        case actionTypes.SHOW_VALID_ERROR: {
            const {payload} = action;
            const validError = ERROR_MESSAGE_TRANSLATE[payload] || payload;

            return {
                ...state,
                validError
            }
        }
        case actionTypes.RESET_VALID_ERROR: {
            return {
                ...state,
                validError: ''
            }
        }
        default:
            return state;
    }
};
