/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React, { PureComponent } from 'react';
import './style.scss';
import {NavLink, Link} from 'react-router-dom';
import HomepageContainer from "../../core/containers/homepage";
import PropTypes from 'prop-types';
import {CaseShape} from "../../core/containers/homepage/shapes";
import CasesGrid from './../../components/cases-grid';
import {TASK_TYPE_FILTER, EMERGENCY_TASK_TYPE_FILTER, TIME_RANGES, REGION_FILTER, IS_MULTIPLE_FILTER, HP_FILTERS, SUCCESS_DISPLAY_HP, PARTNERS_DISPLAY_HP, PARTNERS_SECTIONS_DATA, EVENTS, IS_SUBDOMAIN, USER_STATES, IS_MOBILE,HELPI_BUTTON_CLASSES} from '../../common/constants';
import Filters from '../../components/filters';
import PictureList from '../../components/picture-list';
import NewsletterModal from '../../components/newsletter-modal';
import NewsletterUnsubscribeModal from '../../components/newsletter-modal/unsubscribe-update';
import Modal from '../../components/modal';
import StickyBanner from '../../components/sticky-banner';
import HelpiButton from '../../components/helpi-button';
import {Storage} from '@decor-dev/wrappers/dist';
import moment from 'moment';
import GAService from '../../common/analytics-service';
import Utils from '../../common/utils';
import history from '../../utils/history';
import _ from 'lodash';
import queryString from 'query-string';
import Logo from '../../assets/img/logo_helpi_2023.png';
import SuccessIcon from '../../assets/img/success_icon.svg';
import ScrollBanner from '../../components/scroll-banner';
import EmailValidationModal from '../../components/email-validation-modal';

const INITIAL_FILTERS = {
    taskTypes: [],
    region: [],
    timeRange: [],
    isMultipleVolunteers: [],
    keyword: '',
    pageNumber: 0
};

const DisabledCaseModal = (props) => {
    return (
        <div className={'modal-disabled-case-content content'}>
            <div className={`contact-text`}>
                <h2>הלפי עושה כמיטב יכולתה להגן ולשמור על בטיחותם של המשתמשים בפלטפורמה.</h2>
                <h4>לכן, חילקנו את ההתנדבויות באתר לשתי קבוצות (לפי רמות רגישות) וחלק מההתנדבויות מתאפשרות רק דרך <a href="https://www.hello.helpi.org.il/" target="_blank" rel="noopener noreferrer">חברות שותפות</a>.</h4>
                <p><NavLink to="/signin">התחברו לאתר </NavLink>ותראו את הבקשות המתאימות לכם  </p>
            </div>
            <div className={`actions`}>
                <HelpiButton classList={['transparent']} label={`סגירה`} onClick={props.onClose}/>
            </div>
        </div>
    )
}

let timer;

class Homepage extends PureComponent {

    constructor(props){
        super(props);

        const { filters } = props;

        this.state = {
            filters: {
                taskTypes: filters.taskTypes || INITIAL_FILTERS.taskTypes,
                region: filters.region || INITIAL_FILTERS.region,
                timeRange: filters.timeRange || INITIAL_FILTERS.timeRange,
                isMultipleVolunteers: filters.isMultipleVolunteers || INITIAL_FILTERS.isMultipleVolunteers,
                keyword: filters.keyword || INITIAL_FILTERS.keyword,
                pageNumber: INITIAL_FILTERS.pageNumber
            },
            showNewsletterModal: false,
            showNewsletterUpdate: false,
            showNewsletterUnsubscribeModal: false,
            searchParams: false,
            showStickyBanner: true,
            showDisabledCaseModal: false,
            showValidationModal: false,
            scrollToHeight: 0,
            successInBanner: null,
            backgroundImg: null
        }
    }

    componentDidMount() {
            //if it is a subdomain, wait for this call until we have white label props
            //also if no query in url, use general call
            // if(this.props.authTestDone){
            //     this.props.getItems();
            // }
                if(!this.props.successes.length) {
                    if (!IS_SUBDOMAIN) {
                        this.props.getSuccesses({companyId: null, number: SUCCESS_DISPLAY_HP})
                        this.props.getFinishedHours();

                    }

                    //this will fire when reentering homepage, after whiteLabel props have already been loaded
                    if (IS_SUBDOMAIN && this.props.whiteLabel.id) {
                        this.props.getSuccesses({whiteLabelId: this.props.whiteLabel.id, whiteLabelType: this.props.whiteLabel.type, number: SUCCESS_DISPLAY_HP})
                        this.props.getFinishedHours();
                    }
                }

                if(this.props.user) {
                    this.applyFilter();
                }

            window.scrollTo(0,0);

            if (this.props.location.search) {
                const query = queryString.parse(this.props.location.search);
                if (query.unsubscribetoken) {
                    this.props.newsletterUnsubscribe(query.unsubscribetoken);
                } else if (query.update_newsletter_preferences) {
                    this.props.getSubscriber(query.update_newsletter_preferences)
                } else if (query.newsletter && query.newsletter === '1') {
                    this.handleNewsletterModalToggle();
                } else if (query.email_token){
                    this.props.validateEmailToken(query.email_token);
                } else {
                    this.handleFiltersFromQuery(true);
                }
            }
    }

    getMultiSelectMap = () => (
        {
            taskTypes: this.props.whiteLabel.emergency ? [...EMERGENCY_TASK_TYPE_FILTER] : [...TASK_TYPE_FILTER],
            timeRange: TIME_RANGES,
            isMultipleVolunteers:IS_MULTIPLE_FILTER,
            region:REGION_FILTER
        }
    );

    componentDidUpdate(prevProps, prevState) {

        const userUpdatedCheck = (!prevProps.authTestDone && this.props.authTestDone)
            || (prevProps.authTestDone && !_.isEqual(prevProps.user, this.props.user))
            ||
            (this.state.searchParams && (!_.isEqual(this.state.filters, prevState.filters)))

        if(IS_SUBDOMAIN && !prevProps.whiteLabel.id && this.props.whiteLabel.id) {
            this.props.getSuccesses({whiteLabelId: this.props.whiteLabel.id, whiteLabelType: this.props.whiteLabel.type, number: SUCCESS_DISPLAY_HP})
            this.props.getFinishedHours();
            this.customBannerImage();
        }
        if(userUpdatedCheck) {
            const timeout = IS_SUBDOMAIN ? 2500 : 0;
            timer = setTimeout(() =>{this.applyFilter()}, timeout);
        }

        if(prevProps.cases.length > 0 && (prevProps.cases.length < this.props.cases.length)) {
            window.scrollTo(0, this.state.scrollToHeight);
        }

        if (!prevProps.successes.length && this.props.successes.length) {
            const successInBanner = this.props.successes.sort(() => 0.5 - Math.random()).slice(0,1)[0];
            this.setState({...this.state, successInBanner});

        }


        //If filters are reset by pressing on logo...
        if(!_.isEqual(prevProps.filters, this.props.filters)
            && _.isEqual(this.props.filters, {
                keyword: '',
                region: '',
                taskTypes: [],
                isMultipleVolunteers: null,
                sort: '',
                pageNumber: 0
            })){
            this.setState({
                ...this.state,
                filters: {
                    taskTypes: [],
                    region: '',
                    keyword: '',
                    isMultipleVolunteers: null,
                    pageNumber: 0
                }
            })
        }

        if(!prevProps.newsletterUnsubscribeSuccess && this.props.newsletterUnsubscribeSuccess) {
            this.setState({...this.state, showNewsletterUnsubscribeModal: true})
        }
        if(_.isEmpty(prevProps.subscriber) && !_.isEmpty(this.props.subscriber)){
            this.setState({...this.state, showNewsletterUpdate: true })
        }

        //if query params have been changed, but state filters are still unchanged
        if( this.props.location.search !== prevProps.location.search && _.isEqual(this.state.filters, prevState.filters)) {
            this.handleFiltersFromQuery(true);
        }


        if((!prevProps.emailValidationSuccess && this.props.emailValidationSuccess) ||  (!prevProps.emailGenerationSuccess && this.props.emailGenerationSuccess)) {
            this.toggleValidationModal();
        }


    }

    componentWillUnmount() {
        if(timer !== undefined) {
            clearTimeout(timer);
        }
    }

    /**
     * invoked on a filter change
     * @param name
     * @param value
     */
    onFilterChange = (name, value) => {

        const { filters } = this.state;
        if(name === 'taskTypes' || name === 'timeRange' || name === 'isMultipleVolunteers' || name === 'region'){

            const items = Utils.handleCheckboxClick(name, value, filters[name], this.getMultiSelectMap()[name]);

            return this.setState({filters: {...filters, [name]: [...items]}, searchParams: false});
        }
        this.setState({filters: {...filters, [name]: value}, searchParams: false});
    };

    /**
     * updates filters state for updates in search query
     * filters out mistyped or non-existing values
     */
    handleFiltersFromQuery = () => {
        let filtersValues = [];
        //building an array of all possible filter values
        //TODO: take this out of this function and make it higher level
        Object.values(HP_FILTERS).forEach((item) => {
                if(item.data) {
                    const data = typeof item.data === 'function' ? item.data.apply(null, [this.props.whiteLabel]) : Object.values(item.data)
                    const arr = data.map((val) => val.value);
                    filtersValues.push(...arr);
                }
        });

        const params = queryString.parse(this.props.location.search);
        //build object for internal state
        const stateObj = Object.keys(params).reduce((acc, key) => {
            const newKey = Utils.underToCamelCase(key);
            if (!(newKey in INITIAL_FILTERS)) {
                return acc;
            }
            if(key === 'task_types' || key === 'time_range' || key === 'is_multiple_volunteers' || key === 'region'){
                return {...acc, [newKey]: params[key].split(',').filter((i) => filtersValues.indexOf(i) > -1)}
            } else if(key === 'keyword') {
                return {...acc, [newKey]: params[key]};
            } else {
                return {...acc, [newKey]: filtersValues.indexOf(params[key]) > -1 ? params[key] : INITIAL_FILTERS[key]}
            }
        }, {});

        //return non-selected filters to their initial state
        const filters = this.state.filters;

        Object.keys(filters).forEach((key) => {
            if(!stateObj[key]) {
                stateObj[key] = INITIAL_FILTERS[key]
            }
        });
        this.setState({...this.state, filters: {...this.state.filters, ...stateObj}, searchParams: true})
    };

    /**
     * apply filter choices and send to actions
     */
    applyFilter = (e) => {
        const propsFilters = this.props.filters;
        const stateFilters = this.state.filters;
        const pageNumber =
            propsFilters.taskTypes !== stateFilters.taskTypes
            || propsFilters.location !== stateFilters.location
            || propsFilters.timeRange !== stateFilters.timeRange
            || propsFilters.isMultipleVolunteers !== stateFilters.isMultipleVolunteers
        ?
                0
                :
                this.state.filters.pageNumber;

        const offset = document.documentElement.clientWidth > 900 ? 20 : 50;
        const caseGrid = document.querySelector('#cases-grid-container');
        const caseGridHeight = caseGrid ? caseGrid.getBoundingClientRect().top - offset : 0;
        if(e) {
            window.scrollTo({top: caseGridHeight, left: 0, behavior: 'smooth'});
        }
        const stringify = Object.keys(stateFilters).reduce((acc, key) => {
            if(stateFilters[key] === null || !stateFilters[key].length){
                return acc;
            } else if (key === 'taskTypes' || key === 'timeRange' || key === 'isMultipleVolunteers' || key === 'region') {
                return {
                    ...acc,
                    [Utils.camelToUnderscore(key)]: stateFilters[key].join(',')
                }
            }
            return {
                ...acc,
                [Utils.camelToUnderscore(key)]: stateFilters[key]
            }
            }, {});

        //if search button was pressed
        if(e) {
            window.scrollTo({top: caseGridHeight, left: 0, behavior: 'smooth'});
            history.navigate({search: `?${queryString.stringify(stringify)}`});
            this.handleFilterEvents();
        } else {
            this.setState({...this.state, searchParams: false});
        }
        return this.props.applyFilter({...this.state.filters, sort: this.props.filters.sort, pageNumber})
    };

    /**
     * handle sending all filter events to google analytics
     */
    handleFilterEvents = () => {
        const filters = _.omit(this.state.filters, 'pageNumber');

        const filterMapper = {
            region: EVENTS.FILTERS.ACTIONS.REGIONS,
            taskTypes: EVENTS.FILTERS.ACTIONS.TASKS,
            isMultipleVolunteers: EVENTS.FILTERS.ACTIONS.MULTIPLE_VOLUNTEERS,
            keyword: EVENTS.FILTERS.ACTIONS.TITLE,
            timeRange: EVENTS.FILTERS.ACTIONS.TIME_RANGE
        };

        Object.keys(filters).forEach((filter) => {
            if(filters[filter] && filters[filter].length) {
                let label;
                if(filter === 'isMultipleVolunteers'){
                    label = filters[filter] === 'false' ? 'לבד' : filters[filter].length < 4 ? 'קבוצתית' : 'הכל'
                } else {
                    label = filters[filter].toString()
                }

                GAService.event({category: EVENTS.FILTERS.DISPLAY_NAME, action: filterMapper[filter], label });
            }
        })

    };

    updateSort = (name) => this.props.applyFilter({...this.props.filters, sort: name, pageNumber: 0});

    getMoreCases = () => {
        const {filters} = this.props;
        const filtersWithNewPage = {...filters, pageNumber: filters.pageNumber + 1};
        const currScroll = window.scrollY;
        this.setState({...this.state, scrollToHeight: currScroll});
        this.props.applyFilter(filtersWithNewPage);

        this.handleNewsletterOpen();
    };

    handleNewsletterOpen = async () => {
        const isSubscriber = this.props.user && this.props.user.notifications && this.props.user.notifications.caseNewsletter;
        const closeKey = await Storage.getItem('newsletterPopUp', '');

        if(!isSubscriber && !closeKey) {
            this.handleNewsletterModalToggle();
        }
    };

    handleNewsletterModalToggle = async () => {
        this.setState({...this.state, showNewsletterModal: !this.state.showNewsletterModal},
        GAService.event({category: EVENTS.NEWSLETTER.DISPLAY_NAME, action: this.state.showNewsletterModal === false ? EVENTS.NEWSLETTER.ACTIONS.OPENED : EVENTS.NEWSLETTER.ACTIONS.CLOSED}));
        Storage.setItem('newsletterPopUp', '', moment().unix())
    };

    closeNewsletterUpdate = () => {
        this.setState({...this.state, showNewsletterUpdate: false});
    };

    closeUnsubscribeModal = () => this.setState({...this.state, showNewsletterUnsubscribeModal: false});

    closeStickyBanner = () => this.setState({...this.state, showStickyBanner: false});

    toggleDisabledCaseModal = () => this.setState({...this.state, showDisabledCaseModal: !this.state.showDisabledCaseModal})

    toggleValidationModal = () => this.setState({...this.state, showValidationModal: !this.state.showValidationModal})

    handleGenerateValidationToken = () =>  this.props.generateEmailToken()

    customBannerImage = () => {
        const { whiteLabel } = this.props;

        if(IS_SUBDOMAIN && whiteLabel && whiteLabel.bannerImg) {
            if(IS_MOBILE && whiteLabel.bannerImgMobile) {
                // this.setState({...this.state, backgroundImg: whiteLabel.bannerImgMobile});
            }
            // this.setState({...this.state, backgroundImg: whiteLabel.bannerImg});
        }

    }

    render() {
        const { cases, count, filters, successes, totalSuccesses, user, whiteLabel, finishedHours, bannerSuccess, successesServed} = this.props;

        const {showStickyBanner, successInBanner, backgroundImg} = this.state;

        const successHeading =  _.isEmpty(whiteLabel) ? `סיפורי הצלחה` : `המתנדבים של ${whiteLabel.name}`;

        const bannerText = bannerSuccess && !IS_MOBILE ? bannerSuccess.text : 'לסיפורים שלכם';

        const successStrip = successesServed ?
            totalSuccesses === 0
                ?
                <>
                <h2>זה פשוט לעשות טוב.</h2>
                </>
                :
                <>
                <div className="hours">
                    <span className="number">{parseInt(finishedHours, 10).toLocaleString()}</span>
                    <span>שעות התנדבות</span>
                </div>
                    {!IS_MOBILE &&
                    <div className="successes-number">
                        <span className="number">{parseInt(totalSuccesses, 10).toLocaleString()}</span>
                        <span>סיפורי הצלחה</span>
                    </div>
                    }
                <div className="success">
                    <img src={SuccessIcon} alt=""/>
                    <p><Link to={'/success'}>{bannerText}</Link></p>
                </div>
                </>
            :
            null;
        return (
            <div className={'cases'}>
                    <div>
                        <div className={`header-container-cases ${IS_SUBDOMAIN ? 'whitelabel' : ''}`}>
                            {/*<div id="header-inner-wrapper">*/}
                                {/*<h1 dangerouslySetInnerHTML={{__html: bannerText}} />*/}
                            {/*</div>*/}
                            
                                { <div className='donors-mobile'>
                            <Link to="/partners">
                            <HelpiButton 
                            label="שותפים ותומכים"
                            classList={[HELPI_BUTTON_CLASSES.FILTER_SEARCH, HELPI_BUTTON_CLASSES.ORANGE]}/>
                          </Link>
                            </div>}
                            
                            <Filters
                                values={this.state.filters}
                                onFilterChange={this.onFilterChange}
                                filters={HP_FILTERS}
                                whiteLabel={this.props.whiteLabel}
                                applyFilter={this.applyFilter}
                            />
                        </div>
                            <ScrollBanner>
                                <Filters
                                    values={this.state.filters}
                                    onFilterChange={this.onFilterChange}
                                    filters={HP_FILTERS}
                                    whiteLabel={this.props.whiteLabel}
                                    applyFilter={this.applyFilter}
                                    alignDropsOnBottom={true}
                                />
                            </ScrollBanner>
                            <div className={`hours-successes-wrapper ${successesServed && totalSuccesses === 0 ? 'no-success' : ''}`}>
                                {successStrip}
                            </div>
                             {whiteLabel && <div className="powered"><span>Powered By</span><img src={Logo} alt=""/> </div>}
                        <div>
                            <CasesGrid
                                className={!user ? 'guest-user' : ''}
                                cases={cases}
                                pageNumber={filters.pageNumber}
                                count={count}
                                sort={filters.sort}
                                getMoreCases={this.getMoreCases}
                                updateSort={this.updateSort}
                                user={user}
                                onDisabledCaseClick={this.toggleDisabledCaseModal}
                            />
                            {successes && successes.length &&
                            <PictureList
                                name="success"
                                link="/success"
                                type="card"
                                heading={successHeading}
                                items={successes}
                                itemsToDisplay={SUCCESS_DISPLAY_HP}
                            />
                            }
                            <PictureList
                                name="partners"
                                link="/partners"
                                type="link"
                                heading="השותפים שלנו"
                                items={[...PARTNERS_SECTIONS_DATA[0].data, ...PARTNERS_SECTIONS_DATA[1].data]}
                                itemsToDisplay={PARTNERS_DISPLAY_HP}
                            />
                        </div>
                    </div>
                {(showStickyBanner
                    && user
                    && (((user.state === USER_STATES.USER_PENDING_APPROVAL || user.state === USER_STATES.USER_ACTION_REQUIRED)
                        && !user.company)
                    || user.state === USER_STATES.USER_VALIDATE_EMAIL)
                    ) &&
                <StickyBanner
                    handleClose={this.closeStickyBanner}
                    text={ user.state !== USER_STATES.USER_VALIDATE_EMAIL
                        ?
                        `עובדי <a target="_blank" rel="noopener noreferrer" href="https://www.hello.helpi.org.il/">חברות שותפות</a> יכולים לעשות עוד התנדבויות, רוצה לצרף את החברה שלך?`
                        :
                        `על מנת להירשם להתנדבות עליך לאשר את מייל האימות שנשלח אליך לכתובת המייל איתה נרשמת לאתר.<br> במידה ולא קיבלת מייל אימות לחץ כאן לשליחה חוזרת`
                    }
                    buttonText="לחצו כאן"
                    buttonLink={user.state !== USER_STATES.USER_VALIDATE_EMAIL ? 'https://www.hello.helpi.org.il/' : null}
                    onClick={this.props.generateEmailToken}
                />}
                <NewsletterModal
                    show={this.state.showNewsletterModal || this.state.showNewsletterUpdate}
                    userDetails={this.state.showNewsletterUpdate ? this.props.subscriber : this.props.user}
                    handleToggle={this.state.showNewsletterUpdate ? this.closeNewsletterUpdate : this.handleNewsletterModalToggle}
                    type={this.state.showNewsletterUpdate ? 'update' : 'sign-up'}
                    errors={this.props.newsletterErrors}
                    success={this.props.newsletterSuccess}
                    signUp={this.props.newsletterSignUp}
                    update={this.props.newsletterUpdate}
                    subscriber={this.props.subscriber ? this.props.subscriber : null}
                    searchToken={this.props.location.search ? this.props.location.search.split('=')[1] : null}
                />
                <NewsletterUnsubscribeModal
                    show={this.state.showNewsletterUnsubscribeModal}
                    handleClose={this.closeUnsubscribeModal}
                />
                <Modal
                    render={(props) => <DisabledCaseModal {...props} />}
                    show={this.state.showDisabledCaseModal}
                    handleToggle={this.toggleDisabledCaseModal}
                    showHeader={false}
                    className="disabled-case-modal"
                />
                <EmailValidationModal
                    show={this.state.showValidationModal}
                    generateToken={this.handleGenerateValidationToken}
                    handleToggle={this.toggleValidationModal}
                    valid={this.props.emailValidationDetails.valid}
                    cases={this.props.emailValidationDetails.cases}
                    generateSuccess={this.props.emailGenerationSuccess}
                />
            </div>
        );
    }
}

Homepage.propTypes = {
    cases: PropTypes.arrayOf(CaseShape),
    removeCase: PropTypes.func.isRequired,
    applyFilter: PropTypes.func.isRequired,
    getItems: PropTypes.func.isRequired,
    // loading: PropTypes.bool.isRequired
};

export default HomepageContainer(Homepage);
