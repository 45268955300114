import React, {Component} from 'react';
import CaseWrapper from '../../core/containers/case';
import './style.scss';
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {FacebookShareButton, WhatsappShareButton} from 'react-share';
import Task from "../../components/task";
import {CASE_STATES, TASK_STATES, USER_ROLES} from "../../common/constants";
import RoleProtected from './../../components/role-protected';
import VolunteerList from "../../components/volunteer-list/index";
import CopyToClipboard from 'react-copy-to-clipboard';
import {showSuccessToast} from "../../core/containers/common/toast/actions";
import RegistrationSuccess from '../../components/registration-success';
import EmailValidationModal from '../../components/email-validation-modal';



class Case extends Component {


    constructor(props) {
        super(props);

        this.copySpan = React.createRef();

        this.state = {
            caseCompanyId: '',
            isCompanyCasePage: false,
            hasVolunteers: false,
            groupLeaderId: '',
        };

        this.addVolunteerToCase = this.addVolunteerToCase.bind(this);


    }

    /**
     * add the user id to the case
     * @param caseId
     * @param taskId
     */
    addVolunteerToCase(caseId, taskId) {
        this.props.addVolunteerToCase(caseId, taskId);
    }

    addTaskToPendingTask = (taskId) => this.props.addTaskToPendingTask(this.props.caseData, taskId);

    componentDidUpdate(prevProps) {
        const {currentUser, caseData} = this.props;
        if (caseData.id !== prevProps.caseData.id ||
            (this.props.match.params.companyId !== prevProps.match.params.companyId) ||
            (currentUser !== prevProps.currentUser)
            ||
            ((!prevProps.caseData.whiteLabel && caseData.whiteLabel)
                ||
                (prevProps.caseData.whiteLabel && caseData.whiteLabel && caseData.whiteLabel.id !== prevProps.caseData.whiteLabel.id)
            )
        ) {
            window.scrollTo(0, 0);
            this.initData();
        }


    }

    initData() {
        const {caseData} = this.props;
        const task = caseData.tasks[0];
        const caseCompanyId = caseData.whiteLabel ? caseData.whiteLabel.id  : '';
        const isCompanyCasePage = !!caseCompanyId && this.props.location.pathname.endsWith('/gr');
        const groupLeaderId = task && caseCompanyId ? task.volunteerId : '';
        const hasVolunteers = caseData.isMultipleVolunteers && task.groupVolunteers.length > 0;

        this.setState({
            caseCompanyId,
            isCompanyCasePage,
            groupLeaderId,
            hasVolunteers
        });

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.caseData || this.props.match.params.companyId || this.state.isCompanyCasePage) {
            this.initData();
        }
    }

    showVolunteerButton() {
        const {caseData, currentUser, isCurrentUserVolunteers} = this.props;
        const task = caseData.tasks[0];

        const currentUserCompanyId = (currentUser && currentUser.company ) ? currentUser.company.id : '';
        // const isCurrentUserVolunteers = currentUser && task && task.groupVolunteers ? task.groupVolunteers.includes(currentUser.id) : false;
        if (caseData.isMultipleVolunteers) {
            if (isCurrentUserVolunteers || (this.state.caseCompanyId.length && currentUser && this.state.caseCompanyId !== currentUserCompanyId)) {
                return false;
            }
            else if (caseData.state === CASE_STATES.PARTIALLY_ASSIGNED || caseData.state === CASE_STATES.PENDING_INVOLVEMENT) {
                 if((task.groupVolunteers.length && caseData.maximumVolunteers > 0) && task.state !== TASK_STATES.TASK_PENDING_USER_APPROVAL ){
                     return task.groupVolunteers.length < caseData.maximumVolunteers
                 }

                 if(task.state === TASK_STATES.TASK_PENDING_USER_APPROVAL){
                     return false;
                 }

                return true;
            }
            return false;
        }
        else {
            return [TASK_STATES.TASK_PENDING, TASK_STATES.TASK_UNDEFINED].includes(task.state) && ![CASE_STATES.OVERDUE, CASE_STATES.DUPLICATE_MISTAKE].includes(caseData.state);
        }

    }

    copySuccessToast = () => showSuccessToast('הקישור הועתק!');


    toggleThankYou = () => this.props.toggleThankYou()

    toggleEmailModal = () => {
        this.props.closeGenerateTokenModal();
        this.setState({...this.state, showEmailModal: !this.state.showEmailModal})
    }

    handleGenerateToken = () => this.props.generateEmailToken();

    render() {

        const {caseData, currentUser} = this.props;

        const currentHref = window.location.href;

        const showVolunteerList = currentUser ? ( this.state.hasVolunteers && (currentUser.role === USER_ROLES.ADMIN ||currentUser.role === USER_ROLES.RESPONSIBLE|| currentUser.id === this.state.groupLeaderId) ) : false;
       
        //Update the document title according to the case title
        document.title = caseData.title;

        return (
            !this.props.caseData.showThankYou ?
            <div className={`case-detail-view container case-container`}>
                {(this.state.caseCompanyId && this.state.isCompanyCasePage && currentUser && currentUser.id === caseData.tasks[0].volunteerId) &&
                <div className={'group-leader-message'}>
                    <div className="message">
                        <span>{`${currentUser.firstName}, תודה על הרשמתך!`}</span>
                        <span>יצרנו לכם עמוד חדש להתנדבות</span>
                        <span>זה הקישור אותו ניתן להפיץ לעובדים להרשמתם להתנדבות זו:</span>
                        <span><a href={currentHref}>{currentHref}</a></span>
                        <CopyToClipboard text={currentHref} onCopy={() => this.copySuccessToast()}>
                            <span ref={this.copySpan} value={currentHref} style={{fontSize: '14px', cursor: 'pointer'}}>העתק</span>
                        </CopyToClipboard>
                    </div>
                </div>

                }
                <div className={`card-container`}>
                    <div className={`case-header-panel`}>
                        <div className={`content-box-img hide-xs`}>
                            <img alt={`תמונת ההתנדבות`} src={caseData.imgUrl}/>
                        </div>
                        <div className={`content-box-text`}>
                            <h1>{caseData.title}</h1>
                        </div>
                        <div className={`social-buttons`}>
                            <RoleProtected roles={[USER_ROLES.ADMIN]}>
                                <div className={`edit-icon`}><Link to={`/admin/#/admin/cases/${caseData.id}/${caseData.serialNumber}`} target="_blank" rel="noopener noreferrer"><i className={`material-icons`}>mode_edit</i></Link>
                                </div>
                            </RoleProtected>
                            <FacebookShareButton quote={caseData.title} url={currentHref}>
                                <div className={"fb-share-icon"}/>
                            </FacebookShareButton>
                            <WhatsappShareButton title={caseData.title} url={currentHref}>
                                <div className={"whatsapp-share-icon "}/>
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className={`case-information`}>
                        <div className={`panel-section-inner`}>
                            {caseData.tasks.map((item, index) => {
                                const showVolunteerButton = this.showVolunteerButton();

                                return (
                                    <Task item={item}
                                          key={`task-${item.id}-${index}`}
                                          caseTitle={caseData.title}
                                          caseId={caseData.id}
                                          serialNumber={caseData.serialNumber}
                                          showTooManyVolunteering={caseData.showTooManyVolunteering}
                                          showEmailNotVerified={caseData.showEmailNotVerified}
                                          showCanBeVolunteer={caseData.showCanBeVolunteer}
                                          showEmailModal={caseData.showEmailModal}
                                          caseState={caseData.state}
                                          isPending={caseData.state !== CASE_STATES.PENDING_INVOLVEMENT}
                                          numMaxVolunteers={caseData.maximumVolunteers}
                                          numVolunteers={item.groupVolunteers.length}
                                          addTaskToPendingTask={this.addTaskToPendingTask}
                                          addVolunteerToCase={this.addVolunteerToCase.bind(this, caseData.id, item.id)}
                                          currentUser={this.props.currentUser}
                                          showVolunteerButton={showVolunteerButton}
                                          isMultipleVolunteers={caseData.isMultipleVolunteers}
                                          isCompanyCasePage={this.state.isCompanyCasePage}
                                          caseCompanyName={caseData.whiteLabel ?  caseData.whiteLabel.name : null}
                                          caseCompanyId={caseData.whiteLabel ? caseData.whiteLabel.id : null}
                                          volunteerGroupLeader={caseData.volunteerGroupLeader}
                                          isCurrentUserVolunteers={this.props.isCurrentUserVolunteers}
                                          toggleEmailModal={this.toggleEmailModal}
                                          handleVolunteerModals={this.props.handleVolunteerModals}
                                          toggelTooManyVolunteering={this.props.toggelTooManyVolunteering}
                                          toggleCanBeVolunteer={this.props.toggleCanBeVolunteer}
                                          toggleVerifiedEmailModal={this.props.toggleVerifiedEmailModal}
                                          generateEmailToken={this.handleGenerateToken}
                                          
                                    />
                                )
                            })}
                        </div>
                        {
                            (showVolunteerList) &&
                            <div className={`volunteer-list-container`}>
                                <h3>רשימת המתנדבים</h3>
                                <VolunteerList
                                    volunteers={caseData.groupVolunteers}
                                    addRemoveOption={true}
                                    leaderId={this.state.groupLeaderId}
                                    entityName="case"
                                    handleRemoveVolunteer={this.props.removeVolunteerFromGroupCase}
                                    caseId={caseData.id}
                                    user={this.props.currentUser}
                                />
                            </div>
                        }
                    </div>
                </div>

            </div>
                :
                <>
                    {document.documentElement.clientWidth < 450 ? <div className={`close ${this.state.registering ? 'sign-up' : 'login'}`} onClick={this.leave}></div> : null}
                    <RegistrationSuccess
                        user={this.props.currentUser}
                        onClick={this.toggleThankYou}
                        subtitle="את/ה הלפר/ית"
                        message="פרטי ההתנדבות אצלך במייל"
                        fromVolunteer={true}
                        className="big-text"
                    />
                </>

        );
    }
}

Case.propTypes = {
    caseData: PropTypes.object,
    currentUser: PropTypes.object,
    removeVolunteerFromGroupCase: PropTypes.func
};

export default CaseWrapper(withRouter(Case));
