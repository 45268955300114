import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Input from 'react-toolbox/lib/input';
import './_style.scss';

class InputWrapper extends PureComponent {

    onChange = (value) => this.props.onChange(this.props.name, value);

    render() {

        const { type, label, name, value, className, required, disabled } = this.props;
        return (
            <div className="input-wrapper-container">
                <Input
                    type={type}
                    label={label}
                    name={name}
                    onChange={this.onChange}
                    value={value}
                    className={`${className ? className : ''} ${value ? 'has-value' : ''}`}
                    required={required}
                    disabled={disabled}
                />
            </div>
        )
    }
}

InputWrapper.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default InputWrapper;