import React from "react";
import ValidatedForm from "../../validated-form";
import HelpiButton from "../../helpi-button";
import InputValidator from "../../../common/input-validator";
import {HELPI_BUTTON_CLASSES, TASK_TYPE_FILTER, REGION_FILTER} from '../../../common/constants';
import MultiSelect from '../../multi-select';
import CheckboxWrapper from '../../checkbox-wrapper';
import Utils from '../../../common/utils';
import './style.scss';
import 'formdata-polyfill';

const FormFields = {
    firstName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם פרטי:',
        klass: 'auth-input'
    },
    lastName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם משפחה:',
        klass: 'auth-input'
    },
};

class BasicDetails extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            valid: true,
            firstName: '',
            lastName: '',
            tasks: [],
            regions: [],
            caseNewsletter: true
        };

        this.basicForm = React.createRef();
    }

    componentDidMount() {

        // if(this.props.user && this.props.user.firstName.length && this.props.user.lastName.length) {
        //     const form = document.querySelector('form.basic-details-form');
        //     console.log(form);
        //     form.append('firstName', this.props.user.firstName);
        //     form.append('lastName', this.props.user.lastName);
        //     this.setState({...this.state, firstName: this.props.user.firstName, lastName: this.props.user.lastName})
        // }

        this.addDefaultValuesToForm();
    }

    componentWillUnmount() {
        if(this.props.user && this.props.user.firstName.length && this.props.user.lastName.length) {
            FormFields.firstName.defaultValue = '';
            FormFields.lastName.defaultValue = '';
        }
    }

    /**
     * Handle multiselect selections
     * @param name
     * @param value
     */
    onMultiSelectSelect = (name, value) => {
        let values;
        const filter = name === 'tasks' ? TASK_TYPE_FILTER : REGION_FILTER;

        if(value === '*all*') {
            values = this.state[name].length === filter.length ? [] : filter.map((item) => item.value);
        } else if (this.state[name].indexOf(value) > -1) {
            values = this.state[name].filter((item) => item !== value);
        } else {
            values = [...this.state[name], value];
        }

        this.setState({...this.state, [name]: values})
    };

    /**
     * update valid status of form in state
     * @param valid
     */
    onValidChange = (valid) => {
        this.setState({ valid })
    };

    /**
     * update state for inputs
     * @param name
     * @param value
     * @param valid
     */
    handleFormInputChange = (name, value, valid) => this.setState({...this.state, [name]: value});

    /**
     * Submit form data to server
     * @param evt
     */
    onLoginDetailsSubmit = (evt) => {
        const {tasks, regions, firstName, lastName, caseNewsletter} = this.state;
        evt.preventDefault();

        const isFinalStage = this.props.stage === this.props.totalStages - 1;

        this.props.onCompleteDetailsSubmit({
            firstName,
            lastName,
            taskTypes: tasks,
            regions,
            caseNewsletter
        }, false, isFinalStage);
    };

    /**
     * newsletter checkbox
     */
    handleNewsletterClick = () => this.setState({...this.state, caseNewsletter: !this.state.caseNewsletter});

    /**
     * If user already has these fields saved (legacy user), include them
     * @returns {{firstName: {validator: InputValidator.name, type: string, label: string, klass: string}, lastName: {validator: InputValidator.name, type: string, label: string, klass: string}}}
     */
    addDefaultValuesToForm = () => {
        let fields = FormFields;
        if(this.props.user && this.props.user.firstName.length && this.props.user.lastName.length) {
            fields.firstName.defaultValue = this.props.user.firstName;
            fields.lastName.defaultValue = this.props.user.lastName;
            this.setState({...this.state, firstName: this.props.user.firstName, lastName: this.props.user.lastName})
        }

        return fields;
    };

    render() {
        //TODO: Refactor this component to use state rather than formData/refs. There are now two sources of truth due to how validations are working
        const {tasks, regions, firstName, lastName, caseNewsletter, valid} = this.state;
        const defaultValueToForm = this.addDefaultValuesToForm();

        return (
            <form onSubmit={this.onLoginDetailsSubmit} className="basic-details-form" ref={this.basicForm}>
                <ValidatedForm onValidChange={this.onValidChange} fields={defaultValueToForm} handleFieldValueChange={this.handleFormInputChange}/>
                <MultiSelect
                    name={'tasks'}
                    label={'איזה התנדבויות מעניינות אותך:'}
                    labelAbove={true}
                    id={'tasks-multiselect'}
                    value={tasks}
                    coloredCheckbox={'primary'}
                    noScroll={true}
                    labelInDropdown={false}
                    alignOnBottom={true}
                    placeholder={"אפשר לבחור יותר מאפשרות אחת"}
                    data={Utils.addAllItem(TASK_TYPE_FILTER)}
                    onChange={this.onMultiSelectSelect}
                />
                <MultiSelect
                    name={'regions'}
                    label={"איפה נוח לך:"}
                    labelAbove={true}
                    id={"regions-multiselect"}
                    value={regions}
                    coloredCheckbox={'primary'}
                    labelInDropdown={false}
                    noScroll={true}
                    alignOnBottom={true}
                    placeholder={"אפשר לבחור יותר מאפשרות אחת"}
                    data={Utils.addAllItem(REGION_FILTER)}
                    onChange={this.onMultiSelectSelect}
                />
                <div className="checkbox">
                    <CheckboxWrapper
                        colored={'primary'}
                        label={'אני רוצה לקבל הזדמנויות להתנדבות לאימייל'}
                        checked={caseNewsletter}
                        onChange={this.handleNewsletterClick}
                    />
                </div>
                <div className="under-fields"/>
                <HelpiButton disabled={!valid || !tasks.length || !regions.length || !firstName.length || !lastName.length}
                             type={'submit'}
                             label={this.props.totalStages > this.props.stage + 1 ? 'המשך' : 'סיום'}
                             classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}
                />
            </form>
        );
    }
}

export default BasicDetails;
