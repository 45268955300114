import RestService from './rest-service';

class Subscriber {

    get = (token) => RestService.get(`api/subscriber/${token}`);

    put = (payload, token) => RestService.put(`api/subscriber/${token}`, payload);

    post = (payload) => RestService.post('api/subscriber', payload);

    unsubscribe = (token) => RestService.post(`api/subscriber/unsubscribe/${token}`)

}

export default new Subscriber();