import PropTypes from 'prop-types';

export const ValidatedField = PropTypes.shape({
    type: PropTypes.oneOf(['text', 'number', 'email', 'phone', 'password']).isRequired,
    elementType: PropTypes.string,
    validator: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    showValidateIcon: PropTypes.bool,
    validateIcon: PropTypes.any
});


export const ValidatedLabel = PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string.isRequired
});

