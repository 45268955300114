
class FilterMapper {
    /**
     * map server data to UI data
     * @param payload
     * @returns {{}}
     */
    mapToVm = (payload) => (
        {
            pageNumber: payload.page_number || 0,
            region: payload.region,
            timeRange: payload.time_range,
            taskTypes: payload.task_types.join(','),
            isMultipleVolunteers: payload.is_multiple_volunteers,
            keyword: payload.keyword
        }
    );

    /**
     * map UI data to server data
     * @param payload
     * @returns {{}}
     */
    mapToDto = (payload) => {
        if(payload) {
            let groupVolunteerMin = undefined;
            let groupVolunteerMax = undefined;
            // when choiosing 'All' the length is 4
            if(payload.isMultipleVolunteers && payload.isMultipleVolunteers !== 'false' && payload.isMultipleVolunteers.length < 4) {  

                let filtersSorted = this.sortFilters(payload.isMultipleVolunteers);
                
                let min = filtersSorted[0];
                let max = filtersSorted.length > 1 ? filtersSorted[filtersSorted.length -1] : undefined;
                if (filtersSorted.length === 1 && filtersSorted[0] == '2'){
                    min = undefined;
                    max = '2';
                    }
                if (max ==='21') {
                    max = undefined
                }
                groupVolunteerMin = min;
                groupVolunteerMax = max;
            }

            return {
                page_number: payload.pageNumber || 0,
                region: payload.region && payload.region.length ? payload.region.join(',') : undefined,
                time_range: payload.timeRange && payload.timeRange.length ? payload.timeRange.join(',') : undefined,
                task_types: payload.taskTypes && payload.taskTypes.length ? payload.taskTypes.join(',') : undefined,
                is_multiple_volunteers: payload.isMultipleVolunteers ? payload.isMultipleVolunteers === 'false' ? 'false' : 'true' : undefined,
                group_volunteer_min: groupVolunteerMin,
                group_volunteer_max: groupVolunteerMax,
                keyword: payload.keyword
            }
        } else {
            return {page_number: 0};
        }
    }

    sortFilters(filters) {
        let arr = [];
        for(let f of filters) {
            arr.push(...f.split('-'))
        } 
        return arr.sort(function(a, b){return a-b})
    }
}


export default new FilterMapper();