import RestService from './rest-service';

class EmailValidService {

    validate = (token) => RestService.post('api/email_token/validate', {token});

    send = ({userId, whiteLabel}) => RestService.post('api/email_token/generate', {user_id: userId, whitelabel: whiteLabel})

}

export default new EmailValidService();