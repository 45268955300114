import {toast} from "react-toastify";
import {TOAST_TIMEOUT} from "../../../../common/constants";

const toastDefaultOptions = {
    position: "bottom-left",
    autoClose: TOAST_TIMEOUT,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false
};

export function showSuccessToast(message='הפעולה הושלמה בהצלחה'){
    if(Array.isArray(message)) {
        message.forEach((item) => toast.success(item, toastDefaultOptions))
    } else {
        toast.success(message, toastDefaultOptions);
    }
}

export function showErrorToast(message='היתה שגיאה במערכת, אנא נסו שנית מאוחר יותר'){
    if(Array.isArray(message)) {
        message.forEach((item) => toast.error(item, toastDefaultOptions))
    } else {
        toast.error(message, toastDefaultOptions);
    }}