import React from 'react';
import StraussAdd from '../../../assets/img/strauss-2.png';

const WhitelabelLogo = (props) => {

    return (
        <div className={`whitelabel-logo ${props.isStrauss ? 'strauss' : ''}`}>
            <img src={props.logoUrl} alt="Whitelabel Logo"/>
            {/*{props.isStrauss &&*/}
            {/*<img src={StraussAdd} alt="" style={{display: 'inline-block'}}/>*/}
            {/*}*/}
        </div>
    )
};

export default WhitelabelLogo;
