import React from 'react';
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';


class FooterMenu extends React.PureComponent {

    /**
     * generate link according to item metadata
     * @param item
     * @returns {*}
     */
    static generateLink = (item) => {
        return <NavLink to={item.link}>{item.label}</NavLink>;
    };

    render() {
        return (
            <ul className="footer-links">
                {
                    this.props.items.map((item, index) => (
                        <li key={`${item.link}-${index}`} className={"link"}>
                            {FooterMenu.generateLink(item)}
                        </li>
                    ))
                }
            </ul>
        );
    }
}

FooterMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

export default FooterMenu;