import React, { PureComponent } from 'react';
import './style.scss'
import HelpiButton from "../helpi-button";
import PropTypes from 'prop-types';
import 'formdata-polyfill';
// import HelpiReCaptcha from '../helpi-recaptcha';


class ContactForm extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            token: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }


    onSubmit(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target);

        let data = {};
        for (let [key, value] of formData.entries()) {
            data[key] = value;
        }

        data['g-recaptcha-response'] = this.state.token;

        this.props.sendContactForm(data);

    }

    verifyCallback = (token) => this.setState({token});

    render() {
        return (
            <div className={"form-container"}>
                <form onSubmit={this.onSubmit} className={"contact-us-form"}>
                    <input name={'name'} type="text" placeholder="שם מלא" required  />
                    <input name={'email'} type="email" placeholder="e-mail" required/>
                    <input name={'subject'} type="text" placeholder="נושא" required defaultValue={this.props.subject} />
                    <textarea name={'message'} placeholder="תוכן ההודעה" required />
                    <HelpiButton type={'submit'} classList={["submit-contact"]} label={"שליחה"} disabled={this.props.disableSendButton}/>
                </form>
                {/* <HelpiReCaptcha
                    action="Contact"
                    onVerify={this.verifyCallback}
                /> */}
            </div>
        );
    }
}

ContactForm.propTypes = {
    subject: PropTypes.string,
    sendContactForm: PropTypes.func,
    disableSendButton: PropTypes.bool
};

export default ContactForm;