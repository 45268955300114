/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import {combineReducers} from "redux";
import homepage from './core/containers/homepage/reducer';
import contact from './core/containers/contact/reducer';
import auth from './core/containers/auth/reducer';
import signUp from './core/containers/auth/sign-up/reducer';
import profile from './core/containers/auth/profile/reducer';
import caseData from './core/containers/case/reducer';
import AuthRelatedReducer from "./core/containers/auth/auth-protected/reducer";
import {loadingBarReducer} from 'react-redux-loading-bar';
import rootReducer from "./core/containers/root-reducer/reducer";
import successReducer from './core/containers/success/reducer';

export default combineReducers({
    homepage,
    auth: AuthRelatedReducer(auth),
    signUp,
    profile,
    contact,
    caseData,
    loadingBar: loadingBarReducer,
    rootReducer,
    successReducer
});