import React, { PureComponent } from 'react';
import Input from 'react-toolbox/lib/input';
import PropTypes from 'prop-types';
import ToolTipWrapper from '../tool-tip-wrapper';
import visibilityOff from '../../assets/img/login/visibility_off.png';
import visibilityOn from '../../assets/img/login/round-visibility.png';
import "./style.scss";


class ValidatedInput extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
            showPasswordButton: props.value.length > 0
        }
    }

    showPassword = () => {
        this.setState({...this.state, showPassword: !this.state.showPassword})
    };

    toggleShowPassword = (e) => {
        this.setState({...this.state, showPasswordButton: !this.state.showPasswordButton})
    };

    onChange = (value, { target: {name} }) => {
        this.props.onChange(value, { target: {name} });
        if(this.props.type === 'password') {
            if ((value.length && !this.state.showPasswordButton) || (!value.length && this.state.showPasswordButton)) {
                this.toggleShowPassword();
            }
        }
    };


    render() {
        const {type, className, toolTip, validateIcon, showValidateIcon} = this.props;

        const inputType = type !== 'password' ? type : this.state.showPassword ? 'text' : 'password';

        const passwordIcon = this.state.showPassword ? visibilityOff : visibilityOn;

        return (


            <div
                className={`validated-input-container ${className ? className : ''} ${toolTip ? 'with-tooltip' : ''}`}
            >
                <Input
                    type={inputType}
                    label={this.props.label}
                    name={this.props.name}
                    error={this.props.error}
                    value={this.props.value}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    theme={{
                        label: `description-label`,
                        error: `error-label`,
                        bar: 'bottom-bar',
                        errored: 'errored',
                        hint: 'hint',
                        input: 'validated-input-input'
                    }}
                    required={this.props.required}
                    placeholder={this.props.hint}
                >
                    {toolTip && <ToolTipWrapper label={'?'} tooltip={toolTip} />}
                    </Input>
                {
                    this.props.type === 'password' &&
                    <div
                        className={`show-password ${this.state.showPasswordButton ? 'show' : ''}`}
                        onClick={this.showPassword}
                    >
                        <img src={passwordIcon} alt="visibility"/>
                    </div>
                }
                {validateIcon && <div className={`validate-icon`} hidden={!showValidateIcon}>{validateIcon}</div> }
            </div>
        )
    }
}

ValidatedInput.propTypes = {
    type: PropTypes.oneOf(['text', 'number', 'email', 'phone', 'password']).isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    validateIcon: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    showValidateIcon: PropTypes.bool
};

ValidatedInput.defaultProps = {
    type: 'text',
    disabled: false,
    onBlur: () => {},
    showValidateIcon: false
};

export default ValidatedInput;
