import React, {Component} from 'react';
import InputValidator from '../../../common/input-validator';
import ValidatedForm from '../../validated-form';
import HelpiButton from '../../helpi-button';
import {HELPI_BUTTON_CLASSES} from "../../../common/constants";
import Wizard from '../../wizard';
import 'formdata-polyfill';

const FormFields = {
    firstName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם פרטי:',
        klass: 'auth-input'
    },
    lastName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם משפחה:',
        klass: 'auth-input'
    },
    phoneNumber: {
        validator: InputValidator.phone,
        type: 'phone',
        label: 'מספר טלפון נייד:',
        klass: 'auth-input'
    }

};

class SocialWorkerBasicDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valid: false
        }

    }

    onValidChange = (valid) => this.setState({ valid });

    handleFieldValueChange = () => {
        if(this.props.validError !== ''){
            this.props.resetValidError();
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if(this.props.validError) {
            this.props.resetValidError();
        }

        const formData = new FormData(e.target);

        this.props.onCompleteDetailsSubmit({
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            phoneNumber: formData.get('phoneNumber')
        }, true, true);

    };


    render() {

        const error = this.props.validError ? <div className="error-message">{this.props.validError}</div> : null;


        return (
            <form onSubmit={this.onSubmit} className="social-worker-form">
                <ValidatedForm
                    fields={FormFields}
                    handleFieldValueChange={this.handleFieldValueChange}
                    onValidChange={this.onValidChange}
                />
                <div className="under-text">{error}</div>
                <HelpiButton disabled={!this.state.valid}
                             type={'submit'}
                             label={'שלח'}
                             classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}
                />
            </form>
        )
    }
}

class SocialWorkerSignUp extends Component {
    render() {

        const stages = [
            {title: '', component: <SocialWorkerBasicDetails {...this.props} />},
            // {title: '', component: <Verification {...this.props} />}
        ];

        return (
            <div className={'auth-box with-dots'}>
                <Wizard stages={stages} activeStage={this.props.verificationStage} />
            </div>
        );
    }
}

export default SocialWorkerSignUp;