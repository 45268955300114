import React, {Component} from 'react';
import '../style.scss';
import Modal from '../../modal';
import HelpiButton from '../../../components/helpi-button';
import {HELPI_BUTTON_CLASSES} from "../../../common/constants"

class NewsletterUnsubscribeModal extends Component {

    generateContent = () => {
        return (
            <div className="unsubscribe-content">
                <div>לא נשלח לך יותר הצעות להתנדבות</div>
                <HelpiButton
                    label={'חזרה לאתר '}
                    classList={[HELPI_BUTTON_CLASSES.PRIMARY]}
                    onClick={this.handleClose}
                />
            </div>
        )
    };

    handleClose = () => this.props.handleClose();

    render() {
        const {show} = this.props;
        const form = this.generateContent();

        return (
            <div className="newsletter-modal-wrapper">
                <Modal
                    show={show}
                    title={'הוסרה כתובת המייל שלך'}
                    handleToggle={this.handleToggle}
                    render={(props) => <div {...props} className="child-modal">{form}</div>}
                    closeButton={false}
                    className={'newsletter-modal-wrapper'}
                    closeOnOverlayClick={false}
                />
            </div>
        )
    }
}

export default NewsletterUnsubscribeModal;