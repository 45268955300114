import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

class HeadingText extends PureComponent {
    render() {
        return (
            <div className={`heading-text-container ${this.props.className ? 'container' : ''}`}>
                <div className="heading">{this.props.label}</div>
            </div>
        )
    }
}

export default HeadingText;

HeadingText.propTypes = {
    label: PropTypes.string.isRequired
};