/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Cases reducer
 */
import * as actionTypes from './action-types';
import {ERROR_MESSAGE_TRANSLATE} from '../../../common/constants';

const initialState = {
    cases: [],
    count: 0,
    filters: {
        keyword: '',
        region: [],
        timeRange: [],
        taskTypes: [],
        isMultipleVolunteers: [],
        sort: '',
        pageNumber: 0
    },
    loading: false,
    moreCases: false,
    newsletterErrors: '',
    newsletterSuccess: false,
    subscriber: {},
    newsletterUnsubscribeSuccess: false,
    emailValidationSuccess: false,
    emailGenerationSuccess: false,
    emailValidationDetails: {
        cases: 0,
        valid: false,
        token: null
    },
    finishedHours: 0
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.TOGGLE_LOADING: {

            const { payload: {loading} } = action;

            return {
                ...state,
                loading
            };
        }
        case actionTypes.GET_CASES_SUCCESS: {
            const { payload: {cases, count} } = action;
            const { moreCases } = state;

            if(moreCases){
                const addCases = [...state.cases, ...cases];

                return {
                    ...state,
                    cases: addCases,
                    count
                };
            }

            return {
                ...state,
                cases,
                count
            };
        }
        case actionTypes.APPLY_FILTER: {
            const { payload: filters } = action;

            const moreCasesValue = filters.pageNumber > 0;
            return {
                ...state,
                filters,
                moreCases: moreCasesValue
            };
        }
        case actionTypes.RESET_FILTERS: {
            const filters = {...initialState.filters};

            return {
                ...state,
                filters
            };
        }
        case actionTypes.NEWSLETTER_SUCCESS: {
            return {
                ...state,
                newsletterSuccess: true
            }
        }
        case actionTypes.NEWSLETTER_ERROR: {
            const {payload} = action;
            const error = payload.errors ? ERROR_MESSAGE_TRANSLATE[payload.errors[0]['code']] : payload.message;

            return {
                ...state,
                newsletterErrors: error
            }
        }
        case actionTypes.CLEAR_CASES: {
            return {
                ...initialState
            }
        }
        case actionTypes.GET_SUBSCRIBER_SUCCESS: {
            const {payload} = action;

            return {
                ...state,
                subscriber: {...payload}
            }
        }
        case actionTypes.NEWSLETTER_UNSUBSCRIBE_SUCCESS: {
            return {
                ...state,
                newsletterUnsubscribeSuccess: true
            }
        }
        case actionTypes.EMAIL_VALIDATION_RESPONSE_SUCCESS: {
            const {payload} = action;

            const cases = payload.cases || 0;
            const valid = payload.valid || false;
            const token = !valid ? payload.token :  null;

            return {
                ...state,
                emailValidationSuccess: true,
                emailValidationDetails: {
                    cases,
                    valid,
                    token
                }

            }
        }
        case actionTypes.EMAIL_VALIDATION_GENERATE_SUCCESS: {
            return {
                ...state,
                emailGenerationSuccess: true
            }

        }
        case actionTypes.CLOSE_EMAIL_VALIDATION_GENERATE_MODAL: {
            return {
                ...state,
                emailGenerationSuccess: false
            }
        }
        case actionTypes.CASES_FINISHED_HOURS_SUCCESS: {
            const {payload} = action;

            return {
                ...state,
                finishedHours: payload
            }
        }
        default:
            return state;
    }
};
