import React from 'react';
import HeadingText from '../../components/heading-text';
import {Link} from 'react-router-dom';
import './style.scss';

const PrivacyPolicy = () =>{
    window.scrollTo(0,0);
    return (
        <div className="privacy-policy-wrapper container">
            <HeadingText
                label={'מדיניות פרטיות'}
            />
            <ol className="top-list">
                <li className="list">
                    <div className="title">כללי</div>
                    <ol className="inner-list">
                        <li>
                            <p>פלטפורמת Helpi (להלן: "הפלטפורמה" או "Helpi"), המופעלת על ידי עמותת בואונתערב (ע"ר) (להלן: "העמותה" או "מפעיל האתר" או "אנו"), מאפשרת לציבור הרחב וכן לעובדי חברות וארגונים להתנדב באופן קל ונגיש, ללא התחייבויות להתנדבות ארוכת טווח (להלן: "השירותים"). השירותים המסופקים על ידי Helpi נגישים באמצעות אתר האינטרנט בכתובת www.helpi.org.il (להלן: "האתר"). </p>
                        </li>
                        <li>
                            <p>במסגרת השירותים המסופקים ע"י Helpi, העמותה מקבלת מידע אישי המוזן באתר ע"י המשתמש שעושה שימוש באתר. כמו כן, הארגון אוסף מידע שאינו אישי אודות אופן השימוש של המנוי ו/או המשתמשים באתר. מדיניות פרטיות זו חלה על המידע (כהגדרתו להלן), ומטרתה להסביר את השימושים שהעמותה עושה, בעצמה או באמצעות צדדי ג' מטעמה, בכל סוגי המידע, כמפורט במדיניות זו להלן.</p>
                        </li>
                        <li>
                            <p>תנאי מדיניות פרטיות זו מהווים הסכם מחייב בין הארגון לבין המשתמש וחלק בלתי נפרד מתנאי השימוש באתר אותם ניתן למצוא בכתובת (<Link to="/terms-and-conditions">לתנאי השימוש</Link>). בעצם הרישום לשירותים והשימוש באתר, המשתמש מאשר כי הוא קרא, הבין ומסכים לקבלת את השירותים ותנאי מדיניות פרטיות זו. </p>
                        </li>
                        <li>
                            <p>במדיניות פרטיות זו יהיו למונחים שלהלן הפרשנות שלצידם אלא אם נקבע אחרת במסגרת תנאי השימוש.</p>
                            <ol className="inner-inner-list">
                                <li><p>"מידע אישי" - מידע המזהה או עשוי לזהות אדם באופן אישי ונושא לרוב אופי פרטי או רגיש (לדוגמא – כתובת דוא"ל, מספר סלולרי, ת"ז, תאריך לידה, מצב משפחתי, נתונים סוציאליים וכו'). מידע אישי כולל נתונים ו/או מסמכים ו/או מידע אשר נמסרו ו/או יוזנו על ידי משתמש באתר בקשר עם נשואי המידע.</p></li>
                                <li><p>מידע שאינו אישי" - מידע אנונימי, סטטיסטי ומצטבר שנאסף ע"י העמותה אודות אופן שימוש המשתמש באתר ו/או בשירותים המוצעים בו (למשל - סוג הדפדפן, התאריך והשעה בה נכנס משתמש לאתר, כתובת פרוטוקול האינטרנט (כתובת IP), המקומות בהם ביקר משתמש באתר וכו'). מידע זה אינו מזהה אדם או מאפשר זיהויו באופן אישי.</p></li>
                                <li><p>"מידע" – מידע אישי ומידע שאינו אישי, גם יחד.</p></li>
                                <li><p>"משתמש(ים)" – עובדים סוציאליים, נציגי עמותות וכל מי שמבקש להשתמש בשירותים כדי להתנדב או לסייע לאחר.</p></li>
                            </ol>
                        </li>
                        <li><p>בעצם שימושך בשירות שלנו הנך מצהיר כי אתה בעל הזכויות בכל מידע שימסר על ידך לעמותה ו/או כי קיבלת את כל ההסכמות הדרושות למסירת המידע על ידך וכי אתה מסכים לאיסוף, להעברה, לעיבוד, לאחסון, לחשיפה ולשימושים אחרים המתוארים במדיניות פרטיות זו.</p></li>
                    </ol>
                </li>
                <li className="list">
                    <div className="title">מסירת מידע באמצעות האתר – הצהרות והתחייבויות מוסר המידע</div>
                    <ol className="inner-list">
                        <li>
                            <p>בעצם מסירת הפרטים והשימוש בפלטפורמה, המשתמש מצהיר, נותן הסכמתו, מתחייב ומאשר כדלקמן:</p>
                            <ol className="inner-inner-list">
                                <li><p>כי הפרטים שמסר נכונים, מדויקים, ושייכים לו. ככל שהנתונים שמוסר המשתמש אינם שייכים לו, המשתמש מצהיר כי קיבל מנושא המידע את כל ההסכמות הדרושות להעברת נתוני נושא המידע לעמותה, לשימוש שייעשה בהם ע"י העמותה ו/או מי מטעמו במסגרת השירותים וכן כי הוא מיופה כוח מטעם נושא המידע להתחייב ולהצהיר בשם נושא המידע בכל האמור להלן;</p></li>
                                <li><p>כי לא מוטלת עליו כל חובה חוקית למסור את פרטי המשתמש או מידע אישי אחר, ומסירת פרטי המשתמש והמידע האישי נעשית מרצונו החופשי, ואולם המשתמש מודע לכך שללא מסירת פרטי המשתמש מפעיל האתר לא יוכל להעניק למשתמש את השירות המבוקש.</p></li>
                                <li><p>כי הוא מסכים להעברת מידע אישי שמסר באתר לצדדים שלישיים, בין היתר לצורך אספקת השירות המבוקש, לרבות לארגון בו בחרת להתנדב, לביטוח לאומי וכיוצ"ב.</p></li>
                                <li><p>ככל שהמשתמש החל בפעילות ההתנדבות במסגרת ההתקשרות של מקום עבודתו עם העמותה, הוא מסכים להעברת פרטים אודות פעילות ההתנדבות שלו למקום העבודה שלו.</p></li>
                                <li><p>מבלי לגרוע מהאמור, העמותה תהא רשאית להעביר מידע שמסרת באתר ככל שיידרש עפ"י דין (לרבות לפי צו בית משפט, ו/או במסגרת הליכים משפטיים במידה ויתעוררו בין המשתמש לעמותה ו/או מי מטעמה)</p></li>
                                <li><p>למען הסר ספק, ומבלי לגרוע מהוראות תנאי השימוש, העמותה אינה אחראית לכל שימוש שיעשה במידע האישי שמסר המשתמש באתר, כולו או חלקו, ע"י צדדים שלישיים כלשהם והמשתמש פוטר את העמותה וכל מי מטעמה מכל טענה ו/או תביעה ו/או דרישה (כספית או אחרת) כלפי העמותה וכל מי מטעמה בשל כך.</p></li>
                                <li>
                                    <p>כי הוא מעניק לעמותה רישיון שימוש לא בלעדי, בלתי מוגבל בלתי חוזר, ללא תמורה, לא כפוף לתשלום תמלוגים וללא הגבלת זמן לעשות שימוש במידע האישי, והעמותה תהא רשאית לעשות בו שימוש בהתאם למטרות דלעיל, ולא תהיה לך כל תביעה ו/או טענה ו/או דרישה (כספית או אחרת) בשל כך.</p>
                                </li>
                                <li>
                                    <p>	בכפוף למסירת פרטי ההתקשרות באתר המשתמש נותן הסכמתו לקבל דיוור ישיר, העמותה תהא רשאית לשלוח למשתמש מעת לעת, בדואר אלקטרוני ו/או באמצעי התקשרות אחרים, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי של Helpi ו/או של צדדים שלישיים. המשתמש יהיה רשאי לבטל בכל עת את הסכמתו ולחדול מקבלת המידע באמצעות פנייה בכתב בדוא"ל
                                         <a href="mailto:info@helpi.org.il">info@helpi.org.il</a></p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>מבלי לגרוע מהאמור לעיל ובכפוף למסירת פרטי ההתקשרות ל- Helpiולהסכמתך לקבל דיוור ישיר, תהא Helpi רשאית לשלוח לך מעת לעת, בדואר אלקטרוני ו/או באמצעי התקשרות אחרים, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי של Helpi ו/או של צדדים שלישיים. המשתמש יהיה רשאי לבטל בכל עת את הסכמתו ולחדול מקבלת המידע.</p>
                        </li>
                        <li>
                            <p>בנוסף לאמור, אנו עשויים לאסוף נתונים מצרפיים אודות המשתמש לרבות במהלך השימוש באתר. 'נתונים מצרפיים' הינם מידע שאנו אוספים על קבוצה או על קטגוריה של שירותים או משתמשים, שממנו הוסרו זהויות פרטניות של משתמשים. מדיניות זו אינה מגבילה בשום אופן את האיסוף ואת השימוש שלנו בנתונים מצרפיים, וייתכן כי נשתף נתונים מצרפיים על המשתמשים שלנו עם צדדים שלישיים למטרות שונות, הכוללות סיוע בהבנה טובה יותר של צורכי הלקוחות שלנו, שיפור השירות שלנו, פרסום ושיווק.</p>
                        </li>
                        <li>
                            <p>אנו עשויים לאסוף מידע זה על מנת להעניק את השירותים, לשפר, להעשיר או לשנות את האתר, להתאים אישית את האתר ו/או מסרים שיווקיים להעדפות או לתחומי העניין של המשתמש, וכן על מנת לשלוח למשתמש עדכונים שונים על ידי Helpi או מי מטעמה.</p>
                        </li>
                        <li>
                            <p>אנו עשויים להשתמש ב- Cookiesלצרכי תפעולו השוטף והתקין של האתר, מעקב אחר השימוש באתר ובכלל זה נתונים סטטיסטיים אודות השימוש באתר והגדרת אזורים מועילים או פופולריים לעומת כאלה שאינם, לאימות פרטים, על מנת להתאים למשתמש את האתר ו/או מסרים שיווקיים מצדדים שלישיים העשויים להופיע בו, ולצרכי אבטחת מידע. העמותה נוקטת אמצעי זהירות כדי להבטיח שרק שרתי העמותה יוכלו לקרוא ולהבין את המידע האגור בהם והשימוש בו על ידי העמותה יתבצע בהתאם לתנאי מדיניות פרטיות זו. יובהר כי ככלל, מידע זה אינו מאפשר לזהות את המשתמש באופן אישי, אלא אם כן בקורלציה עם פרטים מזהים אחרים.</p>
                        </li>
                        <li>
                            <p>רוב דפדפני האינטרנט מתריעים על השימוש ב- Cookies ומאפשרים למשתמש לבחור לסרב לחלוטין לשימוש ב- Cookies. אם אינך רוצה לקבל Cookies, תוכל להימנע מכך על ידי שינוי ההגדרות בדפדפן שלך בהתאם להוראות הדפדפן. לידיעתך כי נטרול Cookies עלול לגרום לכך שחלקים מסויימים של האתר עשויים לתפקד באופן חלקי או לא לאפשר לך גישה אליהם כלל, כך שלא תוכל להשתמש בחלק מהשירותים המוצעים באתר.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <div className="title">שימוש בעוגיות (cookies)</div>
                    <ol className="inner-list">
                        <li>
                            <p>אנו עשויים להשתמש ב- Cookiesלצרכי תפעולו השוטף והתקין של האתר, מעקב אחר השימוש באתר ובכלל זה נתונים סטטיסטיים אודות השימוש באתר והגדרת אזורים מועילים או פופולריים לעומת כאלה שאינם, לאימות פרטים, על מנת להתאים למשתמש את האתר ו/או מסרים שיווקיים מצדדים שלישיים העשויים להופיע בו, ולצרכי אבטחת מידע. העמותה נוקטת אמצעי זהירות כדי להבטיח שרק שרתי העמותה יוכלו לקרוא ולהבין את המידע האגור בהם והשימוש בו על ידי העמותה יתבצע בהתאם לתנאי מדיניות פרטיות זו. יובהר כי ככלל, מידע זה אינו מאפשר לזהות את המשתמש באופן אישי, אלא אם כן בקורלציה עם פרטים מזהים אחרים.</p>
                        </li>
                        <li><p>רוב דפדפני האינטרנט מתריעים על השימוש ב- Cookies ומאפשרים למשתמש לבחור לסרב לחלוטין לשימוש ב- Cookies. אם אינך רוצה לקבל Cookies, תוכל להימנע מכך על ידי שינוי ההגדרות בדפדפן שלך בהתאם להוראות הדפדפן. לידיעתך כי נטרול Cookies עלול לגרום לכך שחלקים מסויימים של האתר עשויים לתפקד באופן חלקי או לא לאפשר לך גישה אליהם כלל, כך שלא תוכל להשתמש בחלק מהשירותים המוצעים באתר.</p></li>
                    </ol>
                </li>
                <li>
                    <div className="title">ספקי שירותים.</div>
                    <p>ייתכן שנשתמש בצדדים שלישיים מהימנים מסוימים, שיסייעו לנו לספק, לנתח ולשפר את השירות. ייתכן לצדדים שלישיים כאמור עשויה להיות גישה למידע שלך רק לצורך ביצוע משימות כאמור מטעמנו, ובכפוף למגבלות הדומות למגבלות שצוינו במדיניות זו.</p>
                </li>
                <li>
                    <div className="title">ציות לחוק ולבקשות לאכיפת החוק.</div>
                    <p>ייתכן שנמסור מידע אישי תוך אמונה בתום-לב שמסירת מידע כאמור הולמת והכרחית כדי לציית להליך משפטי או לסמכות חוקית (לדוגמה, הזמנה חוקית לדין, צו או חיפוש צו בית משפט); כדי לאכוף או להחיל את המדיניות שלנו; כדי ליזום, לקבל, לחייב ולגבות סכומים שחייבים לנו; כדי להגן על הזכויות או על הרכוש שלנו, או על הבטיחות האישית של Helpi, של משתמשיה, של עובדיה או של הציבור; או אם אנו מאמינים בתום-לב כי מקרה חירום, הכרוך בסכנה מיידית למוות או לפגיעה גופנית חמורה באדם כלשהו, מחייב חשיפת תקשורות או מצדיק חשיפת רשומות ללא דיחוי.</p>
                </li>
                <li>
                    <div className="title">יצירת קשר.</div>
                    <p>המשתמש רשאי לפנות אלינו בבקשה לעיין בפרטי המשתמש שמסר וכן לבקש לתקן או למחוק את המידע כאמור המצוי במערכות העמותה באמצעות  <Link to="/contact">טופס צור קשר</Link> באתר או באמצעות פנייה לעמותה דרך כתובת הדוא"ל <a href="mailto:info@helpi.org.il">info@helpi.org.il</a>. אנו נפעל להסרת המידע המידע שלך בהתאם לחובות המוטלות עלינו על פי דין, אולם ייתכן שנצטרך לשמור עותקים של המידע שלך  לצורך למילוי אחר החובות שלנו ולמשך הזמן הנדרש עפ"י דין. </p>
                </li>
                <li>
                    <div className="title">המידע שאנו אוספים והשימוש בו</div>
                    <ol className="inner-list">
                        <li><p>אנו עשויים לבקש ממשתמש לספק מידע אישי לצרכי התקשרות ועל מנת שנוכל לספק עבורו את השירותים המוצעים דרך האתר, בין אם המשתמש הינו מתנדב המעוניין להעניק שירות או תרומה ובין אם המשתמש הינו אדם הזקוק לקבלת שירות. בנוסף לאמור, אנו עשויים לאסוף מידע לא אישי כהגדרתו לעיל.</p></li>
                        <li>
                            <p>העמותה תעשה שימוש במידע הנאסף על ידה, בעצמה או באמצעות צדדי ג' מטעמה, לצורך ביצוע השירותים, ובכלל זה למטרות הבאות (כפי שישתנו מעת לעת):</p>
                            <ol className="inner-inner-list">
                                <li><p>מידע לא אישי שייאסף ישמש את העמותה ו/או מי מטעמה, בין היתר, לבחינת מספר המבקרים באתר, פילוח וניתוח הרגלי שימוש באתר, עדכון וטיוב נתונים, תיקון תקלות וכל מטרה אחרת לשם שיפור פעילות האתר והשירות המוצע בו. מאחר ומדובר במידע שאינו אישי ולא ניתן לזיהוי המשתמש, העמותה שומרת לעצמה את הזכות לעשות במידע כל שימוש ולהעבירו לצדדי ג' ללא כל הגבלה, ולא תהיה למשתמש כל תביעה ו/או טענה ו/או דרישה (כספית או אחרת) כלפי העמותה וכל מי מטעמה בשל כך.</p></li>
                                <li><p>על מנת להעניק את השירותים, לשפר, להעשיר או לשנות את האתר, להתאים אישית את האתר ו/או מסרים שיווקיים להעדפות או לתחומי העניין של המשתמש, וכן על מנת לשלוח למשתמש עדכונים שונים על ידי העמותה או מי מטעמה ו/או מטעם צדדים שלישיים.</p></li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <div className="title">העברות עסקיות.</div>
                    <p>מידע על המשתמשים ועל השירות שלנו מהווים אחד הנכסים העסקיים החשובים ביותר שלנו. לפיכך, מידע על המשתמשים שלנו, לרבות מידע אישי, ייחשף כחלק מכל מיזוג, רכישה או מכירה של כל נכסינו או של חלקם. בנוסף, נוכל למסור מידע אישי במקרים כדלקמן.:</p>
                    <ol className="inner-list">
                        <li>על פי דרישת המשתמש או בהסכמתו המפורשת.</li>
                        <li>בכל מקרה בו הפר המשתמש את תנאי השימוש, לרבות תנאי מדיניות פרטיות זו.</li>
                        <li>בכל מקרה בו ביצע או ניסה לבצע המשתמש ו/או מי מטעמו, באמצעות האתר ו/או בקשר איתו, פעולות המנוגדות להוראות תנאי השימוש, למדיניות פרטיות זו או להוראות כל דין.</li>
                        <li>בשל כל מחלוקת, טענה, תביעה, דרישה או הליכים משפטיים אשר יתנהלו בין המשתמש ו/או מי מטעמו לבין העמותה ו/או מי מטעמה.</li>
                        <li>בכל מקרה בו תסבור העמותה כי מסירת המידע נחוצה על מנת למנוע נזק חמור לרכושם ו/או לגופם של המשתמש ו/או של משתמשים אחרים ו/או של צדדים שלישיים.</li>
                        <li><p>במקרה בו העבירה ו/או המחתה העמותה את פעילותה ו/או את זכויותיה וחובותיה כלפי המשתמש לצד שלישי/ צדדים שלישיים.</p></li>
                    </ol>
                    <p>יודגש כי אין באמור לעיל בכדי לגרוע מזכותה של העמותה להעביר מידע אשר אין בו בכדי לזהות את המשתמש בשמו ו/או בפרטי זהותו באופן ישיר, בכפוף למדיניות פרטיות זו או להוראות כל דין.</p>
                </li>
                <li>
                    <div className="title">תכנים של צדדים שלישיים.</div>
                    <p>באתר עשויים להופיע תכנים של צד שלישי לרבות פרסומים, מודעות, באנרים וקישורים לאתרים חיצוניים. כל שימוש אותו מבצע המשתמש בתוכן של צד שלישי יהיה כפוף לתנאי השימוש ו/או למדיניות הפרטיות של אותם צדדים שלישיים והעמותה אינה לוקחת אחריות בקשר לתכנים אלה. בנוסף, כל שימוש בתוכן של צד שלישי, לרבות כל כניסה של משתמש לאתרים של צדדים שלישיים על ידי הפניה ו/או בפרסומת באתר, יתבצע על אחריותו הבלעדית של המשתמש ולמשתמש לא תהיה כל טענה ו/או תביעה כנגד העמותה בקשר לכך, לרבות אך מבלי לגרוע, בגין כל נזק, ישיר או עקיף, הנובע משימוש ו/או כניסה כאמור ו/או בשל פגיעה בפרטיות ו/או כל איסוף מידע ו/או שימוש בו על ידי צדדים שלישים.אנו עשויים להציע בקישורים המופיעים באתר קישורים לאתרים אחרים. יודגש כי אתרים אלו אינם נשלטים על ידי מדיניות פרטיות זו והעמותה -אינה מתחייבת לגבי האופן בו אתרים אלו אוספים או עושים שימוש במידע המופק, לרבות במידע האישי שלך. אנו ממליצים לבדוק את מדיניות הפרטיות של אתרים חיצוניים המקושרים לאתר לפני כל שימוש.</p>
                </li>
                <li>
                    <div className="title">אבטחת מידע.</div>
                    <p>העמותה מטמיעה ומיישמת מערכות ונהלים לאבטחת מידע, תוך נקיטת אמצעי זהירות ושימוש בטכנולוגיות אבטחה מקובלות, כדי להגן על סודיות הנתונים שנמסרו לה על ידי משתמשי האתר. עם זאת, העמותה מבהירה, כי איננה מתחייבת, שהאתר יתנהל ללא כל הפרעה, ו/או שהאתר ו/או הנתונים שנאספו ו/או נמסרו כאמור לעיל יהיו חסינים באופן מוחלט מפני גישה ו/או חדירה בלתי מורשית. המשתמש נוטל על עצמו את הסיכון המלא והמוחלט בשימוש באתר והשימוש באתר מהווה את הסכמת המשתמש כי העמותה לא תישא באחריות בגין כל נזק ו/או אבדן, ישירים או עקיפים, שנגרמו למשתמש או למי מטעמו כתוצאה מהפרעות ו/או חדירות בלתי מורשות לאתר, לרבות בשל פגיעה בפרטיות</p>
                </li>
                <li>
                    <div className="title">שינויים למדיניות הפרטיות. </div>
                    <p>העמותה רשאית לערוך שינויים או עדכונים למדיניות פרטיות זאת מעת לעת. אם נבצע שינויים כלשהם במדיניות זו, נשנה את התאריך "עודכן לאחרונה" להלן. אנו ממליצים לך לבדוק מדיניות זו בכל פעם שאתה משתמש בשירות שלנו כדי להבין כיצד משתמשים במידע האישי שלך. ככל והשינויים למדיניות יפחיתו מעוצמת ההגנה על מידע שנאסף דרך האתר ו/או בקשר עם השירותים, שינויים אלו לא יחולו רטרואקטיבית בקשר למידע שכבר נאסף.</p>
                </li>
            </ol>
            <div className="update-text">עודכן לאחרונה: אפריל 2021</div>
        </div>
    )}
;

export default PrivacyPolicy;