import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthConsumer from "./../auth-protected";
import * as profileActions from "./actions";
import * as profileSelectors from "./selectors";
import * as homeSelectors from '../../homepage/selectors';
import * as profileActionTypes from "./action-types";
import * as signUpActions from "../sign-up/actions";
import * as passwordActions from './password/actions';
import * as homeActions from '../../homepage/actions';

export default (ProfileGUI) => {
    const mapStateToProps = (state) => ({
        validError: profileSelectors.selectValidError(state),
        showSmsModal: profileSelectors.selectShowSmsModal(state),
        userTaskCompleted:profileSelectors.userTaskCompleted(state),
        userOnGoingTasks:profileSelectors.userOnGoingTasks(state),
        showEmailModal: homeSelectors.selectEmailGenerationSuccess(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        resetValidError: () => dispatch({type: profileActionTypes.RESET_VALID_ERROR}),
        toggleShowSmsModal: () => dispatch({type: profileActionTypes.TOGGLE_SMS_MODAL}),
        updateUser: (payload, sendVerification) => dispatch(profileActions.updateUserData(payload, sendVerification)),
        checkSMSCode: (payload) => dispatch(profileActions.checkSMSCode(payload)),
        resendSMSCode: () => dispatch(signUpActions.resendSMSCode()),
        updatePassword: ({oldPass, newPass, user, token}) => dispatch(passwordActions.updatePassword({oldPass, newPass, user, token})),
        generateEmailToken: (userId) => dispatch(homeActions.generateEmailToken(userId)),

        getAllUserTasks: (type) => dispatch(profileActions.getAllUserTasks(type)),
        getAllUserCompletedTasks: (user) => dispatch(profileActions.getAllUserTasks('completed')),
        getAllUserFutureTasks: (user) => dispatch(profileActions.getAllUserTasks('future')),
    });

    class ProfileWrapper extends Component {

        componentDidUpdate() {
            if (!this.props.user) {
                this.props.goToLogin(null, '/profile');
            }
        }

        render() {
            return (
                <ProfileGUI
                    user={this.props.user}
                    onSubmit={this.props.updateUser}
                    validError={this.props.validError}
                    resetValidError={this.props.resetValidError}
                    showSmsModal={this.props.showSmsModal}
                    toggleShowSmsModal={this.props.toggleShowSmsModal}
                    checkSMSCode={this.props.checkSMSCode}
                    resendSMSCode={this.props.resendSMSCode}
                    updatePassword={this.props.updatePassword}
                    generateEmailToken={this.props.generateEmailToken}
                    showEmailModal={this.props.showEmailModal}
                    userTaskCompleted={this.props.userTaskCompleted}
                    userOnGoingTasks={this.props.userOnGoingTasks}
                    getAllUserTasks={this.props.getAllUserTasks}
                    getAllUserFutureTasks={this.props.getAllUserFutureTasks}
                    getAllUserCompletedTasks={this.props.getAllUserCompletedTasks}
                />
            );
        }
    }

    return AuthConsumer(connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper));
};
