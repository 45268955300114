/**
 * Created by mor on 06/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Higher order Reducer for authenticated protected reducers
 */
import {USER_LOGOUT} from "../action-types";
import {initialStateAuth} from "../reducer";

const AuthRelatedReducer = (reducer) => {
    return (state, action) => {
        if (action.type === USER_LOGOUT) {
            state = {
                ...initialStateAuth,
                authTestDone: true,
            };
        }
        return reducer(state, action);
    }
};

export default AuthRelatedReducer;
