/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {Link, withRouter} from 'react-router-dom';
import Logo from '../../assets/img/new-logo-2022.png';
import HeaderMenu from "./header-menu";
import HeaderMobile from "./header-mobile";
import WhitelabelLogo from './whitelabel-logo';
import {HEADER_MENU_ITEMS, IS_SUBDOMAIN, IS_DESKTOP} from "../../common/constants"

class Header extends React.Component {

    getClass() {
        const {pathname} = this.props.location;
        const {specialHeaderClass} = this.props;
        let klass = "";

        if (pathname === '/') {
            klass += ' home-route'
        } else if (pathname === '/signin') {
            klass += ' login-route'
        }

        if (specialHeaderClass) {
            klass += ` ${specialHeaderClass}`;
        }

        if (IS_SUBDOMAIN) {
            klass += ' whitelabel';
        }

        return klass;
    }

    getCases = () => this.props.getCases();

    render() {

        const isLogin = this.props.location.pathname === '/signin';

        const isStrauss = this.props.whiteLabel && this.props.whiteLabel.name === "קבוצת שטראוס";

        const logo = this.props.whiteLabel && this.props.whiteLabel.logoUrl ? this.props.whiteLabel.logoUrl : Logo;

        const whiteLabel = this.props.whiteLabel.logoUrl && (IS_DESKTOP || this.props.location.pathname === '/signin') ?
            <WhitelabelLogo isStrauss={isStrauss} logoUrl={this.props.whiteLabel.logoUrl} whiteLabel={this.props.whiteLabel}/>
            : null;

        return (
            <header className={'app-header'}>
                <div className={`header-container ${this.getClass()} `}>
                    <Link className={`logo ${this.props.whiteLogo ? 'white' : ''}`} to={this.props.homePath} onClick={this.getCases}>
                        {!isLogin && <img src={logo} alt=""/>}
                    </Link>
                    {isLogin && whiteLabel}
                    <HeaderMenu path={this.props.location.pathname} items={HEADER_MENU_ITEMS} whiteLabel={this.props.whiteLabel}/>
                    <HeaderMobile homePath={this.props.homePath} path={this.props.location.pathname} items={HEADER_MENU_ITEMS} whiteLabel={this.props.whiteLabel} logoClick={this.getCases}/>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    whiteLogo: PropTypes.bool,
    homePath: PropTypes.string.isRequired
};

export default withRouter(Header);
