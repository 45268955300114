/**
 * Created by mor on 03/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Case service
 */
import Network from '../../../utils/network';

class CaseService {

    network = Network;

    /**
     * get a single case
     * @param id
     * @returns {Promise<void>}
     */
    async getCase(id) {
        return await this.network.get(`api/cases/${id}`);
    }

    async getCaseBySerial(serial) {
        return  await this.network.get(`api/case_by_serial_number/${serial}`);
    }

    /**
     * update a single task
     * @param caseId
     * @param taskId
     * @param data
     * @returns {Promise<any | void>}
     */
    async updateTask(caseId, taskId, data) {
        return await this.network.put(`api/cases/${caseId}/tasks/${taskId}`, data);
    }

    /**
     * get a case`s list of group volunteers
     * @param caseId
     * @returns {Promise<any | void>}
     */
    async getCaseGroupVolunteers(id) {
        return await this.network.get(`api/cases/${id}/group_volunteers`);
    }

    /**
     * delete a single volunteer in a case list of group volunteers
     * @param caseId
     * @param volunteerId
     * @returns {Promise<any | void>}
     */
    async deleteVolunteer(caseId, volunteerId) {
        return await this.network.delete(`api/cases/${caseId}/group_volunteers/${volunteerId}`);
    }

    async getCasesByVoluteer(volunteer_id) {
        return await this.network.get(`api/cases_by_volunteer/${volunteer_id}`)
    }
}

export default new CaseService();
