import React from 'react';
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import AuthConsumer from './../../../core/containers/auth/auth-protected';
import LoginProfileLink from '../login-profile';
import {IS_SUBDOMAIN} from "../../../common/constants";

class HeaderMenu extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            hiddenSubMenu: true
        };

    }

    /**
     * generate link according to item metadata
     * @param item
     * @returns {*}
     */
    generateLink = (item) => {
        const link = item.genLink ? item.genLink.apply(null, [this.props.user]) : item.link;

        return item.isExternal ?
            <a href={link} target={"_blank"}>{item.label}</a>
            :
            <NavLink activeClassName="active" to={link} target={item.target}>{item.label}</NavLink>
    };

    /**
     * return the item class active if one of his sub items is active
     * @param item
     * @returns string
     */
    getItemClass = (item) => {
        const {pathname} = this.props.location;
        const {subItems} = item;

        if (!subItems) return undefined;

        return subItems.findIndex(({link}) => link === pathname) !== -1 ? 'active' : undefined;
    };

    showSubItems = () => {
        this.setState({
            ...this.state,
            hiddenSubMenu: false
        })
    }

    hideSubItems = () => {
        this.setState({
            ...this.state,
            hiddenSubMenu: true
        })

    }

    /**
     * generates item content
     * @param item
     * @returns {*}
     */
    generateItemContent = (item) => (
        <div className={`nav-bar-main-link ${item.classes}`}
             onMouseEnter={item.subItems ? this.showSubItems : null}
             onTouchStart={item.subItems ? this.showSubItems : null}
             onMouseLeave={item.subItems ? this.hideSubItems : null}
             onClick={!item.subItems ? this.hideSubItems : null}
        >
            {(!item.link) ? <label className={this.getItemClass.apply(null, [item])}>{item.label}</label> : this.generateLink(item)}
            {
                item.subItems && (
                    <ul className={`sub-menu ${this.state.hiddenSubMenu ? 'hidden' : ''}`}>
                        {item.subItems.map((subItem, index) => <li
                            onClick={this.hideSubItems}
                            key={`${subItem.link}-${index}`}
                            className={"sub-menu-item"}
                        >
                            {this.generateLink(subItem)}</li>)}
                    </ul>
                )
            }
        </div>
    );

    /**
     * open authentication modal
     */
    onLoginClick = () => {

    };

    render() {
        return (
            <div className={`header-content ${IS_SUBDOMAIN ? 'whitelabel' : ''}`}>
                <ul className="nav-bar">
                    {
                        this.props.items.filter((link) => !link.mobile && (!IS_SUBDOMAIN || !link.notInWhitelabel) && (!link.hide || link.hide.apply(null, [this.props.user, this.props.whiteLabel]))).map((item, index) => {
                            return (
                                <li key={`${item.link}-${index}`} className={`nav-bar-section ${item.classes ? item.classes : ''}`}>
                                    {this.generateItemContent(item)}
                                </li>
                            )
                        })

                    }
                </ul>
                <LoginProfileLink
                    className="nav-bar"
                    path={this.props.path}
                    user={this.props.user}
                    authenticated={this.props.authenticated}
                    logOut={this.props.logOut}
                />
            </div>
        );
    }
}

HeaderMenu.propTypes = {
    items: PropTypes.array.isRequired,
    authenticated: PropTypes.bool
};

export default withRouter(AuthConsumer(HeaderMenu));
