class SuccessMapper {
    mapToVm = (payload) => (payload.map((success) => ({
            imgUrl: success.img_url,
            text: success.text
        }))
    );

    mapToDto = (payload) => ({
        whitelabel_id: payload.whiteLabelId,
        whitelabel_type: payload.whiteLabelType,
        number: payload.number
    });
}

export default new SuccessMapper();