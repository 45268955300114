import React from 'react';
import PropTypes from 'prop-types';
import {SimpleField, SimpleTitle} from "./shapes";
import InputWrapper from "../input-wrapper";

class SimpleForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: this.buildSchema(),
        };
    }


    /**
     * validate input and update state
     * @param value
     * @param name
     */
    handleFieldChange = (value, { target: {name} }) => {
        const error = this.props.fields[name].validator(value);

        const nextState = {
            ...this.state.data,
            [name]: {
                error,
                value
            }
        };

        this.setState({
            data: nextState,
        });

        if(this.props.handleFieldValueChange){
            this.props.handleFieldValueChange(name, value)
        }
    };

    /**
     * builds state scheme
     * @returns {{}}
     */
    buildSchema = () => Object.entries(this.props.fields).reduce((acc, entry) => {
        const name = entry[0], meta = entry[1];
        acc[name] = {
            value: meta.defaultValue || ''
        };

        return acc;
    }, {});

    /**
     * render the fields
     * @returns {any[]}
     */
    renderFields = () => Object.entries(this.props.fields).map(entry => {
        const name = entry[0], meta = entry[1];

        return (

            meta.type === 'title' ?
                <h2 key={name}>{meta.title}</h2> :
                <InputWrapper type={meta.type}
                                label={meta.label}
                                required={meta.required}
                                disabled={meta.disabled}
                                className={meta.klass}
                                name={name}
                                key={name}
                                value={this.state.data[name].value}
                                onChange={this.handleFieldChange}
                />
        );
    });

    render() {
        return this.renderFields();
    }

}

SimpleForm.propTypes = {
    fields: PropTypes.oneOfType([
        PropTypes.objectOf(SimpleField),
        PropTypes.objectOf(SimpleTitle)]
    ),
    handleFieldValueChange: PropTypes.func
};

SimpleForm.defaultProps = {
    fields: {}
};

export default SimpleForm;
