// import {Network} from '@decor-dev/wrappers/dist';
import RestService from '../../../common/rest-service';
import {mapToDto} from "./mapper";
import {showSuccessToast} from "../common/toast/actions";
import history from './../../../utils/history';
import {selectWhiteLabelState} from "../root-reducer/selectors";
import GAService from 'react-ga';
import {EVENTS} from "../../../common/constants"


/**
 * fetch cases from server
 * @returns {Function}
 */
export const sendCaseRequest = (payload, userId, taskType) => async (dispatch, getState) => {

    const whiteLabel = selectWhiteLabelState(getState())

    const newPayload =  {
        ...payload,
        userId: userId,
        taskType: taskType
    };

    if(whiteLabel && whiteLabel.type === 'organization') {
        newPayload.whiteLabel = whiteLabel.id
    }

    try {
        const response = await RestService.post('api/cases', mapToDto(newPayload, whiteLabel && whiteLabel.emergency));

        if(response){
            GAService.event({category: EVENTS.HELP_REQUEST.DISPLAY_NAME, action: EVENTS.HELP_REQUEST.ACTIONS.CASE_TYPE, label: taskType.toLowerCase()});
            showSuccessToast("המידע נשמר בהצלחה.");
            history.navigate('/');
        }

    } catch (e) {
        console.log(e)
    }
};
