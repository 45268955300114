import {DATE_RADIO_BUTTONS_TYPES} from "../../../features/request/constants";

/**
 * map UI data to server data
 * @param payload
 * @returns {{}}
 */
export const mapToDto = (payload, emergency) => {

    if(emergency) {
        return({
            description: payload.description,
            tasks: [{
                ...payload.tasks,
                due_date: payload.dueDate.unix(),
                real_due_date: false
            }],
            petitioner_id: payload.petitionerId,
            title: payload.title,
            allowed_user_state: payload.allowedUserState,
            whitelabel: payload.whiteLabel ? {
                id: payload.whiteLabel,
                type: 'organization'
            } : undefined
        })
    }

    const behalfOtherPersonEmail = !!payload.behalfOtherPersonData.value.email ? {
        email : payload.behalfOtherPersonData.value.email
    }
    : {};

    const behalfOtherPersonPhone = !!payload.behalfOtherPersonData.value.phoneNumber ? {
        phone_number:
            {
                number: payload.behalfOtherPersonData.value.phoneNumber,
                country_code: "IL"
            }
    }
    : {} ;

    const behalfOtherPersonData = payload.behalfOtherPersonData.active ?
        {
            behalf_other_person: {
                first_name: payload.behalfOtherPersonData.value.firstName,
                last_name: payload.behalfOtherPersonData.value.lastName,
                ...behalfOtherPersonEmail,
                ...behalfOtherPersonPhone
            }
        }: {};

    const location = (payload.location.active) ? {
            location: {
                address: {
                    city: payload.location.value.address.city,
                    country: payload.location.value.address.country,
                    street_name: payload.location.value.address.streetName,
                    street_number: payload.location.value.address.streetNumber
                }
            }
        }
        : {};


    const destination = (payload.destination.active) ? {
            destination: {
                    address: {
                        city: payload.destination.value.address.city,
                        country: payload.destination.value.address.country,
                        street_name: payload.destination.value.address.streetName,
                        street_number: payload.destination.value.address.streetNumber
                    }
            }
        }
        : {};

    const maximumVolunteers = payload.multipleVolunteersData.value ? {
        maximum_volunteers: parseInt(payload.multipleVolunteersData.value, 10),

    } : {};

    const timeRange = payload.timeRange.value && (payload.dateType.value === DATE_RADIO_BUTTONS_TYPES.FLEX) ? {
        time_range: payload.timeRange.value
    } : {};

    if (payload.endRangeOfDates && (payload.dateType.value === DATE_RADIO_BUTTONS_TYPES.RANGE_OF_DATES)) {
        payload.date.value = payload.endRangeOfDates.value
    } 
    const is_real_due_date = payload.dateType.value === DATE_RADIO_BUTTONS_TYPES.EXACT || payload.dateType.value ==DATE_RADIO_BUTTONS_TYPES.RANGE_OF_DATES ? true :false

    return({
        ...behalfOtherPersonData,
        description: payload.description.value,

        //currently no extra volunteers
        is_multiple_volunteers: payload.multipleVolunteersData.active,
        ...maximumVolunteers,
        petitioner_id: payload.userId,
        send_behalf: payload.behalfOtherPersonData.active,
        allowed_user_state: payload.userType.value,
        tasks: [
            {
                description: payload.description.value,
                start_date : payload.dateType.value === DATE_RADIO_BUTTONS_TYPES.RANGE_OF_DATES ? payload.startRangeOfDates.value.unix() : null,
                due_date: payload.date.value.unix(),
                real_due_date: is_real_due_date,
                title: payload.description.value,
                type: payload.taskType,
                ...timeRange,
                ...destination,
                ...location
            }
        ],
        title: payload.description.value,
        whitelabel: payload.whiteLabel ? {
            id: payload.whiteLabel,
            type: 'organization'
        } : undefined

    })

};