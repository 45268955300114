import UserService from "../service";
import Mapper from '../mapper';
import {showSuccessToast, showErrorToast} from '../../common/toast/actions';
import Network from '../../../../utils/network';
import FBService from '../../../../common/facebook-service';
import RestService from '../../../../common/rest-service';

import * as actionTypes from './action-types';
import * as authActions from '../action-types';
import {login, handlePendingTask} from "../actions";
import {selectLoggedInUserId, selectSignUpStage, selectEmailDomainAuthorizedType, selectPendingTask} from "../selectors";
import InputValidator from "../../../../common/input-validator";
import GAService from 'react-ga';
import {EVENTS, SERVER_RESPONSES, IS_SUBDOMAIN, SIGN_UP_CONTEXTS} from "../../../../common/constants"
import Cookies from 'universal-cookie';
import { hideLoading } from 'react-redux-loading-bar';

import * as homeActions from '../../homepage/actions';

export const signUp = ({fb, email, password, company,  recaptchaToken}) => async (dispatch, getState) => {
    dispatch({type: authActions.RESET_VALID_ERROR});

    if (!fb) {
        const result = await UserService.create(email, password, company, recaptchaToken);
        const {success, data} = result;
        const token = data.token

        if (success) {
            if (token) {
                const redirect = {context: SIGN_UP_CONTEXTS.FULL};
                Network.setToken(token);
                const cookie = new Cookies();
                cookie.set('token', token, !document.domain.split('.').includes('helpistaging') &&  {domain: `.${IS_SUBDOMAIN ? document.domain.split('.').slice(1).join('.') : document.domain}`, path: '/', sameSite: 'strict', secure: true});
                if(process.env.NODE_ENV === 'development') {
                    sessionStorage.setItem('token', token);
                }
                const resp = await UserService.getUserDetails();
                const userData = Mapper.mapToVm({user: resp.data});
                console.log('userData', userData);
                if(!userData.user.reqActions.email) {
                    dispatch(homeActions.generateEmailToken(userData.user.id));
                }
                if (typeof redirect === 'object' && redirect.context === SIGN_UP_CONTEXTS.FULL && !userData.user.reqActions.email) {
                    dispatch({type: actionTypes.LOGIN_COMPLETE_DETAILS});
                    dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: {...userData}});
                    dispatch(hideLoading());
        
                } else {
                    dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: {...userData}});
                    dispatch(hideLoading());
                    dispatch(handlePendingTask(redirect, {...userData.user}));
                }
            } else {
                dispatch({type: actionTypes.SHOW_VALID_ERROR, payload: data});
                dispatch(hideLoading());
            }


            // const mappedResp = Mapper.mapToVm({ user: data });
            //             dispatch({type: authActions.USER_AUTHENTICATION_SUCCESS, payload: {...mappedResp}});
            // dispatch({type: actionTypes.NEXT_STAGE});

            GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.SIGN_UP, label: 'Step 2'});
            dispatch({type: actionTypes.NEXT_STAGE});
        } else {
            let payload;
            if (data.errors) {
                if(typeof data.errors[0] === 'object') {
                    payload = data.errors[0]['code']
                } else {
                    payload = data.errors[0]
                }
            } else {
                payload = data.message ? data.message : data;
            }


            dispatch({type: authActions.SHOW_VALID_ERROR, payload});
        }
    } else {
        dispatch(facebookSignUp());
    }
};

export const updateUser = (payload, verification, isFinalStage, context) => async (dispatch, getState) => {
    const userId = selectLoggedInUserId(getState());
    const mappedPayload = Mapper.mapToDto({user: payload});
    const result = await UserService.update(userId, mappedPayload);
    const pendingTask = selectPendingTask(getState());
    const { success, data } = result;

    if (success) {
            const mappedResp = Mapper.mapToVm({ user: data });
            dispatch({type: authActions.USER_AUTHENTICATION_SUCCESS, payload: {...mappedResp}});
            dispatch({type: actionTypes.NEXT_STAGE});
            const step3 = data.notifications && data.notifications.case_newsletter ? 'Step 3 + Newsletter' : 'Step 3';
            GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.SIGN_UP, label: step3});

            if (isFinalStage) {
                // GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.USER_UPDATED});
                dispatch({ type: actionTypes.USER_REGISTRATION_SUCCESS });
                if(!mappedResp.user.reqActions.email) {
                    dispatch(homeActions.generateEmailToken(userId))
                }

                if(pendingTask) {
                    dispatch(handlePendingTask(context, { ...mappedResp.user }));
                }
            }
    } else {
        if(data.errors && Array.isArray(data.errors)) {
            dispatch({type: authActions.SHOW_VALID_ERROR, payload: data.errors[0].code})
        } else {
            showErrorToast(SERVER_RESPONSES.GENERAL_ERROR)
        }
    }
};

export const checkSMSCode = (payload, context) => async (dispatch, getState) => {
    const userId = selectLoggedInUserId(getState());
    const signUpStage = selectSignUpStage(getState());
    const emailDomainType = selectEmailDomainAuthorizedType(getState());
    const result = await UserService.checkSMSVerification(userId, payload.code);
    const { success } = result;

    if (success) {
        const resp = await UserService.getUserDetails();
        const mappedResp = Mapper.mapToVm({ user: resp.data });
        dispatch({ type: authActions.USER_AUTHENTICATION_SUCCESS, payload: mappedResp });

        if((signUpStage === 2) || (signUpStage === 1 && emailDomainType === 'ORGANIZATION')) {
            dispatch({type: actionTypes.USER_REGISTRATION_SUCCESS});
        } else {
            dispatch(handlePendingTask(context, {...mappedResp.user}));
        }
    } else {
        dispatch({type: authActions.SHOW_VALID_ERROR, payload: 'bad code'});
    }
};

export const resendSMSCode = () => async (dispatch, getState) => {
    const userId = selectLoggedInUserId(getState());
    const result = await UserService.sendSMSVerification(userId);
    const { success } = result;

    if(success) {
        showSuccessToast('קוד האימות נשלח שוב');
    } else {
        showErrorToast('היתה שגיאה במערכת. נא לנסות מאוחר יותר')
    }
};

/**
 * handle signup with facebook API
 * @param redirect
 */
export const facebookSignUp = (token) => async (dispatch) => {

    dispatch({type: authActions.RESET_VALID_ERROR});

    await FBService.login(async (res) => {

        const {authResponse} = res;
        try {
            const fbResponse = await RestService.get(`login/fb_token/${authResponse.accessToken}`);

            try {
                const {data, success} = fbResponse;

                if (success) {

                    Network.setToken(data);
                    GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.FB_CONNECT})

                    const resp = await UserService.getUserDetails();
                    if (resp.success) {

                        const mappedPayload = Mapper.mapToVm({user: resp.data});
                        dispatch({
                            type: authActions.USER_AUTHENTICATION_SUCCESS,
                            payload: mappedPayload
                        });
                        dispatch({type: actionTypes.NEXT_STAGE});
                    }
                }

            } catch (e) {
            }
        } catch (e) {
            //if given access, but server still throws error
            if(authResponse && authResponse.accessToken) {
                dispatch({type: authActions.SHOW_VALID_ERROR, payload: 'ERR_DUP_EMAIL'});
            }
        }
    });

};

export const checkEmailDomain = (email) => async (dispatch) => {

    const domain = (InputValidator.email(email) === '' && email.split('@')[1]) || '';

    if (domain) {
        try {
            const result = await RestService.get(`api/entities/validate_domain/${domain}`);

            const {success} = result;
            if (success) {
                dispatch({type: actionTypes.EMAIL_DOMAIN_AUTHORIZED, payload: result.data.type, companies: result.data.companies});
            }
            else{
                dispatch({type: actionTypes.EMAIL_DOMAIN_NOT_AUTHORIZED});
            }
        }
        catch(e) {
            dispatch({type: actionTypes.EMAIL_DOMAIN_NOT_AUTHORIZED});
        }
     }
     else{
         dispatch({type: actionTypes.EMAIL_DOMAIN_NOT_AUTHORIZED});
     }
};