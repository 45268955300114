import React, {Component} from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Network from './utils/network';

import "./style.scss"
import Header from './components/header';
import AppRouter from './features/router';
import PageWrapper from './components/page-wrapper'
import Footer from "./components/footer";
import LoadingBar from 'react-redux-loading-bar';

import './style.scss';
import {connect} from "react-redux";
import * as authSelectors from './core/containers/auth/selectors';
import * as authActionTypes from './core/containers/auth/action-types';
import {withRouter} from "react-router";
import {authTest, userLogOut, updatePassword, sendToken} from "./core/containers/auth/actions";

import './style.scss';
import {getCases} from "./core/containers/homepage/actions"
import {Sentry} from '@decor-dev/wrappers/dist';

import theme from './assets/css/react-toolbox/theme'
import ThemeProvider from 'react-toolbox/lib/ThemeProvider';
import {getCitiesWhiteList, getSubdomainData} from "./core/containers/root-reducer/actions";
import {pick, isEmpty} from 'lodash';

import * as rootSelectors from './core/containers/root-reducer/selectors';
import GAService from './common/analytics-service';
import {IS_SUBDOMAIN} from "./common/constants"
import Cookies from 'universal-cookie';

// import { loadRecaptcha } from 'react-recaptcha-v3/dist';

import AuthContext from './core/context/auth-context';

import hirum from '../src/assets/img/hirum.jpg'
import hirumMobail from '../src/assets/img/HelpiHirumMobail.jpg'

const mapStateToProps = (state) => ({
    whiteLogo: rootSelectors.selectWhiteHeader(state),
    specialHeaderClass: rootSelectors.selectSpecialHeaderClass(state),
    authentication: authSelectors.selectAuthenticationForContext(state),
    authTestDone: !!authSelectors.selectAuthTestDone(state),
    forgetPasswordState: authSelectors.selectForgetPassword(state),
    whiteLabel: rootSelectors.selectWhiteLabelState(state)
});

const mapDispatchToProps = (dispatch) => ({
    authTest: () => dispatch(authTest()),
    logOut: () => dispatch(userLogOut()),
    updatePassword: (password, token) => dispatch(updatePassword(password, token)),
    resetForgetPassword: () => dispatch({type: authActionTypes.RESET_FORGOT_PASSWORD}),
    sendToken: (token, type) => dispatch(sendToken(token, type)),
    getWhiteList: () => dispatch(getCitiesWhiteList()),
    getCases: () => dispatch(getCases()),
    getSubdomainData: (subdomain) => dispatch(getSubdomainData(subdomain))
});

class App extends Component {

    componentDidMount() {
        const domain = document.domain;
        const cookies = new Cookies();

        if(IS_SUBDOMAIN) {
            if(process.env.NODE_ENV === 'development'){
                this.props.getSubdomainData(`${domain.split('.')[0]}.localhost:3000`);
            } else {
                this.props.getSubdomainData(domain);
            }
        }
        // else {
        //     //Pride Month Header
        //     document.documentElement.style.setProperty('--homepage-banner-img', `url('/images/cover pride heart.png')`);
        //     document.documentElement.style.setProperty('--homepage-banner-img-tablet', `url('/images/cover pride heart.png')`);
        //     document.documentElement.style.setProperty('--homepage-banner-img-mobile', `url('/images/cover pride heart.png')`);
        // }

        if(cookies.get('token')){
            Network.setToken(cookies.get('token'));
        }

        this.props.authTest();
        this.props.getWhiteList();

        // loadRecaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY);


        //handle initial page load event
        GAService.pageview(this.props.location.pathname);

    }

    componentDidUpdate(prevProps) {
        if(!prevProps.authentication.user && this.props.authentication.user) {
            Sentry.setUserContext({...pick(this.props.authentication.user, ['id', 'email'])})

        }

        //handle route navigation changes
        if(prevProps.location.pathname !== this.props.location.pathname) {
            GAService.pageview(this.props.location.pathname);
        }

        if(isEmpty(prevProps.whiteLabel) && !isEmpty(this.props.whiteLabel)) {
            console.log(this.props.whiteLabel);
            const homePageBanner = this.props.whiteLabel.name ==="חרבות ברזל" ? hirum : this.props.whiteLabel.bannerImg;
            const homePageBannerTablet = this.props.whiteLabel.name ==="חרבות ברזל" ? hirumMobail : this.props.whiteLabel.bannerImgTablet;
            const homePageBannerMobile = this.props.whiteLabel.name ==="חרבות ברזל" ? hirumMobail : this.props.whiteLabel.bannerImgMobile;
            const documentRoot = document.documentElement;
            documentRoot.style.setProperty('--primary-color', this.props.whiteLabel.primaryColor);
            documentRoot.style.setProperty('--secondary-color', this.props.whiteLabel.secondaryColor);
            documentRoot.style.setProperty('--text-color', this.props.whiteLabel.textColor);
            documentRoot.style.setProperty('--heading-bg-color', this.props.whiteLabel.headingBgColor);
            documentRoot.style.setProperty('--menu-link-color', this.props.whiteLabel.menuLinkColor);
            documentRoot.style.setProperty('--header-logo', `url('${this.props.whiteLabel.logoUrl}')`);
            documentRoot.style.setProperty('--success-strip-text', this.props.whiteLabel.headingText);
            documentRoot.style.setProperty('--menu-link-hover-color', this.props.whiteLabel.menuLinkHoverColor);
            documentRoot.style.setProperty('--success-strip-text-color', this.props.whiteLabel.successStripTextColor);
            documentRoot.style.setProperty('--homepage-banner-img', `url('${homePageBanner}')`);
            documentRoot.style.setProperty('--homepage-banner-img-tablet', `url('${homePageBannerTablet}')`);
            documentRoot.style.setProperty('--homepage-banner-img-mobile', `url('${homePageBannerMobile}')`);
        }
    }

    render() {

        const auth = {
            authentication: this.props.authentication,
            authTestDone: this.props.authTestDone,
            logOut: this.props.logOut
        };

        const pageWrapperProps = {
            updatePassword: this.props.updatePassword,
            forgetPassword: this.props.forgetPasswordState,
            resetForgetPassword: this.props.resetForgetPassword,
            sendToken: this.props.sendToken,

        };

        return (
            <div>
                {
                    <ThemeProvider theme={theme}>
                        <AuthContext.Provider value={auth}>
                            <Header
                                whiteLogo={this.props.whiteLogo}
                                specialHeaderClass={this.props.specialHeaderClass}
                                getCases={this.props.getCases}
                                whiteLabel={this.props.whiteLabel}
                                homePath={"/"}
                            />
                            <LoadingBar updateTime={100} maxProgress={95} progressIncrease={10} style={{ backgroundColor: '#00bcd4', height: '1px', position: 'fixed', zIndex: '9999'}}/>
                            <PageWrapper {...pageWrapperProps} location={this.props.location}>
                                <AppRouter/>
                            </PageWrapper>
                            <Footer/>
                            <ToastContainer
                                position="bottom-left"
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover
                            />
                        </AuthContext.Provider>
                    </ThemeProvider>
                }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
