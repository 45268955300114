import React, {PureComponent} from 'react';
import ToolTip from 'react-toolbox/lib/tooltip';
import './style.scss';

class ToolTipWrapper extends PureComponent {
    render() {
        const Div = (props) => <div {...props}>{props.children}</div>;
        const ToolTipNode = ToolTip(Div);

        return (
            <ToolTipNode
                className="tool-tip-wrapper"
                tooltipPosition="horizontal"
                theme={
                    {
                    tooltip: 'tool-tip-wrapper-inner',
                    tooltipInner:'tool-tip-inner'
                    }
                }
                {...this.props} >{this.props.label}</ToolTipNode>
        )
    }
}

export default ToolTipWrapper;