import React, { PureComponent } from 'react';
import './_style.scss';
import '../../assets/css/react-toolbox/theme.css';
import theme from '../../assets/css/react-toolbox/theme.js';
import ThemeProvider from 'react-toolbox/lib/ThemeProvider';
import queryString from 'query-string';
import ForgotPasswordModal from '../forgot-password-modal';
import history from '../../utils/history';
import {SIGN_UP_CONTEXTS} from "../../common/constants";

class PageWrapper extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            forgetPassword: false,
            token: ''
        }
    }

    getClass() {
        const {pathname} = this.props.location;

        if(pathname === '/') {
            return 'home';
        } else if (pathname === '/signin') {
            return 'login-route';
        }

        return '';
    }

    async componentDidMount() {
        if(this.props.location.search.length){
            const query = queryString.parse(this.props.location.search);
            if(query.passwordtoken) {

                const response = await this.sendToken(query.passwordtoken, 'password');

                if(response) {
                    this.setState({...this.state, forgetPassword: !this.state.forgetPassword, token: query.passwordtoken})

                }
            } else if (query.institute_id && query.institute_type) {
                sessionStorage.setItem('institute_id', query.institute_id);
                sessionStorage.setItem('institute_type', query.institute_type);
                const user = sessionStorage.getItem('token');
                if(!user) {
                    if (query.institute_type  === 'organization') {
                        history.navigate({
                            pathname: '/signin',
                            state: {
                                context: SIGN_UP_CONTEXTS.SOCIAL_WORKER
                            }
                        });
                    } else {
                    // A specific change for the Iskar company
                       if(query.institute_id === '6373f106116f6200223968d5'){                                                                                                  
                           history.navigate('/');
                       }
                       else history.navigate('/signin');
                    }
                }
            }
        }
    }

    sendToken = async (token, type) => this.props.sendToken(token, type);

    updatePassword = async (password) => {
        const response = await this.props.updatePassword(password, this.state.token);

        if(response) {
            history.navigate('/signin');
        }
    };

    toggleForgotPassword = () => this.setState({...this.state, forgetPassword: !this.state.forgetPassword});

    render() {
        const classes = this.getClass();

        return (
            <div className={`page-wrapper ${classes}`}>
                <ThemeProvider theme={theme}>
                    <div>
                        {this.props.children}
                        {this.props.location.pathname === '/' &&
                            <ForgotPasswordModal
                                show={this.state.forgetPassword}
                                type={"password"}
                                currState={this.props.forgetPassword}
                                handleToggle={this.toggleForgotPassword}
                                sendRequest={this.updatePassword}
                                resetForgetPassword={this.props.resetForgetPassword}
                                successMessage={'הסיסמה שלך שונתה בהצלחה. את/ה יכול/ה להכנס לחשבון שלך.'}
                            />
                        }
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}

export default PageWrapper;