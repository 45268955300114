/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import * as actionTypes from './action-types';
import Mapper from './mapper';
import Network from '../../../utils/network';
import RestService from '../../../common/rest-service';
import {Storage} from '@decor-dev/wrappers/dist';
import {CASE_PAGE_SIZE, ERROR_MESSAGE_TRANSLATE, EVENTS, USER_STATES} from '../../../common/constants';
import _ from 'lodash';
import FilterMapper from '../filters/mapper';
import AuthMapper  from  '../auth/mapper';
import SubscriberService from '../../../common/subscriber-service';
import SubscriberMapper from '../common/newsletter-modal/mapper';
import {showSuccessToast, showErrorToast} from '../common/toast/actions';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {selectWhiteLabelState} from "../root-reducer/selectors";
import {selectAuth} from "../auth/selectors";
import GAService from 'react-ga';
import EmailValidService from '../../../common/email-validation-service';
import UserService from '../auth/service';
import {USER_AUTHENTICATION_SUCCESS} from '../auth/action-types';
import {CASES_FINISHED_HOURS_SUCCESS} from "./action-types";

/**
 * fetch cases from server
 * @returns {Function}
 */
export const getCases = (filters) => async (dispatch, getState) => {

    dispatch({ type: actionTypes.TOGGLE_LOADING, payload: { loading: true } });
    dispatch(showLoading());

    const whiteLabel = selectWhiteLabelState(getState());

    const authUser = selectAuth(getState());

    const userIsWideCrowd = authUser.user &&
        ((authUser.user.state === USER_STATES.USER_PENDING_APPROVAL)
            ||
            (
                (authUser.user.state === USER_STATES.USER_ACTION_REQUIRED
                || authUser.user.state === USER_STATES.USER_VALIDATE_EMAIL)
                && !authUser.user.company
            )
        );

    const sort = filters && filters.sort ? filters.sort : 'newest';

    if(!filters) {
        dispatch({type: actionTypes.RESET_FILTERS});
    }

    const params = {
        whitelabel_id: whiteLabel && whiteLabel.id ? whiteLabel.id : undefined,
        whitelabel_type: whiteLabel && whiteLabel.type ?  whiteLabel.type : undefined,
        page_size: CASE_PAGE_SIZE,
        user_wide_crowd: userIsWideCrowd,
        sort,
        ...FilterMapper.mapToDto(filters)
    };

    try {
        const response = await Network.get('api/open_cases', params);
        const { data, headers } = response;
        const payload = {
            cases: data,
            count: headers['x-total-count']
        };

        dispatch({ type: actionTypes.GET_CASES_SUCCESS, payload: Mapper.mapToVm(payload) });
        dispatch(hideLoading());

    } catch (e) {
        dispatch(hideLoading());
    }
};

export const applyFilter = (filters) => (dispatch) => {

    const filteredValues = Object.keys(filters).filter((it) => {return (it === 'pageNumber') || (filters[it] && filters[it].length > 0 && filters[it] !== '*all*')});
    dispatch({type: actionTypes.TOGGLE_LOADING, payload: {loading: true}});
    dispatch({type: actionTypes.APPLY_FILTER, payload: filters});
    dispatch(getCases(_.pick(filters, filteredValues)));
};

export const newsletterSignup = (payload) => async (dispatch, getState) => {

    dispatch(showLoading());
    const response = await SubscriberService.post({...SubscriberMapper.mapToDto(payload)});
    const {success} = response;

    if(success) {
        Storage.setItem('newsletterSignUp', '', true);
        dispatch({type: actionTypes.NEWSLETTER_SUCCESS});
        dispatch(hideLoading());
        GAService.event({category: EVENTS.NEWSLETTER.DISPLAY_NAME, action: EVENTS.NEWSLETTER.ACTIONS.SIGN_UP })
    } else {
        dispatch({type: actionTypes.NEWSLETTER_ERROR, payload: response.data});
        dispatch(hideLoading());
    }

};

export const getSubscriber = (token) => async (dispatch) => {
    const response = await SubscriberService.get(token);
    const {success, data} = response;

    if(success) {
        dispatch({type: actionTypes.GET_SUBSCRIBER_SUCCESS, payload: SubscriberMapper.mapToVm(data)});
    }
};

export const newsletterUpdate = (payload) => async (dispatch) => {
    const updates = SubscriberMapper.mapToDto(_.pick(payload, ['firstName', 'email', 'taskTypes', 'regions']));
    dispatch(showLoading());
    const response = await SubscriberService.put(updates, payload['token']);
    const {success} = response;

    if(success) {
        dispatch({type: actionTypes.NEWSLETTER_SUCCESS});
        dispatch(hideLoading());
    } else {
        dispatch({type: actionTypes.NEWSLETTER_ERROR, payload: response.data});
        dispatch(hideLoading());
    }
};

export const newsletterUnsubscribe = (token) => async (dispatch) => {
    dispatch(showLoading());

    const response = await SubscriberService.unsubscribe(token);
    const {success} = response;

    if (success) {
        dispatch({type: actionTypes.NEWSLETTER_UNSUBSCRIBE_SUCCESS});
    }

    dispatch(hideLoading());

};

export const validateEmailToken = (token) => async (dispatch) => {
    const response =  await EmailValidService.validate(token);
    const {success, data} = response;

    if(success) {
        dispatch({type: actionTypes.EMAIL_VALIDATION_RESPONSE_SUCCESS, payload: {cases: data.cases, valid: data.valid, token}})

        const resp = await UserService.getUserDetails();
        if(resp.success) {
            dispatch({type: USER_AUTHENTICATION_SUCCESS, payload: AuthMapper.mapToVm({user: resp.data})});
        }
    }
}

export const generateEmailToken = (userId) => async (dispatch, getState) => {
    const whiteLabel = selectWhiteLabelState(getState());

    const response = await EmailValidService.send({userId, whiteLabel})
    const {success, data} = response;

    if(success) {
        dispatch({type: actionTypes.EMAIL_VALIDATION_GENERATE_SUCCESS})
        showSuccessToast()
    } else {
        if(data === 'Token Limit') {
            showErrorToast(ERROR_MESSAGE_TRANSLATE[data]);
        } else {
            showErrorToast();
        }
    }
}

export const getFinishedHours = () => async (dispatch, getState) => {
    const whiteLabel = selectWhiteLabelState(getState())

    const paramsToServer = whiteLabel ? {whitelabel_id: whiteLabel.id} : {};

    const response = await RestService.get('api/cases_finished_hours', paramsToServer);
    const {success, data} = response;

    if(success) {
        dispatch({type: actionTypes.CASES_FINISHED_HOURS_SUCCESS, payload: data})
    }
}
