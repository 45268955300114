import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import Input from 'react-toolbox/lib/input';

class TextAreaInput extends PureComponent {

    /**
     * handle the value change - prop func, validation text
     * @param value
     */
    handleFieldChange(value){
        this.props.handleFieldChange(value, this.props.fieldName);
    }

    render() {

        return (
            <div className={`text-area-input-container`}>
                <Input
                    type={'text'}
                    multiline
                    label={this.props.label}
                    required={this.props.required}
                    rows={this.props.rowsNum}
                    error={this.props.error}
                    theme={{
                        label: `descriptionLabel`,
                        error: `errorLabel`,
                        bar: 'bottomBar',
                        errored: 'errored',
                        inputElement: (this.props.value && this.props.value.length > 0) ? `notEmpty` : ``
                    }}
                    name={this.props.fieldName}
                    value={this.props.value}
                    onChange={this.handleFieldChange.bind(this)}
                />
            </div>
        )
    }
}

TextAreaInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    rowsNum: PropTypes.number,
    validationTextFunc: PropTypes.func,
    handleFieldChange: PropTypes.func,
    fieldName: PropTypes.string,
    validationFunc: PropTypes.func
};

export default TextAreaInput;
