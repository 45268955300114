import RestService from "../../../common/rest-service";
import {UPDATE_WHITELIST, HEADER_CLASS, WHITE_LABEL_SUCCESS} from "./action-types";
import WhiteLabelMapper from '../white-label/mapper';

export const getCitiesWhiteList = () => async (dispatch) => {

    try {
        const response = await RestService.get('api/static_data/validation/config/city_whitelist');

        if(response){
            dispatch({type: UPDATE_WHITELIST, payload: response.data.white_list})
        }

    } catch (e) {

    }
};

export const setSpecialHeaderClass = (klass) => async (dispatch) => {
    dispatch({type: HEADER_CLASS, payload: klass})
};

export const getSubdomainData = (subdomain) => async (dispatch) => {
    const response = await RestService.get(`api/whitelabel/${subdomain}`);
    const {success, data} = response;
    if (success) {
        dispatch({type: WHITE_LABEL_SUCCESS, payload: WhiteLabelMapper.mapToVm(data.whitelabel)})
    }
};