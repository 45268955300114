import {HELPI_GA_ID} from './constants';
import ReactGA from 'react-ga';

class GAService {
    init = () => ReactGA.initialize(HELPI_GA_ID);

    pageview = (path) => ReactGA.pageview(path);

    event = (evt) => {
        const nonInteraction = 'nonInteraction' in evt ? evt.nonInteraction : true;

        ReactGA.event({
            ...evt,
            nonInteraction
        })
    }
}


export default new GAService();