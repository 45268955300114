import {Route, Switch} from "react-router";
import React, {Component} from "react";
import Routes from './routes';
import Homepage from "../homepage";
import {withRouter} from "react-router-dom";

class AppRouter extends Component {

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setCurrentTitle()
        }
    }

    componentDidMount() {
        this.setCurrentTitle()
    }

    setCurrentTitle(){
        const pathName = this.props.location.pathname;

        const currentRoute = Routes.filter(({path}) => pathName.includes(path.split("/")[1]))[0];

        (currentRoute && currentRoute.title) ?
                document.title = `${currentRoute.title} - Helpi` :
             document.title = `Helpi - פלטפורמה להתנדבויות לחברות ועסקים`
    }

    render() {
        return (
            <Switch>
                {Routes.map(
                    ({path, component, exact = false}) => (
                        <Route key={path}
                               path={path}
                               exact={exact}
                               component={component}
                        />
                    )
                )}
                <Route component={Homepage}/>
            </Switch>
        );
    }
}

export default withRouter(AppRouter);
