class SubscriberMapper {
    mapToVm = (payload) => {
        return (
            {
                firstName: payload.first_name,
                email: payload.email,
                taskTypes: payload.task_types,
                regions: payload.regions,
                userId: payload.user_id,
            }
        )

    };

    mapToDto = (payload) => {
        return (
            {
                first_name: payload.firstName,
                email: payload.email,
                task_types: payload.taskTypes,
                regions: payload.regions,
                user_id: payload.userId,
                'g-recaptcha-response': payload['g-recaptcha-response']
            }
        )
    };
}

export default new SubscriberMapper();