import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import RadioButton from "react-toolbox/lib/radio/RadioButton";
import RadioGroup from "react-toolbox/lib/radio/RadioGroup";


class Radio extends PureComponent {

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value){
        if(this.props.includeGroupNameOnSubmit){
            this.props.handleChange(value, this.props.groupName);
        } else {
            this.props.handleChange(value);
        }
    }

    genButtons = () => {
        this.props.buttons.map((button, index) => {
            return(
                <div>
                    <RadioButton
                        key={`radio-button-${button.value}-${index}`}
                        label={button.label}
                        value={button.value}
                        name={button.name}
                        theme={
                            {
                                radioChecked: 'radioChecked',
                                text: 'radioLabel'
                            }
                        }
                    />
                </div>

            )
        })
    }

    render() {
        return (
            <div className="radio-container">
                <RadioGroup name={this.props.groupName} value={this.props.currentValue} onChange={this.handleChange}>
                    {
                        this.props.buttons.map((button, index) => {
                            return(
                                    <RadioButton
                                        key={`radio-button-${button.value}-${index}`}
                                        label={button.label}
                                        value={button.value}
                                        name={button.name}
                                        theme={
                                            {
                                                radioChecked: 'radioChecked',
                                                text: 'radioLabel'
                                            }
                                        }
                                    >
                                        {this.props.currentValue === button.value && button.elShowOnChoose}
                                    </RadioButton>

                            )
                        })
                    }
                </RadioGroup>
            </div>
        )
    }
}

Radio.propTypes = {
    groupName: PropTypes.string.isRequired,
    buttons: PropTypes.array.isRequired,
    currentValue: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
};

Radio.defaultProps = {
    includeGroupNameOnSubmit: false,
}

export default Radio;