import React from "react";
import AuthConsumer from "../../core/containers/auth/auth-protected";
import {USER_ROLES} from "../../common/constants";
import PropTypes from 'prop-types';

class RoleProtected extends React.Component {

    isAuthorized = () => this.props.user && (this.props.roles.includes(this.props.user.role) || this.props.user.role === USER_ROLES.ADMIN);

    render() {
        return this.isAuthorized() && this.props.children;
    }
}

RoleProtected.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))).isRequired,
    user: PropTypes.object
};

RoleProtected.defaultProps = {
    roles: Object.values(USER_ROLES)
};

export default AuthConsumer(RoleProtected);