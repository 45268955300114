import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import InputValidator from "../../../../common/input-validator";
import {HELPI_BUTTON_CLASSES} from '../../../../common/constants';
import HelpiButton from '../../../helpi-button';
import ValidatedForm from '../../../validated-form';
import 'formdata-polyfill';

const FormFields = {
    idNumber: {
        validator: InputValidator.id,
        type: 'number',
        label: 'מספר תעודת זהות:',
        klass: 'auth-input',
        toolTip: 'מספר ת״ז שלך מאפשרת לנו לבטח אותך בביטוח מתנדבים של ביטוח לאומי'
    },
    phoneNumber: {
        validator: InputValidator.phone,
        type: 'phone',
        label: 'מספר טלפון נייד:',
        klass: 'auth-input',
    },
};

class IdSmsDetails extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            valid: false
        }
    }

    onValidChange = (valid) => this.setState({ valid });

    handleFieldValueChange = () => {
        if(this.props.validError !== ''){
            this.props.resetValidError();
        }
    };

    onFinalDetailsSubmit = (evt) => {
        evt.preventDefault();

        if(this.props.validError) {
            this.props.resetValidError();
        }

        const formData = new FormData(evt.target);

        this.props.onCompleteDetailsSubmit({
            idNumber: formData.get('idNumber'),
            phoneNumber: formData.get('phoneNumber')
        }, true, true);
    };

    render() {

        const error = this.props.validError ? <div className="error-message">{this.props.validError}</div> : null;

        return (
            <form onSubmit={this.onFinalDetailsSubmit} className="final-details-form">
                <ValidatedForm
                    onValidChange={this.onValidChange}
                    fields={FormFields}
                    handleFieldValueChange={this.handleFieldValueChange}
                />
                <div className="under-text">{error}</div>
                <HelpiButton disabled={!this.state.valid}
                             type={'submit'}
                             label={'שלח'}
                             classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}
                />
            </form>
        )
    }
}

IdSmsDetails.propTypes = {
    onCompleteDetailsSubmit: PropTypes.func
};

export default IdSmsDetails;