import Homepage from './../homepage';
import FAQ from './../faq';
import Contact from "../contact";
import Case from "../case";
import Auth from "../auth";
import About from "../about";
import Partners from "../partners";
import Success from "../success";
import Request from "../request";
import Profile from "../profile";
import ProfileTasks from "../profile/tasks";

import PrivacyPolicy from '../privacy-policy';
import TermsAndConditions from '../terms-and-conditions';
import PasswordChange from '../profile/password';
// , {
//     path: '/my-tasks',
//         component: ProfileTasks,
//         title: 'המשימות שלי',
//         exact: true
// }
export default [
    {
        path: '/c/:serial',
        component: Case,
        title: ''
    }, {
        path: '/case/:id/:companyId?',
        component: Case,
        title: ''
    }, {
        path: '/contact/:formSubject?',
        component: Contact,
        title: 'צרו קשר'
    }, {
        path: '/about',
        component: About,
        title: 'אודות'
    }, {
        path: '/faq',
        component: FAQ,
        title: 'שאלות ותשובות'
    }, {
        path: '/signin',
        component: Auth,
        title: 'התחברות לחשבון'
    }, {
        path: '/partners',
        component: Partners,
        title: 'השותפים שלנו'
    }, {
        path: '/success',
        component: Success,
        title: 'סיפורי הצלחה'
    }, {
        path: '/ask-help',
        component: Request,
        title: 'טופס בקשה'
    }, {
        path: '/profile',
        component: Profile,
        title: 'פרופיל',
        exact: true
    }, {
        path: '/profile/password',
        component: PasswordChange,
        title: 'שינוי סיסמה',
        exact: true
    }, {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        title: 'תנאי שימוש'
    }, {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        title: 'מדיניות פרטיות'
    }, {
        path: '/cases',
        component: Homepage,
        exact: false,
        title: ''
    }];

