import React, {PureComponent} from 'react';
// import './style.scss';
import PropTypes from "prop-types";
import AccordionCard from "./accordion-card";

class Accordion extends PureComponent {

    render() {

        return (
            <div className={`accordion`}>
                {
                    this.props.cards.map((item, index) => (
                            <AccordionCard card={item} key={`card-${item.id}-${index}`}/>
                        )
                    )
                }
            </div>
        );
    }
}

Accordion.propTypes = {
    cards: PropTypes.array
};

export default Accordion;
