import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'react-toolbox/lib/checkbox';
import './_style.scss';

class CheckboxWrapper extends PureComponent {

    onChange = () => this.props.onChange(this.props.value, this.props.name);

    render() {
        const { label, checked, colored } = this.props;

        const coloredClass = colored ? `colored-box ${colored}` : '';

        return (
            <div className="checkbox-wrapper-container">
                <Checkbox
                    label={label}
                    onChange={this.onChange}
                    checked={checked}
                    className={`helpi-checkbox ${checked ? 'checked' : ''} ${coloredClass}`}
                    theme={{
                        text: 'checkbox-label',
                        ...this.props.theme
                    }}
                />
            </div>
        )
    }
}

CheckboxWrapper.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    colored: PropTypes.oneOf(['primary', 'secondary'])
};

CheckboxWrapper.defaultProps = {
    name: ''
}

export default CheckboxWrapper;