import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from './actions';
import * as selectors from "../root-reducer/selectors";

export default (RequestFormGUI) => {

    const mapStateToProps = (state) => ({
        cityWhiteList: selectors.selectCityWhiteList(state),
        whiteLabel: selectors.selectWhiteLabelState(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        sendCaseRequest: (formState, userId, taskType) => dispatch(actions.sendCaseRequest(formState, userId, taskType))
    });

    class RequestFormWrapper extends Component {
        render() {
            return (
                <RequestFormGUI
                    sendCaseRequest={this.props.sendCaseRequest}
                    item={this.props.item}
                    cityWhiteList={this.props.cityWhiteList}
                    whiteLabel={this.props.whiteLabel}
                />
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(RequestFormWrapper);
};
