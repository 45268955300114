import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import RoleProtected from '../../../role-protected';
import { IS_SUBDOMAIN } from "../../../../common/constants";
import { USER_ROLES } from '../../../../common/constants';

class HeaderMenuMobile extends React.Component {

    /**
     * generate link according to item metadata
     * @param item
     * @returns {*}
     */
    generateLink = (item) => {
        const label = item.genLabel ? item.genLabel.apply(null, [this.props.user]) : item.label;
        const link = item.genLink ? item.genLink.apply(null, [this.props.user, this.props.path]) : item.link;

        const changeRouteForResponsibleRole = item.roles && this.props.user.role === USER_ROLES.RESPONSIBLE && item.roles.includes(USER_ROLES.RESPONSIBLE)
            ? '/admin/#/admin/companies' : link;
        return item.isExternal ?
            <a href={link} target={"_blank"} onClick={this.props.onClickItem}>{label}</a>
            :
            item.targetSelf ?
                <a href={changeRouteForResponsibleRole} target={"_self"} onClick={this.props.onClickItem}>{label}</a>
                :
                <NavLink
                    activeClassName="active"
                    to={link} target={item.target}
                    onClick={this.props.onClickItem}>
                    {label}
                </NavLink>
    };


    /**
     * generates item content
     * @param item
     * @param topIndex
     * @returns {*}
     */
    generateItemContent = (item, topIndex) => {
        if(item.subItems) {
            return (item.subItems.map((item, index) => {
                    const subLink = (
                        <li key={`${item.link}-${index}-${topIndex}`} className={`side-nav-option`}>
                            {this.generateLink(item)}
                        </li>
                    );

                    if(item.roles){
                        return (
                            <RoleProtected key={`${item.link}-${index}-${topIndex}`} roles={item.roles}>
                                {subLink}
                            </RoleProtected>
                        )
                    }
                    return subLink;
                }
            ))
        }

        const link = (
            <li key={`${item.link}-${topIndex}`} className={`side-nav-option`}>
                {this.generateLink(item)}
            </li>
        );

        if(item.roles) {
            return (
                <RoleProtected key={`${item.link}--${topIndex}`} roles={item.roles}>
                    {link}
                </RoleProtected>
            )
        }


        return link;

    };

    logOut = () => {
        this.props.onClickItem();
        this.props.logOut();
    }

    render() {
        return (
            <div className={`side-nav-menu ${this.props.className}`}>
                <ul>
                    {
                        this.props.items.filter((link) => (!link.roles || (this.props.user && link.roles.indexOf(this.props.user.role) > -1)) && (!IS_SUBDOMAIN || !link.notInWhitelabel) && (!link.hide || link.hide.apply(null, [this.props.user,  this.props.whiteLabel]))).map((item, index) => {
                            return (
                                this.generateItemContent(item, index)
                            )
                        })
                    }
                    {
                        this.props.user &&
                        <li key='logout-mobile-menu' className="side-nav-option">
                            <div onClick={this.logOut}>התנתקות</div>
                        </li>
                    }
                </ul>
            </div>
        );
    }
}

HeaderMenuMobile.propTypes = {
    items: PropTypes.array.isRequired,
    onClickItem: PropTypes.func
};

export default withRouter(HeaderMenuMobile);
