/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import * as actionTypes from './action-types';

const initialState = {
    stage: 0,
    verificationStage: 0,
    registrationSuccess: false,
    errors: {},
    emailDomainAuthorized: false,
    emailDomainType: '',
    companies: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.NEXT_STAGE: {

            return {
                ...state,
                stage: state.stage + 1
            };
        }
        case actionTypes.NEXT_VALIDATION_STAGE: {
            return {
                ...state,
                verificationStage: state.verificationStage + 1
            };
        }
        case actionTypes.USER_REGISTRATION_SUCCESS: {
            return {
                ...state,
                registrationSuccess: !state.registrationSuccess
            }
        }
        case actionTypes.EMAIL_DOMAIN_AUTHORIZED: {
            const {payload} = action;
            return {
                ...state,
                emailDomainAuthorized: true,
                emailDomainType: payload,
                companies: action.companies
            }
        }
        case actionTypes.EMAIL_DOMAIN_NOT_AUTHORIZED: {
            return {
                ...state,
                emailDomainAuthorized: false,
                companies: {}
            }
        }
        case actionTypes.RESET_REGISTRATION: {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
};
