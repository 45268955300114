import React from 'react';
// import revaha from '../../../../public/images/partners/partners/revaha_office2.png'
import facebookLogo from '../../../assets/img/facebook-logo.png'

class FooterSocial extends React.PureComponent {


    render() {
        return (
            <div className="social-links">
                {/*<div className={"sponsor-container"}>*/}
                    {/*<div className="text"><span>העמותה פועלת במיזם משותף עם:</span></div>*/}
                    {/*<div><img alt={"משרד הרווחה"} src={revaha}/></div>*/}
                {/*</div>*/}

                <div className={"joinus-container"}>
                    <div className={"follow-text"}>עקבו אחרינו</div>
                    <a className={"facebook"} href="https://www.facebook.com/groups/519469054923602/" target="_blank" rel="noopener noreferrer">
                        <img src={facebookLogo} alt={"קישור לפייסבוק"}/>
                        <div className={"helpiFacebook"}>Helpi הלפי</div>
                    </a>
                </div>

            </div>
        );
    }
}

export default FooterSocial;