/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Auth box for login / signup
 */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Wizard from "../wizard";
import LoginDetailsStage from "./login-details";
import BasicDetails from "./basic-details";
import FinalDetails from "./final-details";
import SocialWorkerSignUp from './social-worker';
import {SIGN_UP_CONTEXTS, USER_ROLES} from '../../common/constants';
import _ from 'lodash';

class SignUpBox extends React.PureComponent {

    renderDots = (stages) => {
        return (
            <ul className="helpi-dots">
                {stages.map((item, i) => {
                    return <li key={i}><span className={`helpi-dot ${this.props.stage === i ? 'active' :''}`}/></li>
                })}
            </ul>
        )

    };

    determineStages = () => {
        const {signUpContext, emailDomainType, user} = this.props;
        const {context} = signUpContext || '';

        const signUp = _.isEmpty(signUpContext) || context === SIGN_UP_CONTEXTS.NONE || ((context === SIGN_UP_CONTEXTS.FULL || context === SIGN_UP_CONTEXTS.SOCIAL_WORKER)&& !this.props.loginCompleteDetails);

        const isSocialWorker = (emailDomainType.length && emailDomainType === 'ORGANIZATION') || (user && user.role && user.role === USER_ROLES.SOCIAL_WORKER) || context === SIGN_UP_CONTEXTS.SOCIAL_WORKER;

        const noBasicDetails = context === SIGN_UP_CONTEXTS.BASIC || (user && !this.props.user.firstName && !signUp);

        const showSMS = context === SIGN_UP_CONTEXTS.COMPLETE_DETAILS || context === SIGN_UP_CONTEXTS.FULL || context === SIGN_UP_CONTEXTS.BASIC;

        const title = signUp ? 'יצירת חשבון חדש' : 'השלמת פרטים חסרים';

        const loginDetails = signUp && !noBasicDetails
            ?
            { title: title, component: <LoginDetailsStage {...this.props} />}
            : null;

        const basicDetails = (signUp || noBasicDetails) && !isSocialWorker
            ?
            { title: title, component: <BasicDetails {...this.props} />}
            : null;

        const finalDetails = showSMS && !isSocialWorker
            ?
            { title: title, component: <FinalDetails {...this.props} />}
            : null;

        const socialWorker = isSocialWorker
            ?
            {title, component: <SocialWorkerSignUp {...this.props} />}
            : null;

        const stages = {
            loginDetails,
            basicDetails,
            finalDetails,
            socialWorker
        };

        return Object.keys(stages).reduce((acc, key) => {
            if(stages[key] !== null) {
                acc.push(stages[key]);
            }
            return acc;
        }, []);

    };

    render() {
        const stages = this.determineStages();

        const dots = stages.length > 1 ? this.renderDots(stages) : null;

        return (
            <div className={'auth-box with-dots'}>
                {dots}
                <Wizard stages={stages} activeStage={this.props.stage} />
            </div>
        );
    }
}

SignUpBox.propTypes = {
    onLoginDetailsSubmit: PropTypes.func.isRequired,
    stage: PropTypes.number,
    signUpContext: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default SignUpBox;
