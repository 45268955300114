import * as actionTypes from './action-types';
import {ERROR_MESSAGE_TRANSLATE} from "../../../../common/constants";

const initialState = {
    userTaskCompleted: null,
    userOnGoingTasks: null,

    showSmsModal: false,
    validError: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.TOGGLE_SMS_MODAL: {
            return {
                ...state,
                validError: '',
                showSmsModal: !state.showSmsModal
            };
        }
        case actionTypes.SHOW_VALID_ERROR: {
            const {payload} = action;

            const validError = ERROR_MESSAGE_TRANSLATE[payload];

            return {
                ...state,
                validError
            }
        }
        case actionTypes.RESET_VALID_ERROR: {
            return {
                ...state,
                validError: ''
            }
        }

        case actionTypes.USER_TASK_COMPLETE_SUCCESS: {
            return {
                ...state,
                userTaskCompleted: action.result.data.cases
            }
        }
        case actionTypes.USER_TASK_COMPLETE_ERROR: {
            return {
                ...state,
                validError: action.e.message
            }
        }
        case actionTypes.USER_TASK_FUTURE_SUCCESS: {
            return {
                ...state,
                userOnGoingTasks: action.result.data.cases
            }
        }
        case actionTypes.USER_TASK_FUTURE_ERROR: {
            return {
                ...state,
                validError: action.e.message
            }
        }
        default:
            return state;
    }
};
