import React from 'react';
import PropTypes from 'prop-types';
import {TASK_TYPES_FORM_DATA} from "../../features/request/constants";
import './style.scss';

const TaskTypesGrid = (props) => {


    return (
        <div className={`request-type-menu ${!props.onClick ? 'not-clickable' : ''}`} id={`squaresCaseType`}>
            {
                TASK_TYPES_FORM_DATA.map((item, index) => {
                    return (
                        <div
                            key={`request-type-${item.type}${index}`}
                            className={`case-type-square ${ item.type === props.activeRequest ? 'chosen' : ''}`}
                            onClick={props.onClick ? () => props.onClick(item) : null}
                        >
                            <img src={item.img} alt={""}/>
                            <label className={`case-type-square`}>{item.label}</label>
                        </div>
                    );
                })
            }
        </div>
    )
};

TaskTypesGrid.propTypes = {
    onClick: PropTypes.func,
    activeRequest: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default TaskTypesGrid;