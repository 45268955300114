import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import moment from "moment";
import 'moment/locale/he';

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

class DateTimeCasePicker extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            fullDateTime: this.props.defaultDate,
            isValid: true
        };

        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    isValid = (value) => moment.isMoment(value);

    handleTimeChange(value){

        const isValid = this.isValid(value);

        if(isValid) {

            const fullDateTime =
                this.state.fullDateTime.set({'hour': value.get('hour'), 'minutes': value.get('minutes')});

            this.setState({
                fullDateTime: fullDateTime,
            });
            this.props.handleDateTimeChange(this.state.fullDateTime, this.state.isValid);
        }
    }

    handleDateChange(value){

        const isValid = this.isValid(value) && this.props.validateDate(value);

        if(isValid) {
            const fullDateTime =
                this.state.fullDateTime.set({
                    'date': value.get('date'),
                    'month': value.get('month'),
                    'year': value.get('year')
                }) ;

            this.setState({
                fullDateTime: moment(fullDateTime),
                isValid
            });
            if (this.props.name=="startRangeOfDates" || this.props.name=="endRangeOfDates"){
                const isRangeOfDatesValid = this.isValidRangeOfDates(value, this.props.name)
                const error = isRangeOfDatesValid ? "" : "טווח התאריכים אינו חוקי" 
                this.props.handleDateTimeChange(fullDateTime, isRangeOfDatesValid, error);
            }else {
                this.props.handleDateTimeChange(fullDateTime, isValid);
            }

        }
        else {
            this.setState({
                isValid
            });
            this.props.handleDateTimeChange(this.state.fullDateTime, isValid);
        }
    }

    isValidRangeOfDates(value, name){
        if (name=="startRangeOfDates" || name=="endRangeOfDates"){
            return name == 'startRangeOfDates' ? value.isBefore(this.props.form.endRangeOfDates.value) : this.props.form.startRangeOfDates.value.isBefore(value);
        }
    }
    render() {
        moment.locale('he');

        return (
            <div className={`date-time-case-picker-container `}>
                <div className={`date-container ${ !this.state.isValid ? 'notValid' : '' }`}>
                    <label className={'dateLabel'}>
                        <i className={`material-icons`}>event</i>
                        <Datetime
                            timeFormat={ false }
                            locale={'he'}
                            onChange={this.handleDateChange}
                            defaultValue={this.state.fullDateTime}
                            closeOnSelect={true}
                            isValidDate={ this.props.validateDate }
                        />
                    </label>
                </div>
                <div className={`time-container`}>
                    <Datetime
                        dateFormat={ false }
                        locale={'he'}
                        onChange={this.handleTimeChange}
                        inputProps={{readOnly: true}}
                        defaultValue={this.state.fullDateTime}
                        timeConstraints={{
                            minutes: { step: this.props.timeStep }
                        }}
                    />
                </div>
            </div>
        )
    }
}

DateTimeCasePicker.propTypes = {
    validateDate: PropTypes.func.isRequired,
    defaultDate: PropTypes.object.isRequired,
    timeStep: PropTypes.number,
    handleDateTimeChange: PropTypes.func.isRequired
};

DateTimeCasePicker.defaultProps = {
    timeStep: 15
};

export default DateTimeCasePicker;