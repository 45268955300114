import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class TaskDataItem extends PureComponent {

    render() {
        const {className, label, text} = this.props;
        return(
            <div className={className}>
                <span className={`text-emphasized`}>{label} </span>
                <span>{text}</span>
            </div>
        )
    }
}

TaskDataItem.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.string
};

export default TaskDataItem;