
export const PROMOTION_VIDEO = 'HaMbvqcJ_Ds';

export const BOARD_MEMBERS = [
    {
        name : "ו\"ר הוועד - גיא גבע",
        title: "מנכ\"ל חברת WXG"
    }, {
        name : "מריאנה וקסמן",
        title: "מנהלת תחום אקדמיה וחינוך, אינטל ישראל"
    },               {
        name : "איילה פאר",
        title: "ראש ענף הדרכה ופרויקטים, תחום התנדבות, משרד העבודה, הרווחה והשירותים החברתיים"
    },               {
        name : "אוריה לין",
        title: "ראש תכנית \"הכוורת\" מיסודם של ג'וינט ישראל וקרנות ביטוח לאומי"
    }
];

export const VOLUNTEERS = [
    {
        name: "סטודיו זלצר",
        position: "עיצוב"
    },
    {
        name: "אייל דוד",
        position: "ניהול מוצר"
    },
    {
        name: "חן נבו",
        position: "תוכן שיווקי"
    },
    {
        name: "רותם מידן",
        position: "ניהול נתונים"
    },
    {
        name: "דנה סנדר מולה",
        position: "יזמות חברתית"
    },
    {
        name: "ניבי טפליץ",
        position: "UX"
    },
    {
        name: "יובל רוביצ'ק",
        position: "איורים"
    },
    {
        name: "ORO Photography",
        position: "צילום"
    },
    {
        name: "אבישי בלדרמן",
        position: "פיתוח טכנולוגי"
    }
];

export const TEAM_MEMBERS = [];

export const FOUNDERS_MEMBERS = [
    {
        imgSrc : "/images/about_us/Noa_pic_small.jpg",
        name : "נועה אורבך",
        title: "מייסדת, חברת הועד המנהל",
        description: "הקמתי את הלפי כי באופן אישי הרגשתי שחסרה לי תחושת הסולידריות בארץ. לא סולידריות שנובעת ממשבר או כאב, אלא כזאת שמבוססת על אזרחות, על קהילתיות ועל אנושיות",
        email: "noa@helpi.org.il"
    },
    {
        imgSrc : "/images/about_us/Danna_About.jpg",
        name : "דנה כהן",
        title: "מייסדת, מנכ״לית",
        description: "ניסיתי להתנדב המון ופשוט לא הצלחתי. כשגיליתי שאני לא לבד בסיפור הזה, הבנתי שחייב להימצא פתרון. אני באמת מאמינה שאפשר לעשות מהפכה בתחום ההתנדבות, ואני יודעת שהלפי היא חלק מהמהפכה הזאת",
        email: "danna@helpi.org.il"

    }, {
        imgSrc: "/images/about_us/נטע.jpg",
        name: "נטע קיצוני",
        title: "מנהלת שותפים עסקיים",
        description: 'מהרגע הראשון שהצטרפתי להלפי, הבנתי כמה מיזם כזה היה חסר בעולם החברתי. החיבור בין העולם העסקי למגזר השלישי הוא קריטי לצמצום הפערים בחברה הישראלית, ואני גאה ליצור את החיבורים הללו ולקחת חלק במהפכה החברתית שהלפי מובילה.',
        email: "neta@helpi.org.il"
    }, {
        imgSrc: "/images/about_us/ורד.jpg",
        name: 'ורד ספיר זונשיין',
        title: 'מנהלת שותפים עסקיים',
        description: 'אחרי מספר שנים בעולם החברתי-חינוכי, חיפשתי מקום שארגיש בו ארגיש חלק ממהפכה חברתית, כזו משפיעה על חייהם של אזרחי ישראל, והלפי זה בדיוק המקום. מתרגשת בכל בוקר לדעת שאנחנו פשוט עושות טוב.',
        email: "vered@helpi.org.il"
    },{
        imgSrc: "/images/about_us/almog.jpeg",
        name: 'אלמוג יונה',
        title: 'מנהלת התנדבויות',
        description: 'אחרי מספר שנים בסטארטאפים וחברות עסקיות, חיפשתי להשתלב בעולם החברתי ולהיות חלק מעשייה למען האחר. למזלי, בהלפי אני זוכה ללוות התנדבויות שמרגשות אותי כל פעם מחדש, ולהיות חלק מהמהפכה הטכנולוגית שהלפי מקדמת בעולם החברתי.',
        email: "almog@helpi.org.il"
    }
];