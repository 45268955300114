class SingleVolunteerMapper {
    /**
     * map server data to UI data
     * @param payload
     * @returns {{}}
     */
    mapToVm = (payload) => {
        return ({
            groupVolunteers: payload.group_volunteers.map((item) => {
                return (
                    {
                        id: item.id,
                        name: item.name,
                        phoneNumber: item.phone_number ? {
                            number: item.phone_number.number,
                            country_code: item.phone_number.country_code,
                        } : {},
                        email: item.email

                    }
                )
            })

        });
    };


    // /**
    //  * map UI data to server data
    //  * @param payload
    //  * @returns {{}}
    //  */
    // mapToDto = (payload) => {
    //     return ({
    //
    //     });
    // }
}

export default new SingleVolunteerMapper();