import React from 'react';
import './style.scss';
import HeadingText from "../../components/heading-text";
import CaseCard from "../../components/case-card";
import SuccessContainer from "../../core/containers/success";
import _ from 'lodash';

class Success extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        const {successes, whiteLabel} = this.props;

        const headingText = _.isEmpty(whiteLabel) ? `סיפורי הצלחה` : `המתנדבים של ${whiteLabel.name}`;

        return (
            <div className={`container cases-search-view success-page`}>
                <HeadingText label={headingText} />
                {successes && successes.length > 0 &&
                <div className="cases">
                    {successes.map((item, index) => {
                        return (
                            <CaseCard
                                id={item.id}
                                title={item.text}
                                imgUrl={item.imgUrl}
                                withButton={false}
                                key={`success-card-${index}-${item.id}`}
                            />
                        );
                    })
                    }
                </div>
                }
            </div>
        );
    }
}


export default SuccessContainer(Success);
