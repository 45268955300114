export const NEXT_STAGE = 'SIGNUP@NEXT_STAGE';
export const NEXT_VALIDATION_STAGE = 'SIGNUP@NEXT_VALIDATION_STAGE';
export const SMS_VALIDATION_SUCCESS = 'SIGNUP@SMS_VALIDATION_SUCCESS';
export const USER_REGISTRATION_SUCCESS = 'SIGNUP@USER_REGISTRATION_SUCCESS';
export const RESET_REGISTRATION = 'SIGNUP@RESET_REGISTRATION';
export const EMAIL_DOMAIN_AUTHORIZED = 'SIGNUP@EMAIL_DOMAIN_AUTHORIZED';
export const EMAIL_DOMAIN_NOT_AUTHORIZED = 'SIGNUP@EMAIL_DOMAIN_NOT_AUTHORIZED';
export const USER_AUTHENTICATION_SUCCESS = 'AUTH@USER_AUTHENTICATION_SUCCESS';
export const AUTH_TEST_DONE = 'AUTH@AUTH_TEST_DONE';
export const ADD_PENDING_TO_AUTH_TASK = 'AUTH@ADD_PENDING_TO_AUTH_TASK';
export const CLEAR_PENDING_TO_AUTH_TASK = 'AUTH@CLEAR_PENDING_TO_AUTH_TASK';
export const USER_LOGOUT = 'AUTH@USER_LOGOUT';
export const FORGOT_PASSWORD_SENT = 'AUTH@FORGOT_PASSWORD_SENT';
export const RESET_FORGOT_PASSWORD = 'AUTH@RESET_FORGOT_PASSWORD';
export const UPDATE_FORGOTTEN_PASSWORD = 'AUTH@UPDATE_FORGOTTEN_PASSWORD';
export const LOGIN_COMPLETE_DETAILS = 'AUTH@LOGIN_COMPLETE_DETAILS';
export const SHOW_VALID_ERROR = 'AUTH@SHOW_VALID_ERROR';
export const RESET_VALID_ERROR = 'AUTH@RESET_VALID_ERROR';
