/**
 * Created by Amit on 08/06/2021
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: shows user all tasks history and featured tasks
 */
import React, { useEffect } from 'react';
import './style.scss';
import ValidatedForm from '../../../components/validated-form';
import InputValidator from '../../../common/input-validator';
import HelpiButton from '../../../components/helpi-button';
import {HELPI_BUTTON_CLASSES} from '../../../common/constants.js';
import {Link} from 'react-router-dom';
import history from "../../../utils/history";
import './style.scss';
import ProfileWrapper from "../../../core/containers/auth/profile";
import reducer from '../../../core/containers/auth/profile/reducer';

const Mytasks = (props) => {
    const {user, userTaskCompleted, userOnGoingTasks} = props;

    useEffect((prevProps) => {
        props.getAllUserCompletedTasks();
        props.getAllUserFutureTasks();
    }, [])

    const historyList = userTaskCompleted.taskCompletedList ? userTaskCompleted.taskCompletedList.map((task, key) => {
        return (
            <li className="tasksHistory__list__item" key={key}>
                <p className="tasksHistory__list__item-title">{task.title}</p>
                {/*<p className="tasksHistory__list__item-desc">{task.desc}</p>*/}
                <Link to={`/c/${task.serial_number}`} className="tasksHistory__list__item-link">
                    <HelpiButton
                        label='מעבר למשימה'
                        classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.THICK]}
                    />
                </Link>
            </li>
        )
    }) : null;

    const toDoList = userOnGoingTasks ? userOnGoingTasks.map((task, key) => {
        return (
            <li className="tasksHistory__list__item" key={key}>
                <p className="tasksHistory__list__item-title">{task.title}</p>
                {/*<p className="tasksHistory__list__item-desc">{task.desc}</p>*/}
                <Link to={`/c/${task.serial_number}`} className="tasksHistory__list__item-link">
                    <HelpiButton
                        label='מעבר למשימה'
                        classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.THICK]}
                    />
                </Link>
            </li>
        )
    }) : null;

    const handlePaginationClick = (name) => {
        console.log('clicked', name);
    };

    return (
        <div className='container myTasks-container'>
            <h2>ההתנדבויות שלי</h2>
            <div className="tasksHistory-section">
                <h3 className="tasksHistory-title">משימות לבצע</h3>
                <ul className="tasksHistory__list">
                    {toDoList}
                </ul>
                <div className="tasksHistory-pagination">
                    <div className="tasksHistory-pagination-next" onClick={() => handlePaginationClick('next')}>
                        next
                    </div>
                    <div className="tasksHistory-pagination-previous" onClick={() => handlePaginationClick('previous')}>
                        previous
                    </div>
                </div>
            </div>


            <div className="tasksHistory-section">
                <h3 className="tasksHistory-title">משימות שהושלמו</h3>
                <ul className="tasksHistory__list">
                    {historyList}
                </ul>
                <div className="tasksHistory-pagination">
                    <div className="tasksHistory-pagination-next" onClick={() => handlePaginationClick('next')}>
                        next
                    </div>
                    <div className="tasksHistory-pagination-previous" onClick={() => handlePaginationClick('previous')}>
                        previous
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProfileWrapper(Mytasks);